/* eslint-disable no-loop-func */
import React from "react";
import moment from "moment";
import { withRouter, Link } from "react-router-dom";
import cx from "classnames";
import {
  Typography,
  Select,
  Checkbox,
  Button,
  Modal,
  notification,
  Radio,
  InputNumber,
  Tag,
  Switch,
  message,
} from "antd";
import {
  DeleteOutlined,
  PlusCircleOutlined,
  CheckCircleOutlined,
  CopyOutlined,
  EditOutlined,
  FileOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import { graphqlOperation, Storage } from "aws-amplify";
import { LexoRank } from "lexorank";
import _ from "lodash";
import axios from "axios";

import { callGraphQLSimple } from "common/apiHelpers";
import { getSimpleLabel } from "common/labels";
import { updateProject, updateTask, updateTaskRevision } from "graphql/queries_custom";
import { getTaskSimple } from "graphql/queries_custom";
import { processIdForDisplay } from "common/helpers";
import withSubscriptions from "common/withSubscriptions";
import linkApi from "common/link";
import { TASK_RELATIONSHIPS } from "common/constants";
import {
  callGraphQL,
  getUserReadableCatLevel,
  getReadableStatus,
  assignTaskToUser,
  getLabel,
  downloadBlob,
  openAttachment,
} from "common/helpers";
import getS3File from "common/getS3File";
import { callRest } from "common/apiHelpers";
import { getLatestRevision, KEY_TYPES, encodeKey, getAttachmentTypeFromKey } from "common/shared";
import { formatAddress, Marker } from "../../Map/Map";
import { isAuthorised, getGroupNamesForUser } from "common/permissions";
import { changeTaskStatus } from "common/changeTaskStatus";

import DatePicker from "DatePicker/DatePicker";
import Card from "Card/Card";
import Avatar from "Avatar/Avatar";
import ClientLogo from "ClientLogo/ClientLogo";
import CatLevelModal from "Modals/CatLevelModal/CatLevelModal";
import LinkTaskModal from "Modals/LinkTaskModal/LinkTaskModal";
import AddressModal from "Modals/AddressModal/AddressModal";
import UsersFilter from "UsersFilter/UsersFilter";
import Input from "Input/Input";
import InfoItem from "InfoItem/InfoItem";
import DocumentDetailsModal from "Modals/DocumentDetailsModal/DocumentDetailsModal";
import MultipleAssignedUsersModal from "Modals/MultipleAssignedUsersModal/MultipleAssignedUsersModal.jsx";
import MapWrapper from "MapWrapper/MapWrapper";
import TaskReviewButton from "./TaskReviewButton/TaskReviewButton";
import { DashboardItemTags } from "DashboardItemTags/DashboardItemTags";

import "./TaskSidebar.scss";

export class TaskSidebar extends React.Component {
  state = {
    isCatLevelModalVisible: false,
    isLinkTaskModalVisible: false,
    isRequestReviewModalVisible: false,
    pdfPreviewData: null,
    selectedAttachment: null,
    documentViewModalAttachment: null,
    isMapModalVisible: false,
    selectedField: null,
    isMultipleAssignedUsersModalVisible: false,
  };

  constructor(props) {
    super(props);
    this.debouncedChangeAttribute = _.debounce(this.changeAttribute, 500);
  }

  getExcludedAssigneeList = () => {
    const { task, users } = this.props;

    if (!task.catLevel) {
      return [];
    }
    const result = users.filter((x) => x.catLevelDesign < task.catLevel).map((x) => x.id);

    return result;
  };

  assignProjectToUser = async ({ project, user }) => {
    const { task } = this.props;
    await callGraphQL(
      `Failed to change senior engineer on ${getSimpleLabel("project")}`,
      graphqlOperation(updateProject, {
        input: {
          id: project.id,
          assignedTo: user ? user.id : null,
        },
      })
    );

    await callGraphQL(
      `Failed to refresh ${getSimpleLabel("task")} details`,
      graphqlOperation(updateTask, {
        input: {
          id: task.id,
          itemSubscription: Math.floor(Math.random() * 100000),
        },
      })
    );
  };

  removeLinkedTask = (link) => {
    Modal.confirm({
      title: `Confirm remove linked ${getLabel({
        id: "task",
        defaultValue: "task",
      })}`,
      className: "confirm-remove-linked-task-modal",
      content: (
        <>
          Are you sure you want to remove <b>{link.label}</b> from the linked
          {getSimpleLabel("tasks")}?
        </>
      ),
      onOk: async () => {
        const linkedTaskDetails = (
          await callGraphQL(
            `Failed to retrieve linked ${getSimpleLabel("task")}`,
            graphqlOperation(getTaskSimple, {
              id: link.taskId,
            })
          )
        ).data.getTask;

        await callGraphQL(
          `Failed to remove linked ${getSimpleLabel("task")}`,
          graphqlOperation(updateTask, {
            input: {
              id: this.props.task.id,
              linkedTasks: this.props.task.linkedTasks.filter((x) => x.id !== link.id),
            },
          })
        );

        await callGraphQL(
          "Failed to remove corresponding link",
          graphqlOperation(updateTask, {
            input: {
              id: link.taskId,
              linkedTasks: linkedTaskDetails.linkedTasks.filter((x) => x.id !== link.correspondingId),
            },
          })
        );
      },
    });
  };

  changeStatus = async (status) => {
    await changeTaskStatus({
      status,
      taskId: this.props.task.id,
      organisationDetails: this.props.organisationDetails,
    });
  };

  changeSprint = async (sprintId) => {
    const { tasks, task, organisationDetails } = this.props;
    const tasksInSprint = tasks.filter((x) => x.sprintId === sprintId);
    const sortedTasks = tasksInSprint.sort((a, b) => (a.order < b.order ? -1 : 1));
    const lastTaskInSprint = sortedTasks[sortedTasks.length - 1];
    const newTaskOrder = lastTaskInSprint
      ? LexoRank.parse(lastTaskInSprint.order).genNext().value
      : LexoRank.middle().value;
    await callGraphQL(
      `Failed to update ${getLabel({
        id: "task",
        defaultValue: "task",
      })} ${getLabel({
        organisationDetails,
        id: "sprint",
        defaultValue: "sprint",
      })}`,
      graphqlOperation(updateTask, {
        input: {
          id: task.id,
          sprintId,
          order: newTaskOrder,
        },
      })
    );
  };

  changeDueDate = async (momentDate, stringDate) => {
    let formattedDate;
    const { organisationDetails, task } = this.props;
    if (stringDate.length === 0) {
      formattedDate = null;
    } else {
      formattedDate = momentDate.format("YYYY-MM-DD");
    }

    if (organisationDetails.settings?.task?.useDueDatesOnTaskRevisions) {
      let latestTaskRevision = task.revisions.items.slice(-1)[0];
      if (latestTaskRevision) {
        await callGraphQLSimple({
          message: "Failed to update task revision due date",
          queryCustom: "updateTaskRevision",
          variables: {
            input: {
              id: latestTaskRevision.id,
              dueDate: formattedDate,
            },
          },
        });
      }
    }

    await callGraphQL(
      "Failed to update the due date",
      graphqlOperation(updateTask, {
        input: {
          id: task.id,
          dueDate: formattedDate,
          itemSubscription: Math.floor(Math.random() * 100000),
        },
      })
    );

    await window.callGraphQLSimple({
      mutation: "createTaskActivityItem",
      message: `Failed to record ${getSimpleLabel("task")} activity item`,
      variables: {
        input: {
          taskId: task.id,
          author: window.apiUser.id,
          organisation: organisationDetails.id,
          type: "DUE_DATE_CHANGED",
          content: JSON.stringify({
            newDate: stringDate,
          }),
        },
      },
    });
  };

  changeStartDate = async (momentDate, stringDate) => {
    let formattedDate;
    const { organisationDetails, task } = this.props;
    if (stringDate.length === 0) {
      formattedDate = null;
    } else {
      formattedDate = momentDate.format("YYYY-MM-DD");
    }

    if (task.endDate && momentDate.isAfter(moment(task.endDate), "day")) {
      message.error("End date cannot be before start date");
      return;
    }

    await callGraphQL(
      "Failed to update the start date",
      graphqlOperation(updateTask, {
        input: {
          id: task.id,
          startDate: formattedDate,
          itemSubscription: Math.floor(Math.random() * 100000),
        },
      })
    );

    await window.callGraphQLSimple({
      mutation: "createTaskActivityItem",
      message: `Failed to record ${getSimpleLabel("task")} activity item`,
      variables: {
        input: {
          taskId: task.id,
          author: window.apiUser.id,
          organisation: organisationDetails.id,
          type: "LIFECYCLE_EVENT",
          content: JSON.stringify({
            type: "START_DATE_CHANGED",
            newDate: stringDate,
            oldDate: task.startDate,
          }),
        },
      },
    });
  };

  changeEndDate = async (momentDate, stringDate) => {
    let formattedDate;
    const { organisationDetails, task } = this.props;

    if (task.startDate && momentDate.isBefore(moment(task.startDate), "day")) {
      message.error("End date cannot be before start date");
      return;
    }

    if (stringDate.length === 0) {
      formattedDate = null;
    } else {
      formattedDate = momentDate.format("YYYY-MM-DD");
    }

    await callGraphQL(
      "Failed to update the end date",
      graphqlOperation(updateTask, {
        input: {
          id: task.id,
          endDate: formattedDate,
          itemSubscription: Math.floor(Math.random() * 100000),
        },
      })
    );

    await window.callGraphQLSimple({
      mutation: "createTaskActivityItem",
      message: `Failed to record ${getSimpleLabel("task")} activity item`,
      variables: {
        input: {
          taskId: task.id,
          author: window.apiUser.id,
          organisation: organisationDetails.id,
          type: "LIFECYCLE_EVENT",
          content: JSON.stringify({
            type: "END_DATE_CHANGED",
            newDate: stringDate,
            oldDate: task.endDate,
          }),
        },
      },
    });
  };

  changeAttribute = async ({ fieldName, value }) => {
    const { task } = this.props;
    await callGraphQL(
      `Failed to update ${getLabel({
        id: "task",
        defaultValue: "task",
      })}`,
      graphqlOperation(updateTask, {
        input: {
          id: task.id,
          [fieldName]: value,
        },
      })
    );
  };

  changeBudget = async (momentDate, stringDate) => {
    let formattedDate;
    const { task } = this.props;
    if (stringDate.length === 0) {
      formattedDate = null;
    } else {
      formattedDate = momentDate.format("YYYY-MM-DD");
    }

    await callGraphQL(
      "Failed to update the due date",
      graphqlOperation(updateTask, {
        input: {
          id: task.id,
          dueDate: formattedDate,
        },
      })
    );
  };

  confirmFinish = () => {
    const { task, organisationDetails } = this.props;
    if (task.isUnderReview) {
      notification.open({
        placement: "bottomRight",
        type: "error",
        message: (
          <>
            <Typography.Title level={3}>
              {getLabel({
                id: "Task",
                defaultValue: "Task",
              })}{" "}
              cannot be finished while it is under review
            </Typography.Title>
            <Typography.Paragraph>
              Close the review in order to be able to be able to finish the{" "}
              {getLabel({
                id: "task",
                defaultValue: "task",
              })}
            </Typography.Paragraph>
          </>
        ),
      });
      return;
    }

    Modal.confirm({
      title: `Mark ${getLabel({
        id: "task",
        defaultValue: "task",
      })} as finished`,
      icon: <CheckCircleOutlined />,
      content: (
        <>
          Are you sure you want to finish <b>{task.title}</b>?
        </>
      ),
      okButtonProps: { "data-cy": "finish-task-modal-ok" },
      cancelButtonProps: { "data-cy": "finish-task-modal-cancel" },
      onOk: async () => {
        await callGraphQL(
          `Failed to mark ${getLabel({
            id: "task",
            defaultValue: "task",
          })} as finished`,
          graphqlOperation(updateTask, {
            input: {
              id: task.id,
              isFinished: true,
              finishedAt: new Date().toISOString(),
              isReadOnly: true,
            },
          })
        );

        await window.callGraphQLSimple({
          message: `Failed to record ${getSimpleLabel("task")} activity item`,
          mutation: "createTaskActivityItem",
          variables: {
            input: {
              taskId: task.id,
              author: window.apiUser.id,
              organisation: organisationDetails.id,
              type: "FINISHED",
            },
          },
        });

        if (task.revisions && task.revisions.items.length > 0) {
          await callGraphQL(
            `Failed to update old ${getLabel({
              organisationDetails: this.props.organisationDetails,
              id: "task revision",
              defaultValue: "task revision",
            })}`,
            graphqlOperation(updateTaskRevision, {
              input: {
                id: task.revisions.items[task.revisions.items.length - 1].id,
                isReadOnly: true,
              },
            })
          );
        }

        if (!window.isMac) {
          try {
            await linkApi.cleanup({
              taskId: task.id,
              type: "FINISH",
            });
          } catch (e) {
            console.log("Link cleanup call failed");
          }
        }
      },
    });
  };

  confirmResume = () => {
    const { task, sprints, organisationDetails } = this.props;
    Modal.confirm({
      title: `Resume ${getLabel({
        id: "task",
        defaultValue: "task",
      })}`,
      icon: <CheckCircleOutlined />,
      okButtonProps: { "data-cy": "resume-task-modal-ok" },
      cancelButtonProps: { "data-cy": "resume-task-modal-cancel" },
      content: (
        <>
          Are you sure you want to resume <b>{task.title}</b>?
        </>
      ),
      onOk: async () => {
        let sprintId = task.sprintId;
        const activeSprint = sprints.find((x) => x.isActive);
        if (activeSprint) {
          sprintId = activeSprint.id;
        }
        await callGraphQL(
          `Failed to resume ${getLabel({
            id: "task",
            defaultValue: "task",
          })}`,
          graphqlOperation(updateTask, {
            input: {
              id: task.id,
              isFinished: false,
              isReadOnly: false,
              sprintId,
            },
          })
        );

        await window.callGraphQLSimple({
          message: `Failed to record ${getSimpleLabel("task")} activity item`,
          mutation: "createTaskActivityItem",
          variables: {
            input: {
              taskId: task.id,
              author: window.apiUser.id,
              organisation: organisationDetails.id,
              type: "RESUMED",
            },
          },
        });

        if (task.revisions && task.revisions.items.length > 0) {
          const latestTaskRevision = getLatestRevision(task);
          if (latestTaskRevision.reviewStatus !== "SUCCESS") {
            await callGraphQL(
              `Failed to update old ${getLabel({
                organisationDetails: this.props.organisationDetails,
                id: "task revision",
                defaultValue: "task revision",
              })}`,
              graphqlOperation(updateTaskRevision, {
                input: {
                  id: latestTaskRevision.id,
                  isReadOnly: false,
                },
              })
            );
          }
        }
      },
    });
  };

  displayFinishButton = () => {
    const { task } = this.props;

    if (task.isArchived) {
      return null;
    }
    if (task.isFinished) {
      return (
        <Button
          icon={<CheckCircleOutlined />}
          className="mark-as-not-finished"
          onClick={this.confirmResume}
          type="primary"
          data-cy="button-resume-task"
        >
          Resume
        </Button>
      );
    } else {
      return (
        <Button
          icon={<CheckCircleOutlined />}
          className="mark-as-finished"
          type="primary"
          onClick={this.confirmFinish}
          data-cy="button-finish-task"
        >
          Finish
        </Button>
      );
    }
  };

  displayLinkedTasks = () => {
    const { task, tasks, clients, projects, organisationDetails } = this.props;

    return (
      <div className="stat linked-tasks-container" data-cy="linked-tasks-container">
        <Typography.Text className="stat-label">
          <span className="label">
            Linked{" "}
            {getLabel({
              id: "tasks",
              defaultValue: "tasks",
            })}{" "}
          </span>{" "}
          {task.isArchived || task.isFinished ? null : (
            <Button
              type="primary"
              icon={<PlusCircleOutlined />}
              onClick={() => this.setState({ isLinkTaskModalVisible: true })}
              data-cy="add-linked-task-button"
            >
              Add
            </Button>
          )}
        </Typography.Text>
        {!task.linkedTasks || task.linkedTasks.length === 0 ? (
          <Typography.Text className="stat-value not-set">
            No linked{" "}
            {getLabel({
              id: "tasks",
              defaultValue: "tasks",
            })}
          </Typography.Text>
        ) : (
          <div className="linked-tasks-items">
            {task.linkedTasks.map((link, i) => {
              const currentTaskDetails = tasks.filter((x) => x.id === link.taskId)[0];
              const project = projects.find((x) => x.id === currentTaskDetails?.projectId);
              const client = clients.find((x) => x.id === currentTaskDetails?.clientId);

              return (
                <div
                  key={`linked-task-` + i}
                  className="stat-value linked-task-item"
                  data-cy="linked-task-item"
                  data-task-id={link.taskId}
                >
                  <div className="info-and-actions">
                    <div className="info">
                      {TASK_RELATIONSHIPS.find((x) => x.value === link.relationship).label}
                      <br />
                      <Link to={`/tasks/${link.taskId}`}>
                        <b>{link.label}</b>
                      </Link>
                    </div>
                    {!task.isFinished && !task.isArchived && (
                      <Button
                        icon={<DeleteOutlined />}
                        onClick={() => this.removeLinkedTask(link)}
                        className="remove-linked-task"
                        data-cy="remove-linked-task-button"
                        data-task-id={link.taskId}
                      />
                    )}
                  </div>
                  {currentTaskDetails && (
                    <DashboardItemTags
                      item={{ ...currentTaskDetails, client, project }}
                      organisationDetails={organisationDetails}
                      includeLinks
                    />
                  )}
                </div>
              );
            })}
          </div>
        )}
      </div>
    );
  };

  openSingleAttachment = async (_, key) => {
    const fileName = key.split("/").slice(-1)[0];
    const attachmentType = getAttachmentTypeFromKey(key);
    const attachment = { key, type: attachmentType, name: fileName };

    openAttachment.call(this, attachment);
  };

  changeCustomFieldValue = async (field, value) => {
    const { task } = this.props;
    if (Array.isArray(value)) {
      value = JSON.stringify(value);
    }

    let customFields = [...(task.customFields || [])];
    let fieldWasAlreadySet = customFields.find((x) => x.id === field.id);
    if (fieldWasAlreadySet) {
      customFields.forEach((crtField) => {
        if (crtField.id === field.id) {
          crtField.value = value;
        }
      });
    } else {
      customFields.push({ id: field.id, value });
    }

    await callGraphQL(
      "Failed to update field value",
      graphqlOperation(updateTask, {
        input: {
          id: task.id,
          customFields,
        },
      })
    );
  };

  getKey = async (localKey, fieldName) => {
    const { project, task } = this.props;

    let fileKey = await encodeKey({
      type: KEY_TYPES.TASK_ATTACHMENT_CUSTOM_FIELD,
      data: {
        organisation: task ? task.organisation : project.organisation,
        projectId: task ? task.project.id : project.id,
        taskId: task.id,
        attachmentFileName: `${localKey}`,
        fieldName,
      },
    });

    return fileKey.split("//").join("/");
  };

  onFileInputChange = async (e, field) => {
    let augmentedFiles = [];
    for (let i = 0; i < e.target.files.length; i++) {
      const rawFile = e.target.files[i];
      augmentedFiles.push({
        size: rawFile.size,
        fullPath: `/${rawFile.name}`,
        name: rawFile.name,
        fileObject: rawFile,
        type: rawFile.type,
      });
    }

    await this.onCustomFieldFilesDropped(augmentedFiles, field);
    this.props.refreshAttachments();
  };

  fetchCoordinates = async (formattedAddress) => {
    const apiKey = window.secrets?.GOOGLE_MAPS;

    let coordinates = { lat: 0, lng: 0 };

    const config = {
      method: "GET",
      url: `https://maps.googleapis.com/maps/api/geocode/json?address=${formattedAddress}&key=${apiKey}`,
    };
    await axios(config).then(async (response) => {
      if (!response.data || !response.data.results || response.data.results.length === 0) {
        console.log("No response data");
        return;
      }

      if (response.data.status === "OK") {
        const results = response.data.results;

        coordinates = results[0].geometry.location;
      }
    });

    return coordinates;
  };

  onCustomFieldFilesDropped = async (attachments, field) => {
    const { task } = this.props;

    let uploadPromises = [];

    let loadingModal = Modal.info({
      title: "Uploading files...",
      className: "modal-no-buttons",
      icon: <LoadingOutlined />,
      maskClosable: false,
    });

    for (const attachmentContainer of attachments) {
      const attachment = attachmentContainer.fileObject;
      let fileKey = (await this.getKey(attachmentContainer.fullPath, field.label)).replace("public/", "");

      uploadPromises.push(
        Storage.put(fileKey, attachment, {
          contentType: attachment.type,
        })
          .then(async () => {
            let newCustomTaskFields = JSON.parse(JSON.stringify(task.customFields));
            const fieldToBeUpdated = newCustomTaskFields.find((customField) => customField.id === field.id);

            if (fieldToBeUpdated) {
              fieldToBeUpdated.value = fileKey;
            } else {
              newCustomTaskFields.push({
                id: field.id,
                value: fileKey,
              });
            }

            await callGraphQL(
              "Failed to add custom file",
              graphqlOperation(updateTask, {
                input: {
                  id: task.id,
                  customFields: newCustomTaskFields,
                },
              })
            );
          })
          .then(async () => {
            await callRest({
              route: "/checkAttachmentExistenceInTrash",
              method: "post",
              body: {
                Key: `public/${fileKey}`,
              },
              includeCredentials: false,
            });
          })
          .then(() => fileKey)
      );

      await Promise.all(uploadPromises);
    }
    loadingModal.destroy();
  };

  downloadAttachment = async (attachment) => {
    const fileName = attachment.key.split("/").slice(-1)[0];
    try {
      const signedUrl = await getS3File(attachment.key.replace("public/", ""));
      const fileData = await axios.get(signedUrl, { responseType: "blob" });
      downloadBlob({ blob: fileData.data, fileName });
    } catch (e) {
      console.error("error downloading attachment:", e);
      notification.error({
        message: (
          <Typography.Text>
            Could not download attachment <b>{fileName}</b>
          </Typography.Text>
        ),
        duration: 0,
      });
    }
  };

  displayCustomFields = () => {
    const { task, organisationDetails, groups, apiUser } = this.props;

    const groupNamesForUser = getGroupNamesForUser(apiUser.id, groups);

    return (organisationDetails.customFields || [])
      .filter((field) => {
        if (!field.groupsThatCanSee || field.groupsThatCanSee.length === 0) {
          return true;
        }

        return field.groupsThatCanSee.some((group) => groupNamesForUser.includes(group));
      })
      .map((field) => {
        let taskField = (task.customFields || []).find((x) => x.id === field.id);
        let fieldElement = null;

        let labelElement = (
          <>
            {field.label}
            {field.formula && (
              <CopyOutlined
                onClick={async () => {
                  await navigator.clipboard.writeText(taskField.value);
                  message.success(`${field.label} copied`);
                }}
              />
            )}
          </>
        );
        let isDisabled = task.isArchived || task.isFinished;

        switch (field.type) {
          case "TEXT":
            fieldElement = (
              <Input
                defaultValue={taskField ? taskField.value : field.defaultValue || ""}
                onChange={(value) => this.changeCustomFieldValue(field, value)}
                fireOnChangeWithoutBlurWithDebounce
                disabled={isDisabled || field.isStatic}
                showBorder={!taskField?.value && !field?.defaultValue}
                fullWidth
              />
            );
            break;
          case "TEXTAREA":
            fieldElement = (
              <Input
                defaultValue={taskField ? taskField.value : field.defaultValue || ""}
                onChange={(value) => this.changeCustomFieldValue(field, value)}
                fireOnChangeWithoutBlurWithDebounce
                disabled={isDisabled || field.isStatic}
                showBorder={!taskField?.value && !field?.defaultValue}
                multiLine
                fullWidth
              />
            );
            break;

          case "CHECKBOX":
            let isChecked = taskField && taskField.value === "checked";
            labelElement = (
              <>
                {field.label}{" "}
                <Checkbox
                  className="custom-field-checkbox"
                  checked={isChecked}
                  onChange={(e) => this.changeCustomFieldValue(field, e.target.checked ? "checked" : null)}
                  disabled={isDisabled}
                >
                  {isChecked ? "Yes" : ""}
                </Checkbox>
              </>
            );
            break;

          case "NUMBER":
            fieldElement = (
              <InputNumber
                defaultValue={taskField ? taskField.value : field.defaultValue || 0}
                onChange={(value) => this.changeCustomFieldValue(field, value)}
                disabled={isDisabled}
              />
            );
            break;

          case "RADIO_LIST":
            fieldElement = (
              <Radio.Group
                value={taskField ? taskField.value : field.options[0].value}
                onChange={(e) => this.changeCustomFieldValue(field, e.target.value)}
                disabled={isDisabled}
                options={field.options}
              />
            );
            break;

          case "CHECKBOX_LIST":
            let parsedValue = taskField && taskField.value ? JSON.parse(taskField.value) : null;

            fieldElement = (
              <Checkbox.Group
                value={parsedValue}
                onChange={(value) => this.changeCustomFieldValue(field, value)}
                disabled={isDisabled}
                options={field.options}
              />
            );
            break;

          case "SINGLE_FILE":
            labelElement = null;

            fieldElement = (
              <>
                <InfoItem
                  inline
                  label={field.label}
                  value={
                    <>
                      <Button
                        type="dark"
                        icon={<PlusCircleOutlined />}
                        onClick={() => {
                          document.querySelector(`#custom-field-upload-${field.id}`).click();
                        }}
                      >
                        <span>{taskField?.value ? "Replace file" : "Upload file"}</span>
                      </Button>

                      <input
                        id={`custom-field-upload-${field.id}`}
                        style={{ display: "none" }}
                        type="file"
                        onChange={(e) => {
                          this.onFileInputChange(e, field);
                        }}
                        data-cy="upload-input"
                      />
                    </>
                  }
                  extraContent={
                    taskField?.value && (
                      <Typography.Text
                        className="file-item"
                        onClick={(e) => this.openSingleAttachment(e, taskField?.value)}
                      >
                        <span style={{ marginRight: "0.5rem" }}>
                          <FileOutlined />
                        </span>
                        {taskField?.value?.split("/").slice(-1)[0]}
                      </Typography.Text>
                    )
                  }
                />
              </>
            );
            break;

          case "GEOGRAPHICAL_LOCATION":
            labelElement = null;

            let fullAddressDetails;
            let address;
            let coordinates;

            if (taskField?.value) {
              fullAddressDetails = JSON.parse(taskField?.value);
              address = formatAddress(fullAddressDetails);

              if (fullAddressDetails.coordinates) {
                coordinates = fullAddressDetails.coordinates;
              }
            }

            fieldElement = (
              <>
                <>
                  <InfoItem
                    inline
                    label={field.label}
                    className="custom-field-map-wrapper"
                    value={
                      <div>
                        <Button
                          onClick={() => {
                            this.setState({ isMapModalVisible: true });
                            this.setState({ selectedField: field });
                          }}
                          className="edit-address-button"
                          type="clear"
                          icon={<EditOutlined />}
                        >
                          {taskField?.value ? "Edit address" : "Add address"}
                        </Button>
                      </div>
                    }
                    extraContent={
                      taskField?.value ? (
                        <MapWrapper
                          coordinates={coordinates}
                          isReadOnly={true}
                          address={address}
                          width="100%"
                          height={200}
                        />
                      ) : null
                    }
                  />
                </>
              </>
            );
            break;

          case "USER":
            fieldElement = (
              <UsersFilter
                activateOnHover={true}
                value={taskField?.value}
                onChange={(value) => this.changeCustomFieldValue(field, value)}
                disabled={isDisabled}
                maxLabelLength={22}
              />
            );
            break;

          case "DATE":
            fieldElement = (
              <DatePicker
                format="DD-MM-YYYY"
                className="active-on-hover"
                defaultValue={taskField?.value ? moment(taskField?.value) : undefined}
                onChange={(value) => this.changeCustomFieldValue(field, value)}
                placeholder="Not set"
                suffixIcon={null}
              />
            );
            break;

          default:
            fieldElement = null;
            break;
        }

        return (
          <div className={cx("stat", { static: field.isStatic })} key={field.id}>
            <Typography.Text className="stat-label">{labelElement}</Typography.Text>
            {fieldElement && <Typography.Text className="stat-value">{fieldElement}</Typography.Text>}
          </div>
        );
      });
  };

  displayAddressModal = () => {
    const { task, organisationDetails } = this.props;
    const { selectedField } = this.state;

    let address;
    let formattedAddress;

    const apiKey = window.secrets?.GOOGLE_MAPS;

    const targetField = organisationDetails.customFields?.find((customField) => customField.id === selectedField.id);
    const targetFieldValue = task.customFields?.find((customField) => customField?.id === targetField?.id)?.value;

    if (targetFieldValue) {
      address = JSON.parse(targetFieldValue);
      formattedAddress = formatAddress(address);
    }

    return (
      <AddressModal
        onSubmit={this.onMapModalSubmit}
        visible={true}
        onClose={() => this.setState({ isMapModalVisible: false })}
        addressCoordinates={address?.coordinates}
        parent={task}
        isSingle={true}
        address={address}
        apiKey={apiKey}
        isEditingAddress={targetField?.value ? true : false}
        Marker={Marker}
        fetchCoordinates={this.fetchCoordinates}
        formattedAddress={formattedAddress}
        customField={targetField}
        usesMapWidget={true}
      />
    );
  };

  updateCustomTaskFieldAddress = ({ addressDetails, coordinates, selectedField }) => {
    this.changeCustomFieldValue(selectedField, JSON.stringify({ ...addressDetails, coordinates: { ...coordinates } }));
  };

  onMapModalSubmit = async (params) => {
    const { task } = this.props;
    const { selectedField } = this.state;

    const targetField = task?.customFields.find((customField) => customField.id === selectedField.id);
    const address = targetField?.value ? JSON.parse(targetField?.value) : "";

    const { addressDetails, isDifferentSetOfCoordinates, click } = params;
    const addressWithoutCoordinates = _.omit(address, "coordinates");
    const nonNullableAddressDetails = _.omitBy(addressDetails, _.isNil);

    let shouldFetchCoordinates = !_.isEqual(addressWithoutCoordinates, nonNullableAddressDetails);

    if (isDifferentSetOfCoordinates && _.isEqual(addressWithoutCoordinates, nonNullableAddressDetails)) {
      const coordinates = {
        lat: click.lat(),
        lng: click.lng(),
      };

      this.updateCustomTaskFieldAddress({ addressDetails, coordinates, selectedField });
    } else if (shouldFetchCoordinates) {
      const currentAddress = formatAddress(addressDetails);
      const coordinates = await this.fetchCoordinates(currentAddress);
      this.updateCustomTaskFieldAddress({ addressDetails, coordinates, selectedField });
    }

    this.setState({ isMapModalVisible: false });
  };

  render() {
    const {
      apiUser,
      task,
      tasks,
      users,
      organisationDetails,
      sprints,
      splitLayout,
      activityItemsByRequest,
      windowWidth,
      windowHeight,
    } = this.props;

    const { documentViewModalAttachment } = this.state;

    const authorData = users.find((x) => x.id === task.author);

    const atLeastOneStatusMeansDone = (organisationDetails.taskStatuses || []).find((x) => x.type === "DONE");

    return (
      <>
        <Card className={cx("task-sidebar", { "split-layout": splitLayout })}>
          {this.state.pdfPreviewData && (
            <DocumentDetailsModal
              attachment={documentViewModalAttachment}
              document={this.state.pdfPreviewData}
              onClose={() => this.setState({ pdfPreviewData: null })}
              windowWidth={windowWidth}
              windowHeight={windowHeight}
            />
          )}
          <div className="action-buttons">
            <TaskReviewButton
              task={task}
              organisationDetails={organisationDetails}
              users={users}
              activityItemsByRequest={activityItemsByRequest}
            />
            {atLeastOneStatusMeansDone ? null : this.displayFinishButton()}
          </div>
          <div className="stats">
            {organisationDetails.settings?.general?.usesTaskConfirmation && (
              <div className="stat">
                <Typography.Text className="stat-label">
                  Is{" "}
                  {getLabel({
                    organisationDetails,
                    id: "confirmed",
                    defaultValue: "confirmed",
                  })}
                </Typography.Text>
                <Typography.Text className="stat-value">
                  <Switch
                    checkedChildren="Yes"
                    unCheckedChildren="No"
                    checked={task.isConfirmed}
                    onChange={(checked) => {
                      this.changeAttribute({
                        fieldName: "isConfirmed",
                        value: checked,
                      });
                    }}
                  />
                </Typography.Text>
              </div>
            )}

            {this.displayCustomFields()}

            {organisationDetails.usesSprints && (
              <div className="stat">
                <Typography.Text className="stat-label">
                  {getLabel({
                    organisationDetails,
                    id: "Sprint",
                    defaultValue: "Sprint",
                  })}
                </Typography.Text>
                <Typography.Text className="stat-value">
                  {task.isArchived || task.isFinished ? (
                    <Typography.Text>
                      {sprints.find((x) => x.id === task.sprintId)?.name}{" "}
                      {sprints.find((x) => x.id === task.sprintId)?.isActive && (
                        <Tag color="green" style={{ marginLeft: 10 }}>
                          {getLabel({
                            organisationDetails,
                            id: "Sprint-Active",
                            defaultValue: "Active",
                          })}
                        </Tag>
                      )}
                    </Typography.Text>
                  ) : (
                    <Select
                      className="sprint-picker active-on-hover"
                      onChange={this.changeSprint}
                      value={task.sprintId}
                      suffixIcon={null}
                    >
                      {[...sprints]
                        .filter((x) => !x.isFinished)
                        .reverse()
                        .map((sprint, i) => (
                          <Select.Option key={i} value={sprint.id}>
                            {sprint.name}{" "}
                            {sprint.isActive && (
                              <Tag color="green" style={{ marginLeft: 10 }}>
                                {getLabel({
                                  organisationDetails,
                                  id: "Sprint-Active",
                                  defaultValue: "Active",
                                })}
                              </Tag>
                            )}
                          </Select.Option>
                        ))}
                    </Select>
                  )}
                </Typography.Text>
              </div>
            )}

            {(organisationDetails.usesDesignAuthority || organisationDetails.usesDesignAuthority) && (
              <div
                className={cx("stat", {
                  clickable: !task.isArchived && !task.isFinished,
                  link: !task.isArchived && !task.isFinished,
                })}
                data-cy="task-sidebar-authority-container"
                onClick={
                  task.isArchived || task.isFinished
                    ? undefined
                    : () => !task.isArchived && this.setState({ isCatLevelModalVisible: true })
                }
              >
                <Typography.Text className="stat-label">{getSimpleLabel("Authority")}</Typography.Text>
                <Typography.Text className="stat-value cat-level-value" data-cy="task-sidebar-authority">
                  {getUserReadableCatLevel(organisationDetails, task.catLevel)}
                </Typography.Text>
              </div>
            )}

            <div className="stat">
              <Typography.Text className="stat-label">{getSimpleLabel("task-assigned-to-single")}</Typography.Text>
              <Typography.Text className="stat-value">
                {task.isArchived || task.isFinished ? (
                  <Avatar user={users.find((x) => x.id === task.assignedTo)} showLabel={true} />
                ) : (
                  <UsersFilter
                    className="assigned-to-picker"
                    activateOnHover={true}
                    value={task.assignedTo}
                    includeUnassigned={!organisationDetails.settings?.task?.isTaskAssigneeMandatory}
                    onChange={async (assignedTo) => {
                      const newAssigneeDetails = users.find((x) => x.id === assignedTo);

                      await assignTaskToUser({
                        task,
                        user: newAssigneeDetails,
                        organisationDetails,
                      });
                    }}
                    excludeList={this.getExcludedAssigneeList()}
                    suffixIcon={null}
                    maxLabelLength={22}
                    data-cy="assigned-to-picker"
                  />
                )}
              </Typography.Text>
            </div>

            {organisationDetails?.settings?.task?.allowMultipleUsersToBeAssignedToATask && (
              <div className="stat">
                <Typography.Text className="stat-label">
                  {getSimpleLabel("task-assigned-to-multiple-users")}
                </Typography.Text>
                <Typography.Text className="stat-value">
                  {!task.isArchived && !task.isFinished && (
                    <Button
                      className="edit-multiple-assigned-users-button"
                      icon={<EditOutlined />}
                      onClick={() => {
                        this.setState({ isMultipleAssignedUsersModalVisible: true });
                      }}
                    >
                      Edit
                    </Button>
                  )}
                  <div className="multiple-assigned-users-container">
                    {task.assignedToUsers?.map((userId) => {
                      return <Avatar user={users.find((x) => x.id === userId)} showLabel={true} />;
                    })}
                  </div>
                </Typography.Text>
              </div>
            )}

            {organisationDetails?.settings?.task?.usesTaskEstimates && (
              <div className="stat">
                <Typography.Text className="stat-label">Estimated hours</Typography.Text>
                {organisationDetails.settings?.task?.useTaskRevisionEstimates ? (
                  <Typography.Text className="stat-value">{task.estimatedHours || 0}</Typography.Text>
                ) : (
                  <Input
                    className="task-estimated-hours-sidebar-input"
                    defaultValue={task.estimatedHours || "0"}
                    data-cy="task-estimated-hours-sidebar-input"
                    onChange={(value) =>
                      this.changeAttribute({ fieldName: "estimatedHours", value: parseInt(value || 0) })
                    }
                  />
                )}
              </div>
            )}

            <div className="stat">
              <Typography.Text className="stat-label">Status</Typography.Text>
              <Typography.Text className="stat-value">
                {task.isArchived || (!atLeastOneStatusMeansDone && task.isFinished) ? (
                  <Typography.Text>{getReadableStatus(task.status)}</Typography.Text>
                ) : (
                  <Select
                    className="task-status-picker active-on-hover"
                    onChange={this.changeStatus}
                    value={getReadableStatus(task.status)}
                    suffixIcon={null}
                    data-cy="task-status-picker"
                    dropdownClassName="task-status-picker-menu"
                  >
                    {organisationDetails.taskStatuses?.map((status) => (
                      <Select.Option key={status.name} value={status.name}>
                        {getReadableStatus(status.name)}
                      </Select.Option>
                    ))}
                  </Select>
                )}
              </Typography.Text>
            </div>

            {!organisationDetails.settings?.task?.hideTaskDueDates && (
              <div className="stat">
                <Typography.Text className="stat-label">Due date</Typography.Text>
                <Typography.Text className="stat-value">
                  {task.isArchived || task.isFinished || !isAuthorised(["TASK_DETAILS.EDIT_DUE_DATE"]) ? (
                    <Typography.Text>
                      {task.dueDate ? moment(task.dueDate).format("DD MMM YYYY") : "Not set"}
                    </Typography.Text>
                  ) : (
                    <DatePicker
                      format="DD-MM-YYYY"
                      key={task.dueDate}
                      data-cy="task-due-date-picker"
                      className="active-on-hover"
                      defaultValue={task.dueDate ? moment(task.dueDate) : undefined}
                      onChange={this.changeDueDate}
                      placeholder="Not set"
                      suffixIcon={null}
                    />
                  )}
                </Typography.Text>
              </div>
            )}
            {organisationDetails.settings?.task?.usesStartAndEndDates && (
              <div className="stat">
                <Typography.Text className="stat-label">Start date</Typography.Text>
                <Typography.Text className="stat-value">
                  {task.isArchived || task.isFinished || !isAuthorised(["TASK_DETAILS.EDIT_DUE_DATE"]) ? (
                    <Typography.Text>
                      {task.startDate ? moment(task.startDate).format("DD MMM YYYY") : "Not set"}
                    </Typography.Text>
                  ) : (
                    <DatePicker
                      format="DD-MM-YYYY"
                      key={task.startDate}
                      data-cy="task-start-date-picker"
                      className="active-on-hover"
                      value={task.startDate ? moment(task.startDate) : undefined}
                      onChange={this.changeStartDate}
                      placeholder="Not set"
                      suffixIcon={null}
                      allowClear={false}
                    />
                  )}
                </Typography.Text>
              </div>
            )}
            {organisationDetails.settings?.task?.usesStartAndEndDates && (
              <div className="stat">
                <Typography.Text className="stat-label">End date</Typography.Text>
                <Typography.Text className="stat-value">
                  {task.isArchived || task.isFinished || !isAuthorised(["TASK_DETAILS.EDIT_DUE_DATE"]) ? (
                    <Typography.Text>
                      {task.endDate ? moment(task.endDate).format("DD MMM YYYY") : "Not set"}
                    </Typography.Text>
                  ) : (
                    <DatePicker
                      format="DD-MM-YYYY"
                      key={task.endDate}
                      data-cy="task-end-date-picker"
                      className="active-on-hover"
                      value={task.endDate ? moment(task.endDate) : undefined}
                      onChange={this.changeEndDate}
                      placeholder="Not set"
                      suffixIcon={null}
                      allowClear={false}
                    />
                  )}
                </Typography.Text>
              </div>
            )}

            <Link to={`/clients/${task.client?.id}`}>
              <div className="stat clickable link " data-cy="task-sidebar-client-container">
                <Typography.Text className="stat-label">
                  {getLabel({
                    id: "Client",
                    defaultValue: "Client",
                  })}
                </Typography.Text>
                <Typography.Text className="stat-value">
                  <ClientLogo client={task.client} />
                </Typography.Text>
              </div>
            </Link>
            <Link to={`/projects/${task.project?.id}`}>
              <div className="stat clickable link " data-cy="task-sidebar-project-container">
                <Typography.Text className="stat-label">{getSimpleLabel("Project")}</Typography.Text>
                <Typography.Text className="stat-value">{task.project?.title}</Typography.Text>
              </div>
            </Link>
            {organisationDetails.settings?.request?.usesRequests && task.requestIds?.length > 0 && (
              <div className="stat" data-cy="task-sidebar-requests-container">
                <Typography.Text className="stat-label">{getSimpleLabel("Requests")}</Typography.Text>
                <Typography.Text className="stat-value">
                  {task.requestIds.map((requestId) => (
                    <Link to={`/requests/${requestId}`} style={{ marginRight: "0.2rem" }}>
                      <Tag className="dark-tag" style={{ display: "inline-block", marginTop: "0.3rem" }}>
                        {processIdForDisplay(requestId)}
                      </Tag>
                    </Link>
                  ))}
                </Typography.Text>
              </div>
            )}

            <div className="stat created-by-container">
              <Typography.Text className="stat-label">Created by</Typography.Text>
              <Typography.Text className="stat-value created-by" data-cy="created-by">
                <Avatar user={authorData} showLabel={true} maxLabelLength={22} />
              </Typography.Text>
            </div>
            <div className="stat" data-cy="senior-engineer-responsible-for-project-container">
              <Typography.Text className="stat-label">
                Senior engineer <br /> responsible for {getSimpleLabel("project")}
              </Typography.Text>
              <Typography.Text className="stat-value">
                {task.project.isArchived ? (
                  <Avatar user={users.find((x) => x.id === task.project.assignedTo)} showLabel={true} />
                ) : (
                  <UsersFilter
                    activateOnHover={true}
                    value={task.project.assignedTo}
                    data-cy="senior-engineer-responsible-for-project-picker"
                    onChange={(assignedTo) =>
                      this.assignProjectToUser({
                        project: task.project,
                        user: users.find((x) => x.id === assignedTo),
                      })
                    }
                    excludeList={this.getExcludedAssigneeList()}
                    suffixIcon={null}
                    maxLabelLength={22}
                  />
                )}
              </Typography.Text>
            </div>
            <div className="stat">
              <Typography.Text className="stat-label">Created</Typography.Text>
              <Typography.Text className="stat-value">{moment(task.createdAt).fromNow()}</Typography.Text>
            </div>
            <div className="stat">
              <Typography.Text className="stat-label">Last updated</Typography.Text>
              <Typography.Text className="stat-value">{moment(task.updatedAt).fromNow()}</Typography.Text>
            </div>

            {this.displayLinkedTasks()}
          </div>
        </Card>
        {this.state.isCatLevelModalVisible && (
          <CatLevelModal
            key={`cat-level-modal-${task.catLevel}`}
            visible={true}
            onClose={() => this.setState({ isCatLevelModalVisible: false })}
            task={task}
            users={users}
            organisationDetails={organisationDetails}
          />
        )}

        {this.state.isLinkTaskModalVisible && (
          <LinkTaskModal
            visible={this.state.isLinkTaskModalVisible}
            onClose={() => this.setState({ isLinkTaskModalVisible: false })}
            task={task}
            tasks={tasks}
            apiUser={apiUser}
            organisationDetails={this.props.organisationDetails}
          />
        )}

        {this.state.isMapModalVisible && this.state.selectedField && this.displayAddressModal()}
        {this.state.isMultipleAssignedUsersModalVisible && (
          <MultipleAssignedUsersModal
            taskId={task.id}
            startDate={task.startDate}
            endDate={task.endDate}
            assignedToUsers={task.assignedToUsers}
            onClose={() => {
              this.setState({ isMultipleAssignedUsersModalVisible: false });
            }}
          />
        )}
      </>
    );
  }
}

export default withRouter(
  withSubscriptions({
    Component: TaskSidebar,
    subscriptions: ["organisationDetails", "sprints", "tasks", "users", "groups"],
  })
);
