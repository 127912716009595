import React from "react";
import moment from "moment";
import { withRouter } from "react-router-dom";
import { Button, Typography, Input, InputNumber, Checkbox, Switch, Dropdown, Select, Menu, Tag } from "antd";
import query from "query-string";
import { PlusCircleOutlined, SearchOutlined, DownOutlined } from "@ant-design/icons";

import { CUSTOM_TASK_FIELDS_WITHOUT_FILTERS } from "common/constants";
import { getLabel, getUppercaseStatus } from "common/helpers";
import withSubscriptions from "common/withSubscriptions";
import { getSimpleLabel } from "common/labels";
import { isAuthorised, getGroupNamesForUser } from "common/permissions";

import DatePicker from "DatePicker/DatePicker";
import AvatarList from "AvatarList/AvatarList";
import Explanation from "Explanation/Explanation";
import OverallSpinner from "OverallSpinner/OverallSpinner";
import CreateTaskModal from "CreateTaskModal/CreateTaskModal";
import UsersFilter from "UsersFilter/UsersFilter";
import ButtonWithPermissions from "ButtonWithPermissions/ButtonWithPermissions";

import "./TaskFilters.scss";

export class TaskFilters extends React.Component {
  state = {
    containsText: "",
    selectedUsers: [],
    sprintId: "all",
    clientId: null,
    projectId: null,
    includeActive: true,
    includeArchived: false,
    e: false,
    includeUnconfirmed: true,
    onlyReviewsRequested: false,
    onlyReviewsAssigned: false,
    onlyReviewsThatNeedAction: false,
    onlyTasksInReview: false,
    dueDateStart: null,
    dueDateEnd: null,
    createdAtStart: null,
    createdAtEnd: null,
    finishedAtStart: null,
    finishedAtEnd: null,
    isCreateTaskModalVisible: false,
    isDropdownVisible: false,
    onlyWithoutDueDate: false,
    isLoadingTasks: false,
    taskStatus: null,
  };

  async componentDidMount() {
    const { location, sprints, apiUser, defaultFilter, organisationDetails, cookieName } = this.props;

    if (!cookieName) {
      throw new Error("You have not provided a cookie name for TaskFilters");
    }

    const queryParams = query.parse(location.search);
    const backlog = [...sprints].sort((a, b) => (a.createdAt < b.createdAt ? -1 : 1))[0];
    let newState = {};

    const cookieValue = await window.localDatabase.getItem(`${cookieName}-${this.props.organisationDetails.id}`);

    if (cookieValue) {
      try {
        const cookieValueParsed = JSON.parse(cookieValue);
        const { isDropdownVisible, isCreateTaskModalVisible, ...existingFilters } = cookieValueParsed;
        newState = existingFilters;
      } catch (e) {
        console.error("Failed to parse local database value for TaskFilters:", cookieValue);
      }
    }

    if (defaultFilter) {
      newState = { ...newState, ...defaultFilter };
    }

    if (queryParams.containsText) {
      newState.containsText = queryParams.containsText;
    }

    if (organisationDetails.usesSprints) {
      if (queryParams.sprintId) {
        newState.sprintId = queryParams.sprintId;
      } else if (location.pathname === "/") {
        const activeSprint = sprints.find(
          (x) => x.organisation === apiUser.organisation && x.isActive && !x.isFinished
        );
        if (activeSprint) {
          newState.sprintId = activeSprint.id;
        } else if (backlog) {
          newState.sprintId = backlog.id;
        }
      }
    }

    if (queryParams.selectedUsers) {
      if (Array.isArray(queryParams.selectedUsers)) {
        newState.selectedUsers = queryParams.selectedUsers;
      } else {
        newState.selectedUsers = [queryParams.selectedUsers];
      }
    }

    if (newState.selectedUsers) {
      // eliminate users that are not found in the users array
      newState.selectedUsers = newState.selectedUsers.filter((userId) =>
        this.props.users.find((user) => user.id === userId)
      );
    }

    if (queryParams.dueDateStart) {
      newState.dueDateStart = queryParams.dueDateStart;
    }

    if (queryParams.dueDateEnd) {
      newState.dueDateEnd = queryParams.dueDateEnd;
    }

    if (queryParams.createdAtStart) {
      newState.createdAtStart = queryParams.createdAtStart;
    }

    if (queryParams.createdAtEnd) {
      newState.createdAtEnd = queryParams.createdAtEnd;
    }

    if (queryParams.finishedAtStart) {
      newState.finishedAtStart = queryParams.finishedAtStart;
    }

    if (queryParams.finishedAtEnd) {
      newState.finishedAtEnd = queryParams.finishedAtEnd;
    }

    if (queryParams.includeActive) {
      newState.includeActive = queryParams.includeActive === "true";
    }

    if (queryParams.includeArchived) {
      newState.includeArchived = queryParams.includeArchived === "true";
    }

    if (queryParams.includeFinished) {
      newState.includeFinished = queryParams.includeFinished === "true";
    }

    if (queryParams.includeUnconfirmed) {
      newState.includeUnconfirmed = queryParams.includeUnconfirmed === "true";
    }

    if (queryParams.taskStatus) {
      newState.taskStatus = queryParams.taskStatus;
    }

    if (queryParams.onlyReviewsRequested) {
      newState.onlyReviewsRequested = queryParams.onlyReviewsRequested === "true";
    }

    if (queryParams.onlyTasksInReview) {
      newState.onlyTasksInReview = queryParams.onlyTasksInReview === "true";
    }

    if (queryParams.onlyReviewsAssigned) {
      newState.onlyReviewsAssigned = queryParams.onlyReviewsAssigned === "true";
    }

    if (queryParams.onlyReviewsThatNeedAction) {
      newState.onlyReviewsThatNeedAction = queryParams.onlyReviewsThatNeedAction === "true";
    }

    if (queryParams.clientId) {
      newState.clientId = queryParams.clientId;
    }

    if (queryParams.projectId) {
      newState.projectId = queryParams.projectId;
    }

    if (queryParams.onlyWithoutDueDate) {
      newState.onlyWithoutDueDate = queryParams.onlyWithoutDueDate === "true";
    }

    this.setState(newState, () => this.onChange());
  }

  getFieldStateName = (fieldId) => {
    return `custom_field_${fieldId}`;
  };

  onChange = async () => {
    const {
      selectedUsers,
      containsText,
      dueDateStart,
      dueDateEnd,
      createdAtStart,
      createdAtEnd,
      finishedAtStart,
      finishedAtEnd,
      sprintId,
      onlyReviewsRequested,
      onlyReviewsAssigned,
      onlyReviewsThatNeedAction,
      onlyTasksInReview,
      clientId,
      projectId,
      onlyWithoutDueDate,
      includeUnconfirmed,
      taskStatus,
    } = this.state;

    const { history, location, apiUser, organisationDetails } = this.props;

    const nonNullKeys = Object.keys(this.state).filter(
      (keyName) =>
        this.state[keyName] !== null &&
        this.state[keyName] !== undefined &&
        this.state[keyName] !== "" &&
        this.state[keyName] !== false
    );

    const queryParams = {};
    nonNullKeys.forEach((keyName) => {
      queryParams[keyName] = this.state[keyName];
    });

    const PROPERTIES_TO_EXCLUDE_FROM_QUERY = ["isCreateTaskModalVisible", "isDropdownVisible", "context", "callbacks"];

    PROPERTIES_TO_EXCLUDE_FROM_QUERY.forEach((key) => delete queryParams[key]);
    for (let key in queryParams) {
      if (key.startsWith("custom_field_")) {
        delete queryParams[key];
      }
    }

    const queryString = query.stringify(queryParams);

    history.replace(`${location.pathname}?${queryString}`);

    let filter = {
      callbacks: [(task) => !task.isExternalReference],
    };

    if (!this.state.includeActive) {
      filter.callbacks.push((task) => task.isArchived || task.isFinished);
    }

    if (!this.state.includeArchived) {
      filter.callbacks.push((task) => {
        return !task.isArchived;
      });
    }

    if (!this.state.includeFinished) {
      filter.callbacks.push((task) => !task.isFinished);
    }

    if (organisationDetails.usesSprints && sprintId && sprintId !== "all") {
      filter.callbacks.push((task) => task.sprintId === sprintId);
    }

    if (onlyReviewsRequested) {
      filter.callbacks.push((task) => task.assignedTo === apiUser.id && task.isUnderReview);
    }

    if (onlyReviewsAssigned) {
      filter.callbacks.push((task) => {
        return task.checkedBy === apiUser.id && task.isUnderReview;
      });
    }

    if (onlyReviewsThatNeedAction) {
      filter.callbacks.push((task) => {
        if (!task.isUnderReview) {
          return false;
        }

        if (task.reviewStatus === "IN_PROGRESS" || task.reviewSecondaryStatus) {
          return task.checkedBy === apiUser.id;
        } else if (task.reviewStatus === "WITH_COMMENTS" || task.reviewStatus === "CHANGES_REQUESTED") {
          return task.assignedTo === apiUser.id;
        }
      });
    }

    if (onlyTasksInReview) {
      filter.callbacks.push((task) => task.isUnderReview);
    }

    if (!includeUnconfirmed) {
      filter.callbacks.push((task) => task.isConfirmed);
    }

    if (clientId) {
      filter.callbacks.push((task) => task.clientId === clientId);
    }

    if (projectId) {
      filter.callbacks.push((task) => task.projectId === projectId);
    }

    if (taskStatus) {
      filter.callbacks.push(
        (task) => task.status?.toUpperCase().split(" ").join("_") === taskStatus.toUpperCase().split(" ").join("_")
      );
    }

    if (dueDateStart && dueDateEnd) {
      filter.dueDate = { between: [dueDateStart, dueDateEnd] };
    }

    if (createdAtStart && createdAtEnd) {
      filter.createdAt = { between: [createdAtStart, createdAtEnd] };
    }

    if (finishedAtStart && finishedAtEnd) {
      filter.finishedAt = { between: [finishedAtStart, finishedAtEnd] };
    }

    if (onlyWithoutDueDate) {
      filter.callbacks.push((task) => !task.dueDate);
    }

    if (selectedUsers && selectedUsers.length > 0) {
      filter.callbacks.push((task) => {
        if (selectedUsers.includes("unassigned") && !task.assignedTo) {
          return true;
        } else if (selectedUsers.includes(task.assignedTo)) {
          return true;
        } else {
          return false;
        }
      });
    }

    if (containsText) {
      filter.containsText = {
        contains: (containsText || "").toLowerCase(),
      };
    }

    for (let stateProperty in this.state) {
      const value = this.state[stateProperty];

      if (stateProperty.startsWith("custom_field") && value !== undefined && value !== null) {
        if (typeof value === "string" && value.length === 0) {
          continue;
        }

        if (Array.isArray(value)) {
          if (value.length === 0) {
            continue;
          }
          if (value.every((x) => x === undefined || x === null)) {
            continue;
          }
        }

        const fieldName = stateProperty.replace("custom_field_", "");
        const fieldDefinition = organisationDetails.customFields.find((x) => x.id === fieldName);
        filter.callbacks.push((task) => {
          const taskCustomField = task.customFields?.find((x) => x.id === fieldName);

          if (fieldDefinition.type !== "CHECKBOX") {
            if (!taskCustomField || !taskCustomField.value) {
              return false;
            }
            if (typeof value === "string" && value.length === 0) {
              return false;
            }
            if (Array.isArray(value) && value.length === 0) {
              return false;
            }
          }
          switch (fieldDefinition.type) {
            case "TEXT":
              return taskCustomField.value.toLowerCase().includes(value.toLowerCase());
            case "TEXTAREA":
              return taskCustomField.value
                .toLowerCase()
                .split("\n")
                .join(" ")
                .split("  ")
                .join(" ")
                .includes(value.toLowerCase());
            case "NUMBER":
              taskCustomField.value = parseInt(taskCustomField.value);
              if (typeof value[0] === "number") {
                value[0] = String(value[0]);
              }
              if (typeof value[1] === "number") {
                value[1] = String(value[1]);
              }
              if (!value[0] || value[0].length === 0) {
                value[0] = undefined;
              }
              if (!value[1] || value[1].length === 0) {
                value[1] = undefined;
              }
              if (value[0] === undefined && value[1] === undefined) {
                return true;
              }
              if (value[0] && value[0].length > 0) {
                value[0] = parseInt(value[0] || 0);
                if (parseInt(taskCustomField.value) < value[0]) {
                  return false;
                }
              }
              if (value[1] && value[1].length > 0) {
                value[1] = parseInt(value[1] || 0);
                if (taskCustomField.value > value[1]) {
                  return false;
                }
              }
              return true;

            case "CHECKBOX":
              if (!value || value === "either") {
                return true;
              } else if (value === "yes") {
                return taskCustomField?.value === "checked";
              } else {
                return taskCustomField?.value !== "checked";
              }
            case "CHECKBOX_LIST":
              const parsedTaskCustomField = JSON.parse(taskCustomField.value);
              for (let valueElement of value) {
                if (!parsedTaskCustomField.includes(valueElement)) {
                  return false;
                }
              }
              return true;
            case "USER":
              return taskCustomField.value === value;
            case "DATE":
              return (
                moment(taskCustomField.value).isSameOrAfter(value[0]) &&
                moment(taskCustomField.value).isSameOrBefore(value[1])
              );

            default:
              return taskCustomField.value === value;
          }
        });
      }
    }

    if (this.props.cookieName) {
      window.localDatabase.setItem(
        `${this.props.cookieName}-${this.props.organisationDetails.id}`,
        JSON.stringify(this.state)
      );
    }
    this.props.onChange(filter);
  };

  onUserSelected = (_, user) => {
    if (!user) {
      user = { id: "unassigned" };
    }
    let selectedUsers = this.state.selectedUsers || [];
    if (!Array.isArray(selectedUsers)) {
      selectedUsers = [];
    }
    if (selectedUsers.includes(user.id)) {
      selectedUsers = selectedUsers.filter((x) => x !== user.id);
    } else {
      selectedUsers.push(user.id);
    }
    this.setState({ selectedUsers }, this.onChange);
  };

  changeDueDateRange = (dates) => {
    let newDueDateStart = null;
    let newDueDateEnd = null;
    if (dates) {
      newDueDateStart = dates[0].startOf("day").format("YYYY-MM-DD");
      newDueDateEnd = dates[1].endOf("day").format("YYYY-MM-DD");
    }

    this.setState(
      {
        dueDateStart: newDueDateStart,
        dueDateEnd: newDueDateEnd,
      },
      this.onChange
    );
  };

  changeCreatedAtDateRange = (dates) => {
    let newCreatedAtStart = null;
    let newCreatedAtEnd = null;
    if (dates) {
      newCreatedAtStart = dates[0].startOf("day").format("YYYY-MM-DD");
      newCreatedAtEnd = dates[1].endOf("day").format("YYYY-MM-DD");
    }

    this.setState(
      {
        createdAtStart: newCreatedAtStart,
        createdAtEnd: newCreatedAtEnd,
      },
      this.onChange
    );
  };

  changeFinishedAtDateRange = (dates) => {
    let newFinishedAtStart = null;
    let newFinishedAtEnd = null;
    if (dates) {
      newFinishedAtStart = dates[0].startOf("day").format("YYYY-MM-DD");
      newFinishedAtEnd = dates[1].endOf("day").format("YYYY-MM-DD");
    }

    this.setState(
      {
        finishedAtStart: newFinishedAtStart,
        finishedAtEnd: newFinishedAtEnd,
      },
      this.onChange
    );
  };

  displayCustomFilter = (organisationDetails, customField) => {
    const { type, id } = customField;

    const key = this.getFieldStateName(id);
    const value = this.state[key];

    switch (type) {
      case "TEXT":
        return (
          <Input
            className="filter-input"
            placeholder=""
            allowClear
            onChange={(e) => this.setState({ [key]: e.target.value }, this.onChange)}
            value={value}
            data-cy="filter-task-input"
          />
        );
      case "TEXTAREA":
        return (
          <Input
            className="filter-input"
            placeholder=""
            allowClear
            onChange={(e) => this.setState({ [key]: e.target.value }, this.onChange)}
            value={value}
            data-cy="filter-task-input"
          />
        );

      case "NUMBER":
        return (
          <>
            <InputNumber
              className="filter-input"
              placeholder="Min"
              allowClear
              stringMode={true}
              onChange={(inputValue) =>
                this.setState(
                  {
                    [key]: [inputValue, (value || [])[1]],
                  },
                  this.onChange
                )
              }
              value={(value || [])[0]}
              data-cy="filter-task-input"
            />
            <InputNumber
              className="filter-input"
              placeholder="Max"
              allowClear
              stringMode={true}
              onChange={(inputValue) =>
                this.setState(
                  {
                    [key]: [(value || [])[0], inputValue],
                  },
                  this.onChange
                )
              }
              value={(value || [])[1]}
              data-cy="filter-task-input"
            />
          </>
        );
      case "RADIO_LIST":
        return (
          <Select
            showSearch
            allowClear
            placeholder={`Choose a ${customField.label.toLowerCase()}`}
            className="filter-input"
            onChange={(option) => {
              this.setState({ [key]: option }, this.onChange);
            }}
            value={value}
          >
            {customField.options?.map((option, index) => (
              <Select.Option key={index} value={option.value}>
                {option.label}
              </Select.Option>
            ))}
          </Select>
        );
      case "CHECKBOX":
        return (
          <Select
            showSearch
            allowClear
            placeholder="Yes / No / Either?"
            className="filter-input"
            onChange={(option) => {
              this.setState({ [key]: option }, this.onChange);
            }}
            value={value}
          >
            <Select.Option key="either" value="either">
              Either
            </Select.Option>
            <Select.Option key="yes" value="yes">
              Yes
            </Select.Option>
            <Select.Option key="no" value="no">
              No
            </Select.Option>
          </Select>
        );
      case "CHECKBOX_LIST":
        return (
          <Checkbox.Group
            showSearch
            allowClear
            className="filter-input"
            onChange={(option) => {
              this.setState({ [key]: option }, this.onChange);
            }}
            value={value}
          >
            {customField.options &&
              customField.options.map((option, i) => (
                <Checkbox key={i} value={option.value}>
                  {option.label}
                </Checkbox>
              ))}
          </Checkbox.Group>
        );
      case "USER":
        return (
          <UsersFilter
            onChange={(option) => {
              this.setState({ [key]: option }, this.onChange);
            }}
            value={value}
          />
        );

      case "DATE":
        return (
          <DatePicker.RangePicker
            format="DD-MM-YYYY"
            onChange={(dates) => {
              this.setState({ [key]: dates }, this.onChange);
            }}
            placeholder={["Start date", "End date"]}
            value={value}
            suffixIcon={null}
          />
        );
      default:
        return null;
    }
  };

  displayCustomFieldsFilters = (organisationDetails) => {
    const { apiUser, groups } = this.props;
    const { customFields } = organisationDetails;

    const groupNamesForUser = getGroupNamesForUser(apiUser.id, groups);

    if (customFields?.length > 0) {
      return customFields
        .filter((field) => {
          if (CUSTOM_TASK_FIELDS_WITHOUT_FILTERS.includes(field.type)) {
            return false;
          }

          if (!field.groupsThatCanSee || field.groupsThatCanSee.length === 0) {
            return true;
          }

          return field.groupsThatCanSee.some((group) => groupNamesForUser.includes(group));
        })
        .map((customField, i) => (
          <div key={i}>
            <Menu.Item className="flex-menu-item custom-task-fields-wrapper" key="custom-field">
              <Typography.Text className="left-label">{customField.label}: </Typography.Text>

              <div className="input-container">{this.displayCustomFilter(organisationDetails, customField)}</div>
            </Menu.Item>
            <Menu.Divider />
          </div>
        ));
    }
  };

  render() {
    const {
      containsText,
      selectedUsers,
      includeActive,
      includeArchived,
      includeFinished,
      includeUnconfirmed,
      isCreateTaskModalVisible,
      onlyReviewsRequested,
      onlyReviewsAssigned,
      onlyReviewsThatNeedAction,
      onlyTasksInReview,
      isDropdownVisible,
      clientId,
      projectId,
      onlyWithoutDueDate,
      isLoadingTasks,
    } = this.state;

    const {
      clients,
      projects,
      sprints,
      users,
      apiUser,
      isDisplayedOnProjectDetailsPage,
      organisationDetails,
      orderedActiveUsers,
    } = this.props;

    const filterMenu = (
      <Menu>
        <Menu.Item key="include-active">
          <Checkbox
            checked={includeActive}
            onChange={(e) => this.setState({ includeActive: e.target.checked }, this.onChange)}
          >
            Include active
          </Checkbox>
        </Menu.Item>
        <Menu.Item key="include-archived">
          <Checkbox
            checked={includeArchived}
            onChange={(e) => this.setState({ includeArchived: e.target.checked }, this.onChange)}
          >
            Include archived
          </Checkbox>
        </Menu.Item>
        <Menu.Item key="include-finished">
          <Checkbox
            checked={includeFinished}
            onChange={(e) => this.setState({ includeFinished: e.target.checked }, this.onChange)}
          >
            Include finished
          </Checkbox>
        </Menu.Item>
        {organisationDetails.settings?.general?.usesTaskConfirmation && (
          <Menu.Item key="include-unconfirmed">
            <Checkbox
              checked={includeUnconfirmed}
              onChange={(e) => this.setState({ includeUnconfirmed: e.target.checked }, this.onChange)}
            >
              Include{" "}
              {getLabel({
                organisationDetails,
                id: "Unconfirmed",
                defaultValue: "Unconfirmed",
              }).toLowerCase()}
            </Checkbox>
          </Menu.Item>
        )}
        {organisationDetails.usesReviews && (
          <>
            <Menu.Divider />
            <Menu.Item key="reviews-i-need-to-action">
              <Switch
                checkedChildren="Yes"
                unCheckedChildren="No"
                checked={onlyReviewsThatNeedAction}
                onChange={(checked) => {
                  let newState = { onlyReviewsThatNeedAction: checked };
                  if (checked) {
                    newState.onlyReviewsAssigned = false;
                    newState.onlyReviewsRequested = false;
                    newState.onlyTasksInReview = false;
                  }
                  this.setState(newState, this.onChange);
                }}
              />
              <Typography.Text className="right-label">
                Only reviews I need to <br />
                take action on
              </Typography.Text>
            </Menu.Item>
            <Menu.Item key="what-i-need-to-review">
              <Switch
                checkedChildren="Yes"
                unCheckedChildren="No"
                checked={onlyReviewsAssigned}
                onChange={(checked) => {
                  let newState = { onlyReviewsAssigned: checked };
                  if (checked) {
                    newState.onlyReviewsRequested = false;
                    newState.onlyTasksInReview = false;
                    newState.onlyReviewsThatNeedAction = false;
                  }
                  this.setState(newState, this.onChange);
                }}
              />
              <Typography.Text className="right-label">Only where I am the reviewer</Typography.Text>
            </Menu.Item>
            <Menu.Item key="reviews-i-requested">
              <Switch
                checkedChildren="Yes"
                unCheckedChildren="No"
                checked={onlyReviewsRequested}
                onChange={(checked) => {
                  let newState = { onlyReviewsRequested: checked };
                  if (checked) {
                    newState.onlyReviewsThatNeedAction = false;
                    newState.onlyReviewsAssigned = false;
                    newState.onlyTasksInReview = false;
                  }
                  this.setState(newState, this.onChange);
                }}
              />
              <Typography.Text className="right-label">Only reviews I requested</Typography.Text>
            </Menu.Item>
            <Menu.Item key="all-under-review">
              <Switch
                checkedChildren="Yes"
                unCheckedChildren="No"
                checked={onlyTasksInReview}
                onChange={(checked) => {
                  let newState = { onlyTasksInReview: checked };
                  if (checked) {
                    newState.onlyReviewsThatNeedAction = false;
                    newState.onlyReviewsRequested = false;
                    newState.onlyReviewsAssigned = false;
                  }
                  this.setState(newState, this.onChange);
                }}
              />
              <Typography.Text className="right-label">All under review</Typography.Text>
            </Menu.Item>
          </>
        )}

        <Menu.Divider />
        <Menu.Item key="only-without-date">
          <Checkbox
            checked={onlyWithoutDueDate}
            onChange={(e) => this.setState({ onlyWithoutDueDate: e.target.checked }, this.onChange)}
          >
            Only without due date
          </Checkbox>
        </Menu.Item>
        <Menu.Item key="due-in-range">
          <div className="due-in-range-container">
            <Typography.Text className="top-label">Due in range:</Typography.Text>
            <br />
            <DatePicker.RangePicker
              format="DD-MM-YYYY"
              dropdownClassName="task-due-date-range-picker"
              value={[
                this.state.dueDateStart && moment(this.state.dueDateStart),
                this.state.dueDateEnd && moment(this.state.dueDateEnd),
              ]}
              onChange={this.changeDueDateRange}
              ranges={{
                "Last 4 weeks": [moment().startOf("week").subtract(4, "weeks"), moment()],
                "Due today": [moment().startOf("day"), moment().endOf("day")],
                Overdue: [moment().subtract(10, "years"), moment().subtract(1, "days").endOf("day")],
                "This week": [moment().startOf("week"), moment().endOf("week")],
                "Following week": [
                  moment().add(1, "week").startOf("week"),
                  moment().startOf("week").add(1, "weeks").endOf("week"),
                ],
                "Next 2 weeks": [moment().startOf("week"), moment().startOf("week").add(1, "weeks").endOf("week")],
                "Next 4 weeks": [moment().startOf("week"), moment().startOf("week").add(4, "weeks").endOf("week")],
                "This month": [moment().startOf("month"), moment().startOf("month").endOf("month")],
              }}
            />
          </div>
        </Menu.Item>
        <Menu.Divider />
        <Menu.Item key="created-in-range">
          <div className="created-in-range-container">
            <Typography.Text className="top-label">Created in range:</Typography.Text>
            <br />
            <DatePicker.RangePicker
              format="DD-MM-YYYY"
              dropdownClassName="task-created-in-range-picker"
              value={[
                this.state.createdAtStart && moment(this.state.createdAtStart),
                this.state.createdAtEnd && moment(this.state.createdAtEnd),
              ]}
              onChange={this.changeCreatedAtDateRange}
              ranges={{
                "Previous year": [
                  moment().startOf("year").subtract(1, "year"),
                  moment().startOf("year").subtract(1, "second"),
                ],
                YTD: [moment().startOf("year"), moment()],
                "Last 12 months": [moment().subtract(1, "day").subtract(12, "months"), moment().subtract(1, "day")],
                "Last 6 months": [moment().subtract(6, "months").startOf("month"), moment()],
                "Last 4 weeks": [moment().startOf("week").subtract(4, "weeks"), moment()],
                "Created today": [moment().startOf("day"), moment().endOf("day")],
                "This week": [moment().startOf("week"), moment().endOf("week")],
                "This month": [moment().startOf("month"), moment().startOf("month").endOf("month")],
              }}
            />
          </div>
        </Menu.Item>
        <Menu.Divider />
        <Menu.Item key="finished-in-range">
          <div className="finished-in-range-container">
            <Typography.Text className="top-label">Finished in range:</Typography.Text>
            <br />
            <DatePicker.RangePicker
              format="DD-MM-YYYY"
              dropdownClassName="task-finished-in-range-picker"
              value={[
                this.state.finishedAtStart && moment(this.state.finishedAtStart),
                this.state.finishedAtEnd && moment(this.state.finishedAtEnd),
              ]}
              onChange={this.changeFinishedAtDateRange}
              ranges={{
                "Last 12 months": [moment().subtract(1, "day").subtract(12, "months"), moment().subtract(1, "day")],
                "Last 6 months": [moment().subtract(6, "months").startOf("month"), moment()],
                "Last 4 weeks": [moment().startOf("week").subtract(4, "weeks"), moment()],
                "Finished today": [moment().startOf("day"), moment().endOf("day")],
                "This week": [moment().startOf("week"), moment().endOf("week")],
                "This month": [moment().startOf("month"), moment().startOf("month").endOf("month")],
              }}
            />
          </div>
        </Menu.Item>
        <Menu.Divider />
        <Menu.Item className="flex-menu-item" key="client">
          <Typography.Text className="left-label">Client:</Typography.Text>

          <Select
            showSearch
            allowClear
            placeholder="Choose a client"
            className="task-filter-client"
            filterOption={(input, option) => {
              return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
            }}
            onChange={(clientId) => {
              this.setState({ clientId }, this.onChange);
            }}
            value={clientId}
          >
            {clients.map((client, i) => (
              <Select.Option key={i} value={client.id}>
                {client.name}
              </Select.Option>
            ))}
          </Select>
        </Menu.Item>
        <Menu.Divider />
        <Menu.Item className="flex-menu-item" key="project">
          <Typography.Text className="left-label">
            {getLabel({
              organisationDetails,
              id: "Project",
              defaultValue: "Project",
            })}
            :
          </Typography.Text>

          <Select
            showSearch
            allowClear
            placeholder={`Choose a ${getLabel({
              organisationDetails,
              id: "project",
              defaultValue: "project",
            })}`}
            className="task-filter-project"
            filterOption={(input, option) => {
              return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
            }}
            onChange={(projectId) => {
              this.setState({ projectId }, this.onChange);
            }}
            value={projectId}
          >
            {projects
              .filter((project) => !project.isArchived && !project.id.includes("TEMPLATES"))
              .map((project, i) => (
                <Select.Option key={i} value={project.id}>
                  {project.title}
                </Select.Option>
              ))}
          </Select>
        </Menu.Item>
        {organisationDetails.usesSprints && (
          <>
            <Menu.Divider />
            <Menu.Item className="flex-menu-item" key="sprint">
              <Typography.Text className="left-label">
                {getLabel({
                  organisationDetails,
                  id: "Sprint",
                  defaultValue: "Sprint",
                })}
                :
              </Typography.Text>
              <Select
                className="task-filter-sprint"
                onChange={(sprintId) => this.setState({ sprintId }, this.onChange)}
                value={this.state.sprintId}
              >
                {[
                  ...sprints,
                  {
                    id: "all",
                    name: `All ${getLabel({
                      organisationDetails,
                      id: "sprints",
                      defaultValue: "sprints",
                    })}`,
                  },
                ]
                  .reverse()
                  .filter((x) => !x.isFinished)
                  .map((sprint, i) => (
                    <Select.Option key={i} value={sprint.id}>
                      {sprint.name}{" "}
                      {sprint.isActive && (
                        <Tag color="green" style={{ marginLeft: "0.5rem" }}>
                          {getLabel({
                            organisationDetails,
                            id: "Sprint-Active",
                            defaultValue: "Active",
                          })}
                        </Tag>
                      )}
                    </Select.Option>
                  ))}
              </Select>
            </Menu.Item>
          </>
        )}
        <Menu.Divider />
        <Menu.Item className="flex-menu-item" key="status">
          <Typography.Text className="left-label">Status:</Typography.Text>

          <Select
            showSearch
            allowClear
            placeholder="Choose a status"
            className="task-filter-status"
            filterOption={(input, option) => {
              return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
            }}
            onChange={(taskStatus) => {
              this.setState({ taskStatus }, this.onChange);
            }}
            value={this.state.taskStatus}
          >
            {organisationDetails.taskStatuses?.map((taskStatus, i) => (
              <Select.Option key={i} value={getUppercaseStatus(taskStatus.name)}>
                {taskStatus.name}
              </Select.Option>
            ))}
          </Select>
        </Menu.Item>
        <Menu.Divider />
        {this.displayCustomFieldsFilters(organisationDetails)}
      </Menu>
    );

    return (
      <div className="task-filters" data-cy="task-filters-container">
        <div className="left-buttons">
          <Input
            className="filter-input"
            placeholder={`Filter ${getLabel({
              organisationDetails: this.props.organisationDetails,
              id: "task",
              defaultValue: "task",
            })} list`}
            prefix={<SearchOutlined />}
            allowClear
            onChange={(e) => this.setState({ containsText: e.target.value }, this.onChange)}
            value={containsText}
            data-cy="filter-task-input"
          />
          <Explanation
            title={`You can filter by ${getSimpleLabel("task")} name, ${getSimpleLabel(
              "project"
            )} name, ${getSimpleLabel("client")} name, assignee name`}
            placement="bottom"
          />
          <AvatarList
            users={orderedActiveUsers}
            onClick={this.onUserSelected}
            selectedUsers={selectedUsers}
            containerWidthToSubtract={this.props.avatarListWidthToSubtract}
          />

          <div className="task-filters-container">
            <Dropdown
              trigger={["click"]}
              overlayClassName={`task-filters-dropdown-overlay ${
                isDisplayedOnProjectDetailsPage ? "project-details-page-task-filters" : ""
              }`}
              overlay={filterMenu}
              open={isDropdownVisible}
              onVisibleChange={(isDropdownVisible) => this.setState({ isDropdownVisible })}
            >
              <Button className="filter-button" icon={<DownOutlined />} data-cy="task-filters-button">
                {this.props.label ? this.props.label : "Filters"}{" "}
              </Button>
            </Dropdown>
          </div>

          {this.props.includeExportButton && isAuthorised(["EXPORT_TASKS"]) && (
            <Button className="export" onClick={this.props.export} data-cy="export-to-csv-button">
              Export to CSV
            </Button>
          )}
        </div>
        {this.props.includeCreateTask === false ? null : (
          <ButtonWithPermissions
            type="primary"
            className="create-task"
            onClick={() => this.setState({ isCreateTaskModalVisible: true })}
            icon={<PlusCircleOutlined />}
            data-cy="create-task-button"
            permissions={["CREATE_TASK"]}
          >
            Create{" "}
            {getLabel({
              organisationDetails: this.props.organisationDetails,
              id: "task",
              defaultValue: "task",
            })}
          </ButtonWithPermissions>
        )}

        {isCreateTaskModalVisible && (
          <CreateTaskModal onClose={() => this.setState({ isCreateTaskModalVisible: false })} apiUser={apiUser} />
        )}
        {isLoadingTasks && <OverallSpinner />}
      </div>
    );
  }
}

export default withRouter(
  withSubscriptions({
    Component: TaskFilters,
    subscriptions: ["organisationDetails", "sprints", "tasks", "users", "clients", "projects", "groups"],
  })
);
