import { getFieldValue, getFieldDetailsById } from "common/documentEditorDataSources/aggregator";

export async function replaceDynamicFormFields({ form, params }) {
  for (let formFieldId in form.fields) {
    const formField = form.fields[formFieldId];
    if (formField.useDynamicOptions) {
      const paramsForGetField = {
        dataSource: formField.dataSource,
        id: formField.dataSourceField,
        fieldId: formField.dataSourceField,
        form,
        ...params,
      };
      let dataFieldDetails = getFieldDetailsById(paramsForGetField);

      if (dataFieldDetails) {
        let repeatedDataList = await getFieldValue(paramsForGetField);
        if (formField.dataSourceField?.startsWith("sheets_")) {
          let options = [];
          for (let sheet of repeatedDataList) {
            if (!sheet.revisions.items || sheet.revisions.items.length === 0) {
              continue;
            }
            options.push({
              label: `${sheet.file.name}${sheet.description ? ` - ${sheet.description}` : ""} (${sheet.name})`,
              value: `${sheet.autoGeneratedReferenceNumber}-${sheet.description} ${
                sheet.revisions.items.slice(-1)[0].name
              }`,
            });
          }
          formField.options = options;
        } else if (formField.dataSourceField?.startsWith("sheet_ids_")) {
          let options = [];
          for (let sheet of repeatedDataList) {
            if (!sheet.revisions.items || sheet.revisions.items.length === 0) {
              continue;
            }
            options.push({
              label: `${sheet.file.name}${sheet.description ? ` - ${sheet.description}` : ""} (${sheet.name})`,
              value: sheet.id,
            });
          }
          formField.options = options;
        } else {
          formField.options = repeatedDataList.map((item) => {
            return {
              label: item.name,
              value: item.id,
            };
          });
        }
      }

      // if we have a radio button list where the selected option is not one of the available options
      // and we have the "auto select first option" flag enabled, then we need to select the first of the available options
      if (
        formField.type === "radio-list" &&
        formField.autoSelectFirstOption &&
        (!formField.value || formField.value.length === 0) &&
        formField.options?.length > 0
      ) {
        formField.value = formField.options[0].value;
      }
    }
  }
}

export async function computeDataFields({ parent, params }) {
  let paramsWithExtraData = {
    ...params,
    ...(parent.extraDataForRepeatedObject || {}),
    ...getDataFieldsFromObject(parent),
  };

  if (parent.visible === false) {
    return;
  }

  if (parent.custom_dataFields && parent.custom_dataFields.length > 0) {
    let updateDataFieldPromises = [];
    for (let dataField of parent.custom_dataFields) {
      updateDataFieldPromises.push(computeIndividualDataField({ parent: dataField, paramsWithExtraData }));
    }
    await Promise.all(updateDataFieldPromises);
  }

  if (parent.objects) {
    for (let i = 0; i < parent.objects.length; i++) {
      let child = parent.objects[i];

      await computeDataFields({
        parent: child,
        params: paramsWithExtraData,
      });
    }
  }
}

export async function computeIndividualDataField({ parent, paramsWithExtraData }) {
  await addDataSourceOverrideIfNeeded({
    targetObject: parent,
    fieldName: "custom_dynamicInformationDataSourceOverride",
    params: paramsWithExtraData,
    dataSourceToOverride: "custom_dynamicInformationDataSource",
  });

  let parsedParameters;
  if (parent.custom_dynamicInformationParameters) {
    try {
      parsedParameters = JSON.parse(parent.custom_dynamicInformationParameters);
    } catch (e) {
      console.error("Error parsing dynamicInformationParameters = ", parent.custom_dynamicInformationParameters);
      console.error("Error parsing dynamicInformationParameters = ", e);
    }
  }

  let parameterValues = {};
  if (parsedParameters) {
    for (let parameterKey in parsedParameters) {
      let parameterDetails = parsedParameters[parameterKey];
      let paramsForGetParamFieldValue = {
        dataSource: parameterDetails.dataSource,
        id: parameterDetails.field,
        fieldId: parameterDetails.field,
        object: parent,
        ...paramsWithExtraData,
      };
      let parameterFieldValue = await getFieldValue(paramsForGetParamFieldValue);

      parameterValues[parameterKey] = parameterFieldValue;
    }
  }

  parent.data = await getFieldValue({
    dataSource: parent.custom_dynamicInformationDataSource,
    fieldId: parent.custom_dynamicInformation,
    dateFormat: parent.custom_dateFormat || "DD-MM-YYYY",
    customField: parent.custom_customFieldName,
    addDays: parent.custom_dateAddDays || 0,
    numberPrefix: parent.custom_numberPrefix || "0",
    numberPrefixDigitsToRemove: parent.custom_numberPrefixDigitsToRemove || "0",
    object: parent,
    ...paramsWithExtraData,
    ...parameterValues,
  });
}

export async function replaceDynamicFields({ parent, params }) {
  let paramsWithExtraData = {
    ...params,
    ...(parent.extraDataForRepeatedObject || {}),
    ...getDataFieldsFromObject(parent),
  };

  if (
    parent.type === "text" &&
    parent.width !== parent.custom_width &&
    parent.custom_width !== undefined &&
    parent.custom_width !== null
  ) {
    parent.width = parent.custom_width;
  }

  if (parent.custom_usesConditionalDisplay) {
    parent.isHidden = await isObjectHidden({
      object: parent,
      params: paramsWithExtraData,
    });
  }

  if (
    parent.custom_dynamicInformation ||
    (parent.custom_variables && parent.custom_variables.length > 0) ||
    parent.custom_componentParameters
  ) {
    if (parent.custom_type === "component") {
      let parsedParameters;
      if (parent.custom_componentParameters) {
        try {
          parsedParameters = JSON.parse(parent.custom_componentParameters);
        } catch (e) {
          console.error("Error parsing custom_componentParameters = ", parent.custom_componentParameters);
          console.error("Error parsing custom_componentParameters = ", e);
        }
      }

      let parameterValues = {};
      if (parsedParameters) {
        for (let parameterKey in parsedParameters) {
          let parameterDetails = parsedParameters[parameterKey];
          let paramsForGetParamFieldValue = {
            dataSource: parameterDetails.dataSource,
            id: parameterDetails.field,
            fieldId: parameterDetails.field,
            object: parent,
            ...paramsWithExtraData,
          };
          let parameterFieldValue = await getFieldValue(paramsForGetParamFieldValue);

          parameterValues[parameterKey] = parameterFieldValue;
          parent.parameterValues = parameterValues;
        }
      }
    } else if (parent.custom_type === "image_container") {
      const paramsForGetField = {
        dataSource: parent.custom_dynamicInformationDataSource,
        fieldId: parent.custom_dynamicInformation,
        object: parent,
        ...paramsWithExtraData,
      };
      parent.custom_imageKey = await getFieldValue(paramsForGetField);
    } else if (parent.custom_type === "dynamic_file") {
      const paramsForGetField = {
        dataSource: parent.custom_dynamicInformationDataSource,
        fieldId: parent.custom_dynamicInformation,
        object: parent,
        id: parent.custom_dynamicInformation,
        ...paramsWithExtraData,
      };
      let fieldDetails = getFieldDetailsById(paramsForGetField);
      if (fieldDetails) {
        parent.custom_fileKeys = await getFieldValue(paramsForGetField);

        if (!Array.isArray(parent.custom_fileKeys)) {
          parent.custom_fileKeys = [parent.custom_fileKeys];
        }
        if (fieldDetails.dataSourceField?.startsWith("files")) {
          let fileIds = [...parent.custom_fileKeys];
          parent.custom_fileKeys = [];
          for (let fileId of fileIds) {
            if (fileId) {
              const file = await getFileDetails({ fileId });
              let latestFileVersion = file?.versions.items.slice(-1)[0];
              let firstExport = latestFileVersion?.exports[0];
              let exportKey = firstExport?.key;
              parent.custom_fileKeys.push(exportKey);
            }
          }
        }
      }
    } else if (parent.custom_type === "signature") {
      const paramsForGetField = {
        dataSource: parent.custom_dynamicInformationDataSource,
        fieldId: parent.custom_dynamicInformation,
        object: parent,
        ...paramsWithExtraData,
      };
      const fieldValue = await getFieldValue(paramsForGetField);
      parent.src = fieldValue?.image;
      parent.custom_firstName = fieldValue?.firstName;
      parent.custom_lastName = fieldValue?.lastName;
    } else {
      await processDynamicInformationForStandardObject({ parent, paramsWithExtraData });
    }
  }

  if (parent.custom_conditionalFormattingRules) {
    await applyConditionalFormattingRules({
      object: parent,
      params: paramsWithExtraData,
    });
  }

  if (parent.objects) {
    for (let i = 0; i < parent.objects.length; i++) {
      let child = parent.objects[i];

      await replaceDynamicFields({
        parent: child,
        params: paramsWithExtraData,
      });
    }
  }
}

async function applyConditionalFormattingRules({ object, params }) {
  if (!object.custom_conditionalFormattingRules) {
    return;
  }

  let rules = object.custom_conditionalFormattingRules;

  for (let rule of rules) {
    await applyConditionalFormattingRule({ object, rule, params });
  }
}

async function applyConditionalFormattingRule({ object, rule, params }) {
  let isRuleSatisfied = !(await isObjectHidden({
    object: rule,
    params: {
      ...params,
      object,
    },
  }));

  if (!isRuleSatisfied) {
    return;
  }

  object[rule.custom_fieldName] = rule.custom_fieldValue;
}

async function getFileDetails({ fileId }) {
  if (global.isBrowser) {
    return (
      await window.callGraphQLSimple({
        message: "Failed to fetch file details",
        queryCustom: "getFile",
        variables: {
          id: fileId,
        },
      })
    ).data.getFile;
  } else {
    return (
      await global.nodeCallAppSync({
        query: global.queries.getFile,
        variables: {
          id: fileId,
        },
        logOutput: false,
      })
    ).data.getFile;
  }
}

export async function processDynamicInformationForStandardObject({ parent, paramsWithExtraData }) {
  if (parent.custom_variables && parent.custom_variables.length > 0) {
    let updateVariablePromises = [];
    for (let variable of parent.custom_variables) {
      if (variable.custom_dynamicInformationDataSource === "formula") {
        continue;
      }
      updateVariablePromises.push(
        processDynamicInformationForStandardObject({ parent: variable, paramsWithExtraData })
      );
    }
    await Promise.all(updateVariablePromises);

    for (let variable of parent.custom_variables) {
      if (variable.custom_dynamicInformationDataSource !== "formula") {
        continue;
      }

      await processDynamicInformationForStandardObject({
        parent: variable,
        paramsWithExtraData: {
          ...paramsWithExtraData,
          object: parent,
        },
      });
    }

    for (let variable of parent.custom_variables) {
      parent.text = parent.text.split(variable.symbol).join(variable.text);
    }
  } else if (parent.custom_dynamicInformation) {
    await addDataSourceOverrideIfNeeded({
      targetObject: parent,
      fieldName: "custom_dynamicInformationDataSourceOverride",
      params: paramsWithExtraData,
      dataSourceToOverride: parent.custom_dynamicInformationDataSource,
    });

    await setCustomTextForTextObject({ parent, paramsWithExtraData });
  }

  if (parent.custom_trimWhitespace && typeof parent.text === "string") {
    let oldText = parent.text;
    let newText = oldText.trim();

    parent.text = newText;
  }
}

async function setCustomTextForTextObject({ parent, paramsWithExtraData }) {
  let parsedParameters;
  if (parent.custom_dynamicInformationParameters) {
    try {
      parsedParameters = JSON.parse(parent.custom_dynamicInformationParameters);
    } catch (e) {
      console.error("Error parsing dynamicInformationParameters = ", parent.custom_dynamicInformationParameters);
      console.error("Error parsing dynamicInformationParameters = ", e);
    }
  }

  let parameterValues = {};
  if (parsedParameters) {
    for (let parameterKey in parsedParameters) {
      let parameterDetails = parsedParameters[parameterKey];
      let paramsForGetParamFieldValue = {
        dataSource: parameterDetails.dataSource,
        id: parameterDetails.field,
        fieldId: parameterDetails.field,
        object: parent,
        ...paramsWithExtraData,
      };
      let parameterFieldValue = await getFieldValue(paramsForGetParamFieldValue);

      parameterValues[parameterKey] = parameterFieldValue;
    }
  }

  if (parent.custom_dynamicInformationDataSource === "formula") {
    parent.text = await computeValueFormula({ parent, paramsWithExtraData });
  } else {
    // debugger;
    parent.text = await getFieldValue({
      dataSource: parent.custom_dynamicInformationDataSource,
      fieldId: parent.custom_dynamicInformation,
      dateFormat: parent.custom_dateFormat || "DD-MM-YYYY",
      customField: parent.custom_customFieldName,
      addDays: parent.custom_dateAddDays || 0,
      numberPrefix: parent.custom_numberPrefix || "0",
      numberPrefixDigitsToRemove: parent.custom_numberPrefixDigitsToRemove || "0",
      object: parent,
      ...paramsWithExtraData,
      ...parameterValues,
    });
  }

  if (parent.text === null || parent.text === undefined) {
    parent.text = "";
  }

  if (Array.isArray(parent.text)) {
    if (parent.custom_staticSeparator) {
      parent.text = parent.text.join(parent.custom_staticSeparator);
    } else {
      parent.text = parent.text.join("");
    }
  }

  if (parent.text && parent.custom_splitBySeparator) {
    let splitValue = parent.text.split(parent.custom_splitBySeparator);
    let indexToUseForSplit = parseInt(parent.custom_splitUseValueAtIndex || 0) || 0;
    if (isNaN(indexToUseForSplit)) {
      indexToUseForSplit = 0;
    }
    if (indexToUseForSplit < 0) {
      indexToUseForSplit = splitValue.length + indexToUseForSplit;
    }
    parent.text = splitValue[indexToUseForSplit];
  }

  if (parent.text && parent.custom_skipFirstXCharacters) {
    parent.text = parent.text.substring(parseInt(parent.custom_skipFirstXCharacters));
  }

  if (parent.text === null || parent.text === undefined) {
    parent.text = "";
  }
  // we have to do this before adding any prefix or suffix, otherwise that will be affected too
  if (parent.custom_textTransform) {
    switch (parent.custom_textTransform) {
      case "uppercase":
        parent.text = parent.text.toUpperCase();
        break;
      case "lowercase":
        parent.text = parent.text.toLowerCase();
        break;
      case "capitalize":
        parent.text = parent.text.charAt(0).toUpperCase() + parent.text.slice(1);
        break;
      default:
        break;
    }
  }

  if (parent.custom_staticPrefix) {
    parent.text = parent.custom_staticPrefix + parent.text;
  }

  if (parent.custom_staticSuffix) {
    parent.text = parent.text + parent.custom_staticSuffix;
  }

  if (parent.trimWhitespace && typeof parent.text === "string") {
    parent.text = parent.text.trim();
  }
}

async function computeValueFormula({ parent, paramsWithExtraData }) {
  const expression = parent.custom_dynamicInformation;
  if (!expression) {
    return "";
  }
  // formula is like "$1 * $2", where $1 and $2 are the symbols of the variables
  // the variables that are used in the formula are already calculated, we just have to calculate the formula for the current variable
  // we have to replace the symbols with the actual values
  const calculatedVariables = {};
  for (let variable of paramsWithExtraData.object.custom_variables) {
    calculatedVariables[variable.symbol] = variable.text;
  }

  const safeExpressionWithVariables = expression.replace(/[^a-zA-Z0-9 ()*+$]/g, "");
  let safeExpressionWithValues = safeExpressionWithVariables;

  for (let variableSymbol in calculatedVariables) {
    const calculatedVariable = calculatedVariables[variableSymbol];
    safeExpressionWithValues = safeExpressionWithValues.split(variableSymbol).join(calculatedVariable);
  }
  let expressionResult;
  const codeToEvaluate = `expressionResult = ${safeExpressionWithValues};`;

  eval(codeToEvaluate); // eslint-disable-line no-eval

  return expressionResult;
}

export async function isObjectHidden({ object, params }) {
  if (!object.custom_usesMultipleConditions) {
    await addDataSourceOverrideIfNeeded({
      targetObject: object,
      fieldName: "custom_conditionalDisplayDataSourceOverride",
      params,
      dataSourceToOverride: object.custom_conditionalDisplayDataSource,
    });

    let realValue = await getFieldValue({
      dataSource: object.custom_conditionalDisplayDataSource,
      fieldId: object.custom_conditionalDisplayDataSourceField,
      object,
      ...params,
    });

    if (typeof realValue === "string") {
      realValue = realValue.trim();
    }

    let expectedValue = object.custom_conditionalDisplayTarget;

    if (typeof expectedValue === "string") {
      expectedValue = expectedValue.trim();
    }

    let elementShouldBeDisplayed = isConditionMet({
      expectedValue,
      realValue,
      condition: object.custom_conditionalDisplayCondition,
    });

    return !elementShouldBeDisplayed;
  } else {
    const expression = object.custom_conditionalExpression;
    if (!expression) {
      // if no conditional expression, then by default the object should be hidden
      return true;
    }

    const calculatedConditions = {};
    for (let condition of object.custom_conditions || []) {
      let realValue = await getFieldValue({
        dataSource: condition.custom_conditionalDisplayDataSource,
        fieldId: condition.custom_conditionalDisplayDataSourceField,
        object,
        ...params,
      });

      let expectedValue = condition.custom_conditionalDisplayTarget;

      let conditionIsTrue = isConditionMet({
        expectedValue,
        realValue,
        condition: condition.custom_conditionalDisplayCondition,
      });
      calculatedConditions[condition.symbol] = conditionIsTrue.toString();
    }

    const safeExpressionWithVariables = expression.replace(/[^()&|$\d\s]+/g, "");
    let safeExpressionWithValues = safeExpressionWithVariables;

    let arrayOfConditions = Object.keys(calculatedConditions);

    arrayOfConditions.sort((a, b) => {
      return parseInt(b.replace(/\D/g, "")) - parseInt(a.replace(/\D/g, ""));
    });

    for (let conditionSymbol of arrayOfConditions) {
      const calculatedCondition = calculatedConditions[conditionSymbol];
      safeExpressionWithValues = safeExpressionWithValues.split(conditionSymbol).join(calculatedCondition);
    }

    let expressionResult;
    const codeToEvaluate = `expressionResult = ${safeExpressionWithValues};`;

    try {
      eval(codeToEvaluate); // eslint-disable-line no-eval
    } catch (e) {
      console.log(`Error evaluating conditional expression for ${object.custom_name}. Expression: ${codeToEvaluate}`);
      throw e;
    }

    return !expressionResult;
  }
}

export function isConditionMet({ expectedValue, realValue, condition }) {
  let expectedValueSeparator = ",";

  switch (condition) {
    case "EXISTS":
      if (Array.isArray(realValue)) {
        return realValue.length !== 0;
      }
      if (typeof realValue === "string") {
        let realValueWithoutSpaces = realValue.replace(/\s/g, "").trim();
        // when the multi-line text area is empty after writing in it, it's not actually fully empty, so we have to account for that
        return realValueWithoutSpaces !== '[{"type":"paragraph","children":[{"text":""}]}]' && realValue.length > 0;
      }

      return !!realValue;

    case "NOT EXISTS":
      if (Array.isArray(realValue)) {
        return realValue.length !== 0;
      }
      if (typeof realValue === "string") {
        let realValueWithoutSpaces = realValue.replace(/\s/g, "").trim();
        // when the multi-line text area is empty after writing in it, it's not actually fully empty, so we have to account for that
        return realValueWithoutSpaces === '[{"type":"paragraph","children":[{"text":""}]}]' || realValue.length === 0;
      }
      return !realValue;

    case "EQUALS":
      return String(realValue) === String(expectedValue);

    case "EQUALS LOWERCASE":
      return String(realValue).toLowerCase() === String(expectedValue).toLowerCase();

    case "NOT EQUALS":
      return String(realValue) !== String(expectedValue);

    case "NOT EQUALS LOWERCASE":
      return String(realValue).toLowerCase() !== String(expectedValue).toLowerCase();

    case "CONTAINS":
      return String(realValue).includes(String(expectedValue));

    case "CONTAINS LOWERCASE":
      return String(realValue).toLowerCase().includes(String(expectedValue).toLowerCase());

    case "NOT CONTAINS":
      return !String(realValue).includes(String(expectedValue));

    case "NOT CONTAINS LOWERCASE":
      return !String(realValue).toLowerCase().includes(String(expectedValue).toLowerCase());

    case "STARTS WITH":
      return String(realValue).startsWith(String(expectedValue));

    case "STARTS WITH LOWERCASE":
      return String(realValue).toLowerCase().startsWith(String(expectedValue).toLowerCase());

    case "NOT STARTS WITH":
      return !String(realValue).startsWith(String(expectedValue));

    case "NOT STARTS WITH LOWERCASE":
      return !String(realValue).toLowerCase().startsWith(String(expectedValue).toLowerCase());

    case "ENDS WITH":
      return String(realValue).endsWith(String(expectedValue));

    case "ENDS WITH LOWERCASE":
      return String(realValue).toLowerCase().endsWith(String(expectedValue).toLowerCase());

    case "NOT ENDS WITH":
      return !String(realValue).endsWith(String(expectedValue));

    case "NOT ENDS WITH LOWERCASE":
      return !String(realValue).toLowerCase().endsWith(String(expectedValue).toLowerCase());

    case "GREATER THAN":
      if (isNaN(expectedValue)) {
        return String(realValue) > String(expectedValue);
      } else {
        return parseFloat(realValue) > parseFloat(expectedValue);
      }

    case "LESS THAN":
      if (isNaN(expectedValue)) {
        return String(realValue) < String(expectedValue);
      } else {
        return parseFloat(realValue) < parseFloat(expectedValue);
      }

    case "GREATER OR EQUAL":
      if (isNaN(expectedValue)) {
        return String(realValue) >= String(expectedValue);
      } else {
        return parseFloat(realValue) >= parseFloat(expectedValue);
      }

    case "LESS OR EQUAL":
      if (isNaN(expectedValue)) {
        return String(realValue) <= String(expectedValue);
      } else {
        return parseFloat(realValue) <= parseFloat(expectedValue);
      }

    case "BETWEEN":
      if (String(realValue).includes(expectedValueSeparator)) {
        return false;
      }
      let expectedBetweenA = String(expectedValue).split(expectedValueSeparator)[0].trim();
      let expectedBetweenB = String(expectedValue).split(expectedValueSeparator)[1].trim();
      if (isNaN(expectedBetweenA)) {
        return String(realValue) >= String(expectedBetweenA) && String(realValue) <= String(expectedBetweenB);
      } else {
        return (
          parseFloat(realValue) >= parseFloat(expectedBetweenA) && parseFloat(realValue) <= parseFloat(expectedBetweenB)
        );
      }

    case "NOT BETWEEN":
      if (String(realValue).includes(expectedValueSeparator)) {
        return false;
      }
      let expectedNotBetweenA = String(expectedValue).split(expectedValueSeparator)[0].trim();
      let expectedNotBetweenB = String(expectedValue).split(expectedValueSeparator)[1].trim();
      if (isNaN(expectedNotBetweenA)) {
        return String(realValue) >= String(expectedNotBetweenA) && String(realValue) <= String(expectedNotBetweenB);
      } else {
        return (
          parseFloat(realValue) >= parseFloat(expectedNotBetweenA) &&
          parseFloat(realValue) <= parseFloat(expectedNotBetweenB)
        );
      }

    case "IN":
      let expectedValuesIn = String(expectedValue)
        .split(expectedValueSeparator)
        .map((expectedValueItem) => expectedValueItem.trim());
      return expectedValuesIn.includes(String(realValue));

    case "NOT IN":
      let expectedValuesNotIn = String(expectedValue)
        .split(expectedValueSeparator)
        .map((expectedValueItem) => expectedValueItem.trim());
      return !expectedValuesNotIn.includes(String(realValue));

    case "CHECKED":
      return realValue === true || realValue === "true";

    case "NOT CHECKED":
      return realValue === false || realValue === null || realValue === undefined;
    case "ODD":
      return parseInt(realValue) % 2 === 1;
    case "EVEN":
      return parseInt(realValue) % 2 === 0;

    default:
      return false;
  }
}

async function addDataSourceOverrideIfNeeded({ targetObject, fieldName, params, dataSourceToOverride }) {
  let overrideValue = targetObject[fieldName];

  dataSourceToOverride = dataSourceToOverride || "file";

  if (!overrideValue) {
    return;
  }

  let fieldId = targetObject[fieldName];

  const paramsForGetField = {
    dataSource: "form",
    id: fieldId,
    fieldId,
    ...params,
  };

  let overrideFieldDetails = getFieldDetailsById(paramsForGetField);

  if (overrideFieldDetails?.dataSourceField?.startsWith("files_") || overrideFieldDetails?.id?.startsWith("files_")) {
    let overrideFieldValue = await getFieldValue({
      dataSource: "form",
      fieldId,
      object: targetObject,
      ...params,
    });

    if (overrideFieldValue && overrideFieldValue.length > 0) {
      if (dataSourceToOverride === "form") {
        if (overrideFieldValue === params.file?.id) {
          return;
        }
        params[dataSourceToOverride] = params.extraForms[overrideFieldValue];
      } else {
        const overrideFile = await getOverrideFile({ id: overrideFieldValue });
        params[dataSourceToOverride] = overrideFile;
      }
    } else {
      params[dataSourceToOverride] = undefined;
    }
  }
}

async function getOverrideFile({ id }) {
  if (global.isBrowser) {
    if (
      window.documentTemplateOverrides &&
      window.documentTemplateOverrides.files &&
      window.documentTemplateOverrides.files[id]
    ) {
      return window.documentTemplateOverrides.files[id];
    }
    let file = (
      await window.callGraphQLSimple({
        message: "Failed to fetch file details",
        queryCustom: "getFile",
        variables: {
          id,
        },
      })
    ).data.getFile;
    if (!window.documentTemplateOverrides) {
      window.documentTemplateOverrides = {};
    }
    if (!window.documentTemplateOverrides.files) {
      window.documentTemplateOverrides.files = {};
    }
    window.documentTemplateOverrides.files[id] = file;
    return file;
  } else {
    if (
      global.documentTemplateOverrides &&
      global.documentTemplateOverrides.files &&
      global.documentTemplateOverrides.files[id]
    ) {
      return global.documentTemplateOverrides.files[id];
    }
    let file = (
      await global.nodeCallAppSync({
        query: global.queries.getFile,
        variables: {
          id,
        },
        logOutput: false,
      })
    ).data.getFile;

    if (!global.documentTemplateOverrides) {
      global.documentTemplateOverrides = {};
    }
    if (!global.documentTemplateOverrides.files) {
      global.documentTemplateOverrides.files = {};
    }
    global.documentTemplateOverrides.files[id] = file;
    return file;
  }
}

function getDataFieldsFromObject(parent) {
  let dataFields = {};
  if (!parent || !parent.custom_dataFields) {
    return dataFields;
  }

  if (parent.custom_dataFields && parent.custom_dataFields.length > 0) {
    for (let dataField of parent.custom_dataFields) {
      dataFields[dataField.symbol] = dataField.data;
    }
  }

  return dataFields;
}

export async function copyRepeatedObjects({ parent, params }) {
  let paramsWithExtraData = {
    ...params,
    ...(parent.extraDataForRepeatedObject || {}),
    ...getDataFieldsFromObject(parent),
  };

  if (!parent.objects) {
    return;
  }
  for (let i = 0; i < parent.objects.length; i++) {
    let child = parent.objects[i];

    if (!child.custom_isRepeatClone && child.custom_repeatFor && child.custom_repeatForDataSource) {
      // debugger;
      await addDataSourceOverrideIfNeeded({
        targetObject: child,
        fieldName: "custom_repeatForDataSourceOverride",
        params: paramsWithExtraData,
        dataSourceToOverride: child.custom_repeatForDataSource,
      });

      const paramsForGetField = {
        includeValue: true,
        dataSource: child.custom_repeatForDataSource,
        id: child.custom_repeatFor,
        fieldId: child.custom_repeatFor,
        object: child,
        ...paramsWithExtraData,
      };
      let parsedParameters;
      if (child.custom_repeatForParameters) {
        try {
          parsedParameters = JSON.parse(child.custom_repeatForParameters);
        } catch (e) {
          console.error("Error parsing repeatForParameters = ", child.custom_repeatForParameters);
          console.error("Error parsing repeatForParameters = ", e);
        }
      }

      let parameterValues = {};
      if (parsedParameters) {
        for (let parameterKey in parsedParameters) {
          let parameterDetails = parsedParameters[parameterKey];
          let paramsForGetParamFieldValue = {
            dataSource: parameterDetails.dataSource,
            id: parameterDetails.field,
            fieldId: parameterDetails.field,
            object: child,
            ...paramsWithExtraData,
          };
          let parameterFieldValue = await getFieldValue(paramsForGetParamFieldValue);

          parameterValues[parameterKey] = parameterFieldValue;
        }
      }

      let fieldDetails = getFieldDetailsById(paramsForGetField);
      let repeatedDataList;
      let childRepeatFor = child.custom_repeatFor.replace("repeat_", "");

      if (!fieldDetails) {
        if (!parent.extraDataForRepeatedObject) {
          continue;
        }

        let parentRepeatElement = parent.extraDataForRepeatedObject.repeatElement;
        let currentElement = parent.extraDataForRepeatedObject[parentRepeatElement.repeatForFieldName];
        let currentElementDetails = parentRepeatElement.fields[childRepeatFor];
        currentElementDetails = {
          ...currentElementDetails,
          ...currentElement[childRepeatFor],
          repeatForFieldName: parentRepeatElement.repeatForFieldName,
        };
        currentElementDetails.value = currentElementDetails.id;
        fieldDetails = JSON.parse(JSON.stringify(currentElementDetails));

        let targetChildElement = currentElement[childRepeatFor];
        if (Array.isArray(targetChildElement)) {
          repeatedDataList = targetChildElement;
        } else {
          repeatedDataList = targetChildElement?.value || [];
        }
      } else {
        // debugger;
        repeatedDataList = (await getFieldValue({ ...paramsForGetField, ...parameterValues })) || [];
        if (!repeatedDataList || repeatedDataList.length === 0) {
          repeatedDataList =
            (await getFieldValue({ ...paramsForGetField, fieldId: childRepeatFor, ...parameterValues })) || [];
        }
      }

      if (repeatedDataList && repeatedDataList.length > 0) {
        const extraData = {
          ...(parent.extraDataForRepeatedObject || {}),
          ...(child.extraDataForRepeatedObject || {}),
          repeatElement: fieldDetails,
          repeatListLength: repeatedDataList.length,
          repeatIndex: 0,
          [fieldDetails.repeatForFieldName]: repeatedDataList[0],
        };
        child.custom_isRepeatClone = true;
        child.custom_repeatCloneIndex = 0;
        child.extraDataForRepeatedObject = extraData;
        child.objects = child.objects?.map((childObject) => {
          return {
            ...JSON.parse(JSON.stringify(childObject)),
            extraDataForRepeatedObject: extraData,
          };
        });

        for (let j = 1; j < repeatedDataList.length; j++) {
          const extraData = {
            ...(parent.extraDataForRepeatedObject || {}),
            ...(child.extraDataForRepeatedObject || {}),
            repeatElement: fieldDetails,
            repeatListLength: repeatedDataList.length,
            repeatIndex: j,
            [fieldDetails.repeatForFieldName]: repeatedDataList[j],
          };
          let clonedObject = JSON.parse(
            JSON.stringify({
              ...JSON.parse(JSON.stringify(child)),
              custom_isRepeatClone: true,
              custom_repeatCloneIndex: j,
              custom_id: `${Date.now()}-${Math.floor(Math.random() * 100000)}-${Math.floor(Math.random() * 100000)}`,
              extraDataForRepeatedObject: extraData,
              objects: child.objects?.map((childObject) => {
                return {
                  ...JSON.parse(JSON.stringify(childObject)),
                  extraDataForRepeatedObject: extraData,
                };
              }),
            })
          );

          regenerateIdsForObjectAndChildren(clonedObject);
          parent.objects.splice(i + j, 0, clonedObject);
        }
      } else {
        parent.objects.splice(i, 1);

        // we need to do this because otherwise we end up skipping the first element after the one that's repeated, if the first one doesn't have any items in the list
        i--;
      }
    }

    await copyRepeatedObjects({ parent: child, params: paramsWithExtraData });
  }
}

export function extractListOfImageKeys(parent, keys = []) {
  if (parent.custom_imageKey && !keys.includes(parent.custom_imageKey)) {
    keys.push(parent.custom_imageKey);
  }

  if (parent.objects) {
    for (let i = 0; i < parent.objects.length; i++) {
      let child = parent.objects[i];
      extractListOfImageKeys(child, keys);
    }
  }

  return keys;
}

export function regenerateIdsForObjectAndChildren(parent) {
  parent.custom_id = `${Date.now()}-${Math.floor(Math.random() * 100000)}-${Math.floor(Math.random() * 100000)}`;

  let children;
  if (parent.objects) {
    children = parent.objects;
  }

  if (children) {
    for (let i = 0; i < children.length; i++) {
      regenerateIdsForObjectAndChildren(children[i]);
    }
  }
}
