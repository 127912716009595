import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Modal, Tooltip } from "antd";
import moment from "moment";
import { LeftOutlined, CalendarOutlined, HomeOutlined } from "@ant-design/icons";

import { TimesheetIcon } from "common/icons";
import withSubscriptions from "common/withSubscriptions";
import { isAuthorised } from "common/permissions";
import { isRunningAsPWAOnWindowsOrMac } from "common/helpers";

import NotificationMenu from "NotificationMenu/NotificationMenu";
import Navigation from "Navigation/Navigation";
import GlobalSearch from "./GlobalSearch/GlobalSearch";
import Card from "Card/Card";
import DraughtHubLogoSymbolOnly from "graphics/draughthub_logo_symbol_only.png";
import QuickCreateMenu from "QuickCreateMenu/QuickCreateMenu";
import UserMenu from "./UserMenu/UserMenu";

import "./NavigationBar.scss";

export function NavigationBar(props) {
  const {
    apiUser,
    organisationDetails,
    users,
    loggedInUsers,
    cognitoIdentityKey,
    userHasSimpleMobileView,
    windowWidth,
    isMobile,
  } = props;

  let [isDeploymentAlertVisible, setIsDeploymentAlertVisible] = useState(false);
  let [lastAlertDeploymentNumber, setLastAlertDeploymentNumber] = useState();

  // eslint-disable-next-line
  useEffect(() => {
    if (
      lastAlertDeploymentNumber !== undefined &&
      lastAlertDeploymentNumber !== null &&
      parseInt(lastAlertDeploymentNumber) !== parseInt(organisationDetails.deploymentAlertNumber) &&
      !isDeploymentAlertVisible
    ) {
      setIsDeploymentAlertVisible(true);
      showDeploymentAlertModal();
    }
    setLastAlertDeploymentNumber(organisationDetails.deploymentAlertNumber);
  });

  function showDeploymentAlertModal() {
    if (!window.isDeploymentAlertTimeout) {
      window.isDeploymentAlertTimeout = true;
      Modal.confirm({
        title: "New app version available",
        content: (
          <>
            Please refresh the page in order to get access to the latest version of the app. The application may not
            function correctly if you choose to ignore this message.
          </>
        ),
        icon: null,
        okText: "Reload",
        cancelText: "Remind me in one minute",
        onOk: () => {
          window.location.reload();
        },
        onCancel: () => {
          window.isDeploymentAlertTimeout = false;
          setTimeout(showDeploymentAlertModal, 10000);
        },
      });
    }
  }

  return (
    <>
      <Card className="navigation-bar">
        {isRunningAsPWAOnWindowsOrMac() ? (
          <LeftOutlined
            className="back-button"
            onClick={() => {
              window.history.back();
            }}
          />
        ) : null}

        {userHasSimpleMobileView && isMobile ? (
          <div>
            <Link to="/">
              <HomeOutlined />
            </Link>
          </div>
        ) : (
          <>
            <Link className="logo" to="/" data-cy="draughthub-logo">
              <img src={DraughtHubLogoSymbolOnly} alt="draughthub logo" />
            </Link>
            <Navigation {...props} isMobile={isMobile} />
          </>
        )}

        <GlobalSearch />

        {apiUser && <QuickCreateMenu {...props} />}
        {organisationDetails.settings?.timesheet?.usesTimesheets && isAuthorised(["USER_TIMESHEET.VIEW"]) && (
          <Tooltip title="Open your timesheet">
            <Link className="timesheet-button" to={`/user-timesheet/${apiUser.id}`}>
              <TimesheetIcon />
            </Link>
          </Tooltip>
        )}
        {organisationDetails.settings?.calendar?.usesCalendar && (
          <Tooltip title="Open your calendar">
            <Link
              className="calendar-button"
              to={`/user-calendar/${apiUser.id}/${moment().startOf("month").format("YYYY-MM-DD")}`}
            >
              <CalendarOutlined />
            </Link>
          </Tooltip>
        )}
        {apiUser && <NotificationMenu apiUser={apiUser} users={users} />}
        <UserMenu
          apiUser={apiUser}
          organisationDetails={organisationDetails}
          loggedInUsers={loggedInUsers}
          cognitoIdentityKey={cognitoIdentityKey}
        />
      </Card>
    </>
  );
}

export default withSubscriptions({
  Component: NavigationBar,
  subscriptions: ["organisationDetails"],
});
