import { useState } from "react";

import { withRouter } from "react-router-dom";
import withSubscriptions from "common/withSubscriptions";

import AttachmentTemplateList from "../AttachmentTemplateList/AttachmentTemplateList";

import "./ProjectSettingsPage.scss";

export function ProjectSettingsPage(props) {
  const { organisationDetails } = props;

  return (
    <div>
      <AttachmentTemplateList organisationDetails={organisationDetails} type="project" />
    </div>
  );
}

export default withRouter(
  withSubscriptions({
    Component: ProjectSettingsPage,
    subscriptions: ["organisationDetails"],
  })
);
