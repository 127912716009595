import { getRowAndColumnFromAddress } from "common/spreadsheetHelpers";
import moment from "moment";
const fields = [];

export const REPEATABLE_FIELDS = [
  "checkbox-list",
  "attachmentPicker",
  "nestedFieldListWithModal",
  "nestedFieldListNoModal",
  "dynamicSectionList",
];

function doNotShowField({ field, form }) {
  if (field.parentSection) {
    let parentSectionField = form.fields[field.parentSection];
    if (!parentSectionField) {
      return true;
    }
    if (parentSectionField.isPlaceholderForDynamicSectionList) {
      return true;
    }
  }

  return false;
}

export function getFields(params) {
  const { form } = params;
  if (!form) {
    return fields;
  }

  let formFields = [];
  for (let fieldId in form.fields) {
    let field = form.fields[fieldId];

    if (doNotShowField({ field, form })) {
      continue;
    }

    if (REPEATABLE_FIELDS.includes(field.type)) {
      formFields.push({
        ...field,
        label: `Number of items in ${field.label}`,
        id: `count_${fieldId}`,
        type: field.type,
        fieldTypes: ["text"],
        value: field.value?.length || 0,
      });

      if (field.dataSourceField?.startsWith("files_")) {
        formFields.push({
          label: `${field.label} - current option`,
          id: `files_${fieldId}_current_option`,
          fieldTypes: ["text"],
          type: field.type,
          value: ({ formElement }) => {
            return formElement; // this is the id of the file for the currently-selected option
          },
        });
      }
    }
  }
  for (let fieldId in form.fields) {
    let field = form.fields[fieldId];

    if (doNotShowField({ field, form })) {
      continue;
    }

    if (REPEATABLE_FIELDS.includes(field.type)) {
      let repeatedFieldDetails = {
        ...field,
        label: `Each item in ${field.label}`,
        id: `repeat_${fieldId}`,
        type: field.type,
        fieldTypes: ["repeatFor"],
        repeatForFieldName: "formElement",
      };

      if (field.type === "dynamicSectionList") {
        repeatedFieldDetails.value = async (params) => {
          let childSections = [];
          for (let childSectionFieldId in form.fields) {
            let sectionField = form.fields[childSectionFieldId];
            if (sectionField.parentDynamicSectionList === fieldId) {
              childSections.push({
                ...sectionField,
                value: async (params) => {
                  return getSectionContents({ ...params, sectionId: sectionField.id });
                },
              });
            }
          }
          childSections.sort((a, b) => a.adminOrder - b.adminOrder);
          return childSections;
        };
      }

      formFields.push(repeatedFieldDetails);
      formFields.push({
        ...field,
        label: `First selected option in ${field.label}`,
        id: `first_${fieldId}`,
        type: field.type,
        value: field.value?.[0] || "",
      });
    }
  }
  for (let fieldId in form.fields) {
    let field = form.fields[fieldId];

    if (doNotShowField({ field, form })) {
      continue;
    }

    if (field.type === "number") {
      let asManyTimesValue = [];
      if (!isNaN(field.value) && !Array.isArray(field.value)) {
        let arrayLength = parseInt(field.value) || 0;
        if (arrayLength < 0) {
          arrayLength = 0;
        }
        try {
          asManyTimesValue = Array(arrayLength).fill(field.value);
        } catch (e) {
          console.error("problem creating array, arrayLength:", arrayLength);
          console.error("error: ", e);
        }
      }

      formFields.push({
        ...field,
        label: `As many times as the value of ${field.label}`,
        id: `repeat_${fieldId}`,
        type: field.type,
        fieldTypes: ["repeatFor"],
        repeatForFieldName: "formElement",
        value: asManyTimesValue,
      });
    } else if (field.type === "section") {
      formFields.push({
        ...field,
        label: `${field.label} - section contents`,
        id: `section_contents_${fieldId}`,
        type: field.type,
        value: async (params) => {
          return getSectionContents({ ...params, sectionId: fieldId });
        },
      });
    }
  }
  for (let fieldId in form.fields) {
    let field = form.fields[fieldId];
    if (field.type === "modalWithFields" || doNotShowField({ field, form })) {
      continue;
    }

    let extraProperties = {};
    switch (field.type) {
      case "checkbox-list":
        extraProperties.supportsSeparator = true;
        break;
      case "spreadsheet":
        extraProperties.isSpreadsheet = true;
        extraProperties.value = (params) => {
          let result = "";
          try {
            const spreadsheetData = JSON.parse(field.value);
            let targetCell = spreadsheetData[0][0];
            if (params.object.custom_targetSpreadsheetCell) {
              const { row, column } = getRowAndColumnFromAddress(params.object.custom_targetSpreadsheetCell);
              targetCell = spreadsheetData[row][column];
            }
            result = targetCell.value || "";
          } catch (e) {
            // nothing we can do
          }
          return result;
        };
        break;
      case "date-picker":
        extraProperties.isDate = true;
        extraProperties.value = ({ dateFormat }) => {
          if (!field?.value) {
            return "";
          } else {
            return moment(field?.value).format(dateFormat);
          }
        };
        break;

      default:
        break;
    }

    let label = field.label;
    if (field.hiddenInModalBy) {
      let modalField = form.fields[field.hiddenInModalBy];
      if (modalField) {
        label = `(${modalField.label}) ${field.label}`;
      }
    }

    formFields.push({
      ...field,
      label,
      id: fieldId,
      type: "string",
      fieldType: field.type,
      ...extraProperties,
    });
  }

  return [...fields, ...formFields];
}

export function getSectionContents(params) {
  // const { sectionId } = params;
  const { formPreview, form, organisationDetails } = params;

  const variables = organisationDetails.variables.items;
  const paragraphVariable = variables.find((variable) => variable.name === "paragraph");
  const headingVariable = variables.find((variable) => variable.name === "heading");
  const subheadingVariable = variables.find((variable) => variable.name === "subheading");

  let sectionFields = [];
  let allFields = formPreview?.fields || form?.fields;
  if (!allFields) {
    return undefined;
  }

  for (let fieldId in allFields) {
    let field = allFields[fieldId];
    if (field.parentSection === params.sectionId) {
      sectionFields.push(field);
    }
  }

  sectionFields.sort((a, b) => a.adminOrder - b.adminOrder);

  let contentToDisplay = sectionFields
    .map((field, index) => {
      let label = field?.label || "";
      let labelWithColonOrQuestionMark = label;
      if (label && label.length > 0) {
        if (label.endsWith("?")) {
          labelWithColonOrQuestionMark = label;
        } else if (label.endsWith(":")) {
          labelWithColonOrQuestionMark = label;
        } else {
          labelWithColonOrQuestionMark = `${label}:`;
        }
      }
      let value = field?.value;
      switch (field.type) {
        case "section-heading":
          return [
            {
              type: "paragraph",
              children: [
                {
                  text: "",
                  [`font_size_${subheadingVariable?.id}`]: !!subheadingVariable && index !== 0,
                },
              ],
            },
            {
              type: "paragraph",
              children: [
                {
                  text: labelWithColonOrQuestionMark,
                  [`font_size_${subheadingVariable?.id}`]: !!subheadingVariable,
                  bold: true,
                },
              ],
            },
          ];
        case "checkbox-list":
          if (!value || value.length === 0) {
            return undefined;
          }
          let resultToReturn = [
            {
              type: "paragraph",
              children: [
                {
                  text: labelWithColonOrQuestionMark,
                  [`font_size_${paragraphVariable?.id}`]: !!headingVariable,
                  bold: true,
                },
              ],
            },
          ];
          value.forEach((checkboxItem) => {
            resultToReturn.push({
              type: "paragraph",
              children: [
                {
                  text: ` - ${checkboxItem}`,
                  [`font_size_${paragraphVariable?.id}`]: !!headingVariable,
                },
              ],
            });
          });
          return resultToReturn;
        case "radio-list":
        case "dropdown":
        case "textfield":
        case "number":
          if (field.type === "number" && value === 0) {
            // we display the field even if the value is 0
          } else if (!value || (Array.isArray(value) && value.length === 0)) {
            return undefined;
          }
          return [
            {
              type: "paragraph",
              children: [
                {
                  text: labelWithColonOrQuestionMark,
                  [`font_size_${paragraphVariable?.id}`]: !!headingVariable,
                  bold: true,
                },
                {
                  text: ` ${value}`,
                  [`font_size_${paragraphVariable?.id}`]: !!headingVariable,
                },
              ],
            },
          ];
        case "textarea":
          if (!value) {
            return undefined;
          }
          try {
            let parsedValue = JSON.parse(value);
            let stringifiedValue = JSON.stringify(parsedValue);
            if (stringifiedValue === '[{"type":"paragraph","children":[{"text":""}]}]') {
              return undefined;
            }
            parsedValue.unshift({
              type: "paragraph",
              children: [
                {
                  text: labelWithColonOrQuestionMark,
                  [`font_size_${paragraphVariable?.id}`]: !!headingVariable,
                  bold: true,
                },
              ],
            });
            return parsedValue;
          } catch (e) {
            // nothing we can do
          }
        case "separator":
          return [
            {
              type: "paragraph",
              children: [
                {
                  text: "",
                  [`font_size_${paragraphVariable?.id}`]: !!headingVariable,
                },
              ],
            },
          ];
        default:
          return undefined;
      }
    })
    .filter((x) => x);

  let contentToDisplayWithFlattenedArrays = contentToDisplay.flat(1);

  return JSON.stringify(contentToDisplayWithFlattenedArrays);
}

export const label = "Form";
