import { useState } from "react";
import { message, Button } from "antd";
import moment from "moment";
import axios from "axios";

import { callGraphQLSimple } from "common/apiHelpers";
import { fetchActivityItemsForRequest } from "common/shared";
import { getPredefinedTaskFieldsFromRequestForm } from "common/naming";
import { getPredefinedProjectFieldsFromRequestForm } from "common/naming";
import { getDetailsForFormAndTaskRevision } from "common/sharedRequestHelpers";
import getS3File from "common/getS3File";
import { getSimpleLabel } from "common/labels";
import { processIdForDisplay } from "common/helpers";
import { sendRequestFormAcceptedNotification } from "common/notificationHelpers";

import AddToTaskModal from "Modals/AddToTaskModal/AddToTaskModal";

type Props = {
  request: any;
  formFileId: string;
  apiUser: any;
  formName: string;
  taskIdsAndRevisionIds: any[] | undefined;
  formCreationActivityItem: any;
  users: any[];
  organisationDetails: any;
  isLinkingReview?: boolean;
  predefinedTaskId?: string;
};

export default function AddRequestFormToTaskRevisionButton({
  users,
  apiUser,
  request,
  formFileId,
  formName,
  taskIdsAndRevisionIds,
  formCreationActivityItem,
  organisationDetails,
  isLinkingReview,
  predefinedTaskId,
}: Props) {
  const [isAddToTaskModalVisible, setIsAddToTaskModalVisible] = useState(false);
  const [predefinedTaskFieldsFromRequestForm, setPredefinedTaskFieldsFromRequestForm] = useState<any>({});
  const [predefinedProjectFieldsFromRequestForm, setPredefinedProjectFieldsFromRequestForm] = useState<any>({});

  async function loadRequestFormFile() {
    if (!formFileId) {
      return;
    }
    const requestFileDetails = (
      await callGraphQLSimple({
        queryCustom: "getFile",
        message: `Failed to fetch ${getSimpleLabel("request")} file details`,
        variables: {
          id: formFileId,
        },
      })
    ).data.getFile;
    const formFileKey = requestFileDetails.versions.items.slice(-1)[0].key.replace("public/", "");
    const formFilePublicUrl = await getS3File(formFileKey);
    let form = (await axios.get(formFilePublicUrl)).data;

    const predefinedTaskFields = await getPredefinedTaskFieldsFromRequestForm({
      requestForm: form,
      organisation: organisationDetails,
      organisationDetails,
      requestFileDetails,
      request,
    });

    const predefinedProjectFields = await getPredefinedProjectFieldsFromRequestForm({
      requestForm: form,
      organisation: organisationDetails,
      organisationDetails,
      requestFileDetails,
      request,
    });

    setPredefinedTaskFieldsFromRequestForm(predefinedTaskFields);
    setPredefinedProjectFieldsFromRequestForm(predefinedProjectFields);
  }

  async function onAddToTaskModalSubmit({
    taskId,
    taskRevisionId,
    taskRevisionName,
    taskTitle,
    projectTitle,
    isLinkingReview,
  }) {
    const messageKey = "assign-request-to-task";

    message.loading({
      content: `Assigning ${getSimpleLabel("request")} to ${getSimpleLabel("task")}...`,
      key: messageKey,
      duration: 0,
    });
    try {
      const taskDetails = (
        await callGraphQLSimple({
          queryCustom: "getTaskSimple",
          message: `Failed to fetch ${getSimpleLabel("task")} details`,
          variables: {
            id: taskId,
          },
        })
      ).data.getTask;

      if (!taskDetails) {
        message.error({
          content: `Failed to fetch ${getSimpleLabel("task")} details`,
          key: messageKey,
        });
        return;
      }

      const activityDetailsForRequests = await Promise.all(
        (taskDetails.requestIds || []).map(fetchActivityItemsForRequest)
      );
      let activityItemsByRequest = {};
      for (let activityDetailsForRequest of activityDetailsForRequests) {
        activityItemsByRequest[activityDetailsForRequest.requestId] = activityDetailsForRequest.activityItems;
      }

      let requestFormActivityItem = getDetailsForFormAndTaskRevision({
        activityItemsByRequest,
        taskRevisionId: taskRevisionId,
      });

      if (requestFormActivityItem) {
        let requestIdMention: any = null;
        if (requestFormActivityItem.parentId === request.id) {
          requestIdMention = <>this {getSimpleLabel("request")}</>;
        } else {
          requestIdMention = (
            <>
              {getSimpleLabel("request")} <b>{processIdForDisplay(requestFormActivityItem.parentId)}</b>
            </>
          );
        }
        message.error({
          content: (
            <span
              style={{ cursor: "pointer" }}
              onClick={() => {
                window.open(
                  `/requests/${requestFormActivityItem.parentId}?formFileId=${requestFormActivityItem.content.formFileId}`,
                  "_blank"
                );
              }}
            >
              This {getSimpleLabel("task revision")} already has a {getSimpleLabel("request")} form assigned to it from{" "}
              {requestIdMention}: "{requestFormActivityItem.content.formName}".{" "}
              <b>Click here to open it in a new tab</b>
            </span>
          ),
          key: messageKey,
          duration: 3,
        });
        return;
      }

      if (
        (taskDetails.requestIds && taskDetails.requestIds.length > 1) ||
        (taskDetails.requestIds?.length === 1 && !taskDetails.requestIds?.includes(request.id)) ||
        Object.keys(activityItemsByRequest).length > 1 ||
        (Object.keys(activityItemsByRequest).length === 1 && !Object.keys(activityItemsByRequest).includes(request.id))
      ) {
        message.error({
          content: (
            <span
              style={{ cursor: "pointer" }}
              onClick={() => {
                window.open(`/requests/${taskDetails.requestIds[0]}`, "_blank");
              }}
            >
              This {getSimpleLabel("task")} is already associated with {getSimpleLabel("request")}{" "}
              <b>{processIdForDisplay(Object.keys(activityItemsByRequest)[0])}</b>.{" "}
              <b>Click here to open it in a new tab</b>
            </span>
          ),
          key: messageKey,
          duration: 3,
        });
        return;
      }

      await callGraphQLSimple({
        mutation: "createActivityItem",
        message: "Failed to record activity item",
        variables: {
          input: {
            parentId: request.id,
            author: apiUser.id,
            content: JSON.stringify({
              type: "REQUEST_FORM_ADDED_TO_TASK_REVISION",
              taskRevisionId,
              taskId,
              taskTitle,
              projectTitle,
              taskRevisionName,
              formName,
              formFileId,
              isLinkingReview,
            }),
            organisation: request.organisation,
          },
        },
      });

      await window.callGraphQLSimple({
        mutation: "createTaskActivityItem",
        message: `Failed to record ${getSimpleLabel("task")} activity item`,
        variables: {
          input: {
            taskId,
            organisation: request.organisation,
            type: "LIFECYCLE_EVENT",
            author: apiUser.id,
            content: JSON.stringify({
              type: "REQUEST_FORM_ADDED_TO_TASK_REVISION",
              requestFormName: formName,
              requestId: request.id,
              requestTitle: request.title,
              taskRevisionName,
              taskRevisionId,
              isLinkingReview,
              formFileId,
            }),
          },
        },
      });

      let resultingTaskIds = [...(request.resultingTaskIds || [])];
      if (!resultingTaskIds.includes(taskId)) {
        resultingTaskIds.push(taskId);
      }
      let projectId = request.projectId;
      let clientId = request.clientId;
      if (!projectId) {
        projectId = taskDetails.projectId;
      }
      if (!clientId) {
        clientId = taskDetails.clientId;
      }

      await callGraphQLSimple({
        mutation: "updateRequest",
        message: `Failed to update ${getSimpleLabel("request")} status`,
        variables: {
          input: {
            id: request.id,
            status: "WORK_IN_PROGRESS",
            resultingTaskIds,
            projectId,
            clientId,
          },
        },
      });

      if (!taskDetails.requestIds?.includes(request.id)) {
        await callGraphQLSimple({
          queryCustom: "updateTask",
          message: `Failed to add ${getSimpleLabel("request")} to ${getSimpleLabel("task")}`,
          variables: {
            input: {
              id: taskId,
              requestIds: [...(taskDetails.requestIds || []), request.id],
            },
          },
        });
      } else {
        await callGraphQLSimple({
          queryCustom: "updateTask",
          message: `Failed to refresh ${getSimpleLabel("task")} details`,
          variables: {
            input: {
              id: taskId,
              itemSubscription: Math.floor(Math.random() * 1000000),
            },
          },
        });
      }

      window.dispatchAutomationEvent("ADD_REQUEST_FORM_TO_TASK_REVISION", {
        taskId,
        taskRevisionId,
        requestId: request.id,
        formFileId,
        formName,
      });

      if (!taskIdsAndRevisionIds || taskIdsAndRevisionIds?.length === 0) {
        sendRequestFormAcceptedNotification({
          users,
          request,
          apiUser,
          formName,
          formFileId,
          receiverId: formCreationActivityItem.author,
        });
      }

      message.success({
        content: `${getSimpleLabel("Request")} added to ${getSimpleLabel("task")}`,
        key: messageKey,
      });
      setIsAddToTaskModalVisible(false);
    } catch (e) {
      console.error(e);
      message.error({
        content: `Failed to assign ${getSimpleLabel("request")} to ${getSimpleLabel("task")}`,
        key: messageKey,
      });
    }
  }

  let label;
  if (isLinkingReview) {
    label = `Link to new ${getSimpleLabel("task revision")}`;
  } else {
    label = `Add to ${getSimpleLabel("task")}`;
  }

  return (
    <>
      <Button
        type="primary"
        onClick={() => {
          loadRequestFormFile();
          setIsAddToTaskModalVisible(true);
        }}
      >
        {label}
      </Button>
      {isAddToTaskModalVisible && (
        <AddToTaskModal
          visible={true}
          onClose={() => setIsAddToTaskModalVisible(false)}
          apiUser={apiUser}
          onSubmit={onAddToTaskModalSubmit}
          predefinedCreateTaskFields={{
            title: request.title,
            priorityId: formCreationActivityItem.content?.priorityId,
            dueDate: formCreationActivityItem.content?.requestedForDate
              ? moment(formCreationActivityItem.content?.requestedForDate)
              : undefined,
            requestedDate: moment(formCreationActivityItem.createdAt),
            assignedTo: request.assignedTo,
            taskRevisionDescription: formCreationActivityItem.content?.formName,
            ...predefinedTaskFieldsFromRequestForm,
          }}
          predefinedCreateTaskRevisionFields={{
            priorityId: formCreationActivityItem.content?.priorityId,
            dueDate: formCreationActivityItem.content?.requestedForDate
              ? moment(formCreationActivityItem.content?.requestedForDate)
              : undefined,
            requestedDate: moment(formCreationActivityItem.createdAt),
            description: formCreationActivityItem.content?.formName,
          }}
          predefinedCreateProjectFields={{
            ...predefinedProjectFieldsFromRequestForm,
          }}
          projectId={request.projectId}
          entityName={isLinkingReview ? "review" : "form"}
          includeTaskRevision
          includeAddNewTaskRevision={false}
          alreadyAddedToTaskIdsAndRevisionIds={taskIdsAndRevisionIds}
          predefinedTaskId={predefinedTaskId}
          isLinkingReview={isLinkingReview}
        />
      )}
    </>
  );
}
