import moment from "moment";

import { KEY_TYPES } from "../shared";
// import log from "../log";
import { getFullDetailsForNaming } from "./namingHelpers";

export async function getFileName({ data, type }) {
  if (data.fileType.toUpperCase() === "QUOTE") {
    return data.quoteId;
  }

  if (data.fileType.toUpperCase() === "INVOICE") {
    return data.invoiceId;
  }

  if (data.fileType.toUpperCase() === "PURCHASE_ORDER") {
    return data.purchaseOrderId;
  }

  let { fileTypeCount, sameTemplateCount, taskIdWithoutOrg, task, file, organisationDetails } =
    await getFullDetailsForNaming(data, type);

  let sheet;
  let fileNumber;

  if (type === KEY_TYPES.FILE_SHEET_EXPORT || type === KEY_TYPES.FILE_SHEET_EXPORT_RAW) {
    sheet = file.sheets.items.find((x) => x.name === data.sheetName);
  }

  let result = `${organisationDetails.id}-${taskIdWithoutOrg}`;

  switch (data.fileType.toUpperCase()) {
    case "AUTOCAD":
    case "REVIT":
    case "BRICSCAD":
      result += "-DRG";
      if (sheet) {
        result += `-${sheet.description}`;
      } else {
        if (!type.toUpperCase().includes("SHEET")) {
          fileNumber = String(1000 + fileTypeCount).substring(1);
          result += `-${fileNumber}`;
        }
      }
      break;
    case "EXCEL":
      result += "-CAL";
      fileNumber = String(10 + fileTypeCount).substring(1);
      result += `-${fileNumber}`;
      if (type?.includes("SHEET") && data.sheetName) {
        result += ` ${data.sheetName}`;
      }
      break;

    default:
      let templateId = data.templateId || data.file?.templateId;
      const templateDetails = organisationDetails.templates.items.find((x) => x.id === templateId);
      if (templateDetails) {
        if (templateDetails?.name?.toUpperCase().includes("EDC")) {
          result += "-EDC";
        } else if (templateDetails?.name?.toUpperCase().includes("SIC")) {
          result += "-SIC";
        } else if (templateDetails?.name?.toUpperCase().includes("DESIGN CERTIFICATE")) {
          result += "-DC";
        } else if (templateDetails?.name?.toUpperCase().includes("CHECK CERTIFICATE")) {
          result += "-CC";
        } else if (
          templateDetails?.name?.toUpperCase().includes("CAT2") ||
          templateDetails?.name?.toUpperCase().includes("CAT 2")
        ) {
          result += "-CAT2";
        } else if (
          templateDetails?.name?.toUpperCase().includes("CAT1") ||
          templateDetails?.name?.toUpperCase().includes("CAT 1")
        ) {
          result += "-CAT1";
        } else if (
          templateDetails?.name?.toUpperCase().includes("DRA") ||
          templateDetails?.name?.toUpperCase().includes("RISK ASSESSMENT")
        ) {
          result += "-DRA";
        } else if (
          templateDetails?.name?.toUpperCase().includes("CAT3") ||
          templateDetails?.name?.toUpperCase().includes("CAT 3")
        ) {
          result += "-CAT3";
        }
      }

      if (templateDetails?.name?.toUpperCase().includes("DOCUMENT REGISTER")) {
        result = result.replace("-ADMIN", "-REG");
      } else {
        fileNumber = String(sameTemplateCount + 1);
        result += `-${fileNumber}`;
      }
      break;
  }

  return result;
}

async function changeFileNameAtDownloadTime(data) {
  // console.log('changeFileNameAtDownloadTime() data =', data);
  let { fileName, sheetRevisionName, file, type, task, invoice, projects, clients, sheetRevision } = data;

  let sheet;
  if (sheetRevision?.sheetId) {
    sheet = file.sheets.items.find((x) => x.id === sheetRevision.sheetId);
  }

  if (type === KEY_TYPES.INVOICE) {
    let date = moment(invoice.createdAt);

    const projectDetails = projects.find((x) => x.id === invoice.projectId);
    const clientDetails = clients.find((x) => x.id === invoice.clientId);

    return `${clientDetails?.name} ${projectDetails?.title} ${date.format("MMM YYYY")} ${invoice.id}.pdf`;
  }

  if (file?.type === "AUTOCAD") {
    if (type?.includes("SHEET")) {
      let fileVersion = file.versions.items.find((x) => x.id === sheetRevision.fileVersionId);
      if (sheet?.customReferenceNumber && sheet.customReferenceNumber !== "undefined") {
        return sheet.customReferenceNumber;
      } else if (fileVersion?.customId && fileVersion.customId !== "undefined") {
        return fileVersion.customId;
      }
      let fileNameParts = fileName.split(" ");
      fileNameParts.splice(1, 1, `${sheetRevisionName}`);
      fileName = fileNameParts.join(" ");
    }
  } else {
    let latestFileVersion = file?.versions?.items?.slice(-1)[0];
    if (latestFileVersion?.customId && latestFileVersion?.customId !== "undefined") {
      fileName = latestFileVersion.customId;
    } else {
      if (!sheetRevisionName && file && file.sheets && file.sheets.items && file.sheets.items[0]) {
        let firstSheet = file.sheets.items[0];
        sheetRevisionName = firstSheet.revisions.items.slice(-1)[0].name;
      }
      let fileNameParts = fileName.split(" ");
      if (!task?.isHidden) {
        fileNameParts.splice(1, 1, `${sheetRevisionName}`);
      }
      fileName = fileNameParts.join(" ");
    }
  }

  return fileName;
}

function changeSheetReference({ sheet, file, referenceNumber }) {
  if (["AUTOCAD", "REVIT", "BRICSCAD"].includes(file?.type.toUpperCase())) {
    return `${referenceNumber}-${sheet.description}`;
  } else {
    return referenceNumber;
  }
}

function getProjectId({ organisation, extraOffset }) {
  const projectNumber =
    parseInt(organisation.projectIdOffset || 0) + parseInt(organisation.projectCount || 0) + 1 + parseInt(extraOffset);
  return `${organisation.id}-${projectNumber}`;
}

async function getTaskId({ organisation, projectDetails, extraOffset }) {
  const taskNumber = parseInt(projectDetails.taskCount || 0) + 1 + parseInt(extraOffset);
  let taskNumberAsString = `${taskNumber}`;

  return `${projectDetails.id}-${taskNumberAsString}`;
}

function getQuoteId({ organisation, projectDetails, extraOffset }) {
  const quoteNumber = parseInt(projectDetails.quoteCount || 0) + 1 + parseInt(extraOffset);
  let quoteNumberAsString = quoteNumber >= 10 ? `${quoteNumber}` : `0${quoteNumber}`;
  return `${projectDetails.id}-Q${quoteNumberAsString}`;
}

function getInvoiceId({ organisation, projectDetails, extraOffset }) {
  const invoiceNumber = parseInt(projectDetails.invoiceCount || 0) + 1 + parseInt(extraOffset);
  let invoiceNumberAsString = invoiceNumber >= 10 ? `${invoiceNumber}` : `0${invoiceNumber}`;
  return `${projectDetails.id}-INV${invoiceNumberAsString}`;
}

function getPurchaseOrderId({ organisation, projectDetails, extraOffset }) {
  const poNumber = parseInt(projectDetails.purchaseOrderCount || 0) + 1 + parseInt(extraOffset);
  return `${projectDetails.id}-PO${poNumber}`;
}

function getSheetDescription({ task, file, taskRevision, sheetCount }) {
  const fileType = file.type;
  let sheetCountForFileType = sheetCount;
  const latestTaskRevision = taskRevision || task.revisions.items[task.revisions.items.length - 1];

  latestTaskRevision.files?.items.forEach((crtFile) => {
    if (crtFile.type !== fileType || crtFile.isArchived) {
      return;
    }

    sheetCountForFileType += crtFile.sheets?.items.filter((x) => x.includeInPublish).length;
  });

  if (file.type === "EXCEL") {
    return String(1001 + sheetCountForFileType).substring(1);
  }
  return String(1 + sheetCountForFileType);
}

function getRequestId({ organisationDetails, extraOffset }) {
  const requestNumber = parseInt(organisationDetails.requestCount || 0) + 1 + parseInt(extraOffset);
  return `${organisationDetails.id}-REQ${requestNumber}`;
}

function getTaskRevisionName({ task }) {
  const possibleNames = [
    "A",
    "B",
    "C",
    "D",
    "E",
    "F",
    "G",
    "H",
    "J",
    "K",
    "L",
    "M",
    "N",
    "P",
    "Q",
    "R",
    "S",
    "T",
    "U",
    "V",
    "W",
    "X",
    "Y",
    "Z",
  ];
  if (!task.revisions || !task.revisions.items || task.revisions.items.length === 0) {
    return possibleNames[0];
  }
  const nonArchivedRevisions = task.revisions.items.filter((x) => !x.isArchived);
  const latestTaskRevision = nonArchivedRevisions[nonArchivedRevisions.length - 1];

  let nameIndexOfLatestTaskRevision = possibleNames.findIndex((x) => x === latestTaskRevision.name);
  if (nameIndexOfLatestTaskRevision === -1) {
    return null;
  }

  let nextName = null;
  while (!nextName && nameIndexOfLatestTaskRevision < possibleNames.length - 1) {
    nextName = possibleNames[nameIndexOfLatestTaskRevision + 1];
    // eslint-disable-next-line
    if (nonArchivedRevisions.find((x) => x.name === nextName)) {
      nextName = null;
      nameIndexOfLatestTaskRevision++;
    }
  }

  return nextName;
}

function getSheetRevisionName({ sheet, newStatus }) {
  let newStatusIsConstruction = newStatus?.toUpperCase().split(" ").join("_").includes("CONSTRUCTION");
  if (!sheet || !sheet.revisions || !sheet.revisions.items || sheet.revisions.items.length === 0) {
    return newStatusIsConstruction ? "C1" : "P1";
  }

  const nonArchivedRevisions = sheet.revisions.items.filter((x) => !x.isArchived);
  const latestSheetRevision = nonArchivedRevisions[nonArchivedRevisions.length - 1];

  let previousStatusIsConstruction = latestSheetRevision.status
    .toUpperCase()
    .split(" ")
    .join("_")
    .includes("CONSTRUCTION");

  let newName = "";
  if (newStatusIsConstruction) {
    if (previousStatusIsConstruction) {
      let numberPart = (parseInt(latestSheetRevision.name.substring(1)) || 0) + 1;
      newName = `C${numberPart}`;
    } else {
      newName = "C1";
    }
  } else {
    let numberPart = (parseInt(latestSheetRevision.name.substring(1)) || 0) + 1;
    newName = `P${numberPart}`;
  }

  return newName;
}

function getQuoteRevisionName({ quote }) {
  const possibleNames = [
    "A",
    "B",
    "C",
    "D",
    "E",
    "F",
    "G",
    "H",
    "J",
    "K",
    "L",
    "M",
    "N",
    "P",
    "Q",
    "R",
    "S",
    "T",
    "U",
    "V",
    "W",
    "X",
    "Y",
    "Z",
  ];
  if (!quote || !quote.currentRevisionName) {
    return possibleNames[0];
  }

  const nameIndexOfLatestSheetRevision = possibleNames.findIndex((x) => x === quote.currentRevisionName);
  if (nameIndexOfLatestSheetRevision === -1) {
    return null;
  }
  return possibleNames[nameIndexOfLatestSheetRevision + 1];
}

async function getFrontendFileName({ organisationDetails, task, taskRevision, templateId, fileType }) {
  if (!templateId) {
    return;
  }
  const templateDetails = organisationDetails.templates.items.find((x) => x.id === templateId);
  const filesWithSameTemplate = taskRevision.files.items.filter((file) => file.templateId === templateDetails?.id);
  let filesWithSameTemplateCount = filesWithSameTemplate.length;
  const deletedFilesByType = JSON.parse(taskRevision.deletedFilesByType || "{}");
  if (deletedFilesByType[fileType]) {
    filesWithSameTemplateCount += deletedFilesByType[fileType];
  }

  const result = `${templateDetails?.name} - ${String(100 + filesWithSameTemplateCount + 1).substring(1)}`;
  return result;
}

// async function getTimelineBlockColor({ task, timelineBlock }) {
//   if (!window.location.origin?.includes("localhost")) {
//     return;
//   }

//   let hasTaskRevision =
//     timelineBlock.taskRevisionId &&
//     task?.revisions &&
//     window.organisationDetails.settings?.timeline?.planTaskRevisionsInsteadOfTasks;

//   let taskRevisionName;

//   if (timelineBlock.taskRevisionId) {
//     if (hasTaskRevision) {
//       let revision = task.revisions.items.find((x) => x.id === timelineBlock.taskRevisionId);
//       if (revision) {
//         taskRevisionName = revision.name;
//       }
//     }
//   }

//   if (!task || !taskRevisionName) {
//     return;
//   }

//   let taskRevisionNameUpperCase = taskRevisionName.toUpperCase().split(" ").join("_");

//   let color = "#444444";

//   switch (taskRevisionNameUpperCase) {
//     case "SURVEY":
//       color = "#FF69B4";
//       break;
//     case "TESTING":
//       color = "#31a4de";
//       break;
//     case "DESIGN":
//       color = "#1fb312";
//       break;
//     case "NMD_INSTALLATION":
//       color = "#decd31";
//       break;
//     case "RADIX_INSTALLATION":
//       color = "#FFA500";
//       break;
//     case "OTHER":
//       color = "#FF0000";
//       break;
//   }

//   return color;
// }

async function getPredefinedTaskFieldsFromRequestForm({
  organisationDetails,
  requestFileDetails,
  requestForm,
  request,
}) {
  let predefinedTaskFields = {
    title: request.title,
  };

  if (requestForm.fields["byrnebrostwdesigncontact-1724332403072"]?.value) {
    predefinedTaskFields.assignedTo = requestForm.fields["byrnebrostwdesigncontact-1724332403072"]?.value;
  }

  if (requestForm.fields["schemedescription-1723123840348"]?.value) {
    predefinedTaskFields.subtitle = requestForm.fields["schemedescription-1723123840348"]?.value;
  }

  if (requestForm.fields["twschemeno3digit-1723123790668"]?.value) {
    predefinedTaskFields.taskNumber = requestForm.fields["twschemeno3digit-1723123790668"]?.value;
  }

  let catLevelFieldValue = requestForm.fields["twdesigncategory-1720778785795"]?.value
    ?.toUpperCase()
    .split(" ")
    .join("_");

  if (catLevelFieldValue?.includes("CATEGORY_3")) {
    predefinedTaskFields.catLevel = 3;
  } else if (catLevelFieldValue?.includes("CATEGORY_2")) {
    predefinedTaskFields.catLevel = 2;
  } else if (catLevelFieldValue?.includes("CATEGORY_1")) {
    predefinedTaskFields.catLevel = 1;
  } else if (catLevelFieldValue?.includes("CATEGORY_0")) {
    predefinedTaskFields.catLevel = 0;
  }

  return predefinedTaskFields;
}

async function getPredefinedProjectFieldsFromRequestForm({
  organisationDetails,
  requestFileDetails,
  requestForm,
  request,
}) {
  let predefinedProjectFields = {};

  if (requestForm.fields["projectno-1723123694811"]?.value) {
    predefinedProjectFields.projectNumber = requestForm.fields["projectno-1723123694811"]?.value;
  }

  if (requestForm.fields["projectname-1723123721503"]?.value) {
    predefinedProjectFields.title = requestForm.fields["projectname-1723123721503"]?.value;
    predefinedProjectFields.useCustomProjectNumber = true;
  }
  return predefinedProjectFields;
}

const functions = {
  getFileName,
  changeFileNameAtDownloadTime,
  changeSheetReference,
  getProjectId,
  getTaskId,
  getQuoteId,
  getInvoiceId,
  getPurchaseOrderId,
  getSheetDescription,
  getTaskRevisionName,
  getSheetRevisionName,
  getQuoteRevisionName,
  getFrontendFileName,
  getRequestId,
  // getTimelineBlockColor,
  getPredefinedTaskFieldsFromRequestForm,
  getPredefinedProjectFieldsFromRequestForm,
};

export default functions;
