// import {
//   getFieldValue,
//   getFieldDetailsById,
// } from "common/documentEditorDataSources/aggregator";
import moment from "moment";

import log from "./log";
import { getCustomFunction } from "./naming";
import { roundToDecimals } from "common/mathHelpers";

export const DEDICATED_ORGANISATION_TEMPLATE_FOLDERS = ["AEC", "AWD", "DC", "AWD2", "AEC2", "CR", "APOLLO"];
export const DEFAULT_ORGANISATION_TEMPLATE_FOLDER = "AEC";
export const EXTERNAL_AUTH_PROVIDER_NAME = "activedirectory";

export const TEMPLATE_PARENT_TYPES = [
  {
    label: "Task",
    value: "TASK",
  },
  {
    label: "Organisation",
    value: "ORGANISATION",
  },
  {
    label: "Project",
    value: "PROJECT",
  },
  {
    label: "Client",
    value: "CLIENT",
  },
  {
    label: "User",
    value: "USER",
  },
];

export const FILE_TYPES_DETAILS = {
  AUTOCAD: {
    label: "AutoCAD",
    dataSources: [
      "day",
      "general",
      "organisation",
      "user",
      "client",
      "project",
      "task",
      "taskRevision",
      "file",
      "sheet",
      "request",
      "BYRNE",
    ],
    isDocumentTemplate: false,
    order: 0,
    isPartOfATask: true,
    hasSheets: true,
  },
  BRICSCAD: {
    label: "BricsCAD",
    dataSources: [
      "day",
      "general",
      "organisation",
      "user",
      "client",
      "project",
      "task",
      "taskRevision",
      "file",
      "sheet",
      "request",
    ],
    isDocumentTemplate: false,
    order: 1,
    isPartOfATask: true,
    hasSheets: true,
  },
  MATHCAD: {
    label: "MathCAD",
    dataSources: [
      "day",
      "general",
      "organisation",
      "user",
      "client",
      "project",
      "task",
      "taskRevision",
      "file",
      "sheet",
      "request",
    ],
    isDocumentTemplate: false,
    order: 2,
    isPartOfATask: true,
    hasSheets: false,
  },
  EXCEL: {
    label: "Excel",
    dataSources: [
      "day",
      "general",
      "organisation",
      "user",
      "client",
      "project",
      "task",
      "taskRevision",
      "file",
      "sheet",
      "request",
      "BYRNE",
    ],
    isDocumentTemplate: false,
    order: 3,
    isPartOfATask: true,
    hasSheets: false,
  },
  WORD: {
    label: "Word",
    dataSources: [
      "day",
      "general",
      "organisation",
      "user",
      "client",
      "project",
      "task",
      "taskRevision",
      "file",
      "sheet",
      "request",
      "BYRNE",
    ],
    isDocumentTemplate: false,
    order: 4,
    isPartOfATask: true,
    hasSheets: false,
  },
  POWERPOINT: {
    label: "PowerPoint",
    dataSources: [
      "day",
      "general",
      "organisation",
      "user",
      "client",
      "project",
      "task",
      "taskRevision",
      "file",
      "sheet",
      "request",
      "BYRNE",
    ],
    isDocumentTemplate: false,
    order: 5,
    isPartOfATask: true,
    hasSheets: false,
  },
  REPORT: {
    label: "Report",
    dataSources: [
      "form",
      "formElement",
      "formElementChild",
      "day",
      "general",
      "organisation",
      "user",
      "client",
      "clientContact",
      "clientAddress",
      "project",
      "task",
      "taskRevision",
      "file",
      "sheet",
      "timesheetBlock",
      "timelineBlock",
      "attachment",
      "invoice",
      "invoiceLineItem",
      "quote",
      "quoteLineItem",
      "purchaseOrder",
      "purchaseOrderLineItem",
      "request",
      "RDX",
      "BYRNE",
    ],
    isDocumentTemplate: true,
    order: 6,
    outputTypes: ["PDF", "SPREADSHEET", "SPREADSHEET-EXCEL"],
    isPartOfATask: true,
    hasSheets: false,
  },

  BLUEBEAM: {
    label: "PDF",
    dataSources: [
      "day",
      "general",
      "organisation",
      "user",
      "client",
      "project",
      "task",
      "taskRevision",
      "file",
      "sheet",
      "request",
      "BYRNE",
    ],
    isDocumentTemplate: false,
    order: 7,
    isPartOfATask: true,
    hasSheets: false,
  },
  REVIT: {
    label: "Revit",
    dataSources: [
      "day",
      "general",
      "organisation",
      "user",
      "client",
      "project",
      "task",
      "taskRevision",
      "file",
      "request",
      "sheet",
    ],
    isDocumentTemplate: false,
    order: 8,
    isPartOfATask: true,
    hasSheets: false,
  },
  QUOTE: {
    label: "Quote",
    dataSources: [
      "form",
      "formElement",
      "day",
      "general",
      "organisation",
      "user",
      "client",
      "clientContact",
      "clientAddress",
      "project",
      "quote",
      "quoteLineItem",
      "quoteFees",
    ],
    isDocumentTemplate: true,
    hasLineItems: true,
    order: 9,
  },
  INVOICE: {
    label: "Invoice",
    dataSources: [
      "form",
      "formElement",
      "day",
      "general",
      "organisation",
      "user",
      "client",
      "clientContact",
      "clientAddress",
      "project",
      "invoice",
      "invoiceLineItem",
    ],
    order: 10,
    isDocumentTemplate: true,
    hasLineItems: true,
  },
  PURCHASE_ORDER: {
    label: "Purchase order",
    dataSources: [
      "form",
      "formElement",
      "day",
      "general",
      "organisation",
      "user",
      "supplier",
      "supplierContact",
      "supplierAddress",
      "project",
      "purchaseOrder",
      "purchaseOrderLineItem",
    ],
    order: 11,
    isDocumentTemplate: true,
    hasLineItems: true,
  },
  REQUEST: {
    label: "Request (initial)",
    dataSources: ["day", "general", "organisation", "user", "form", "formElement", "attachment", "BYRNE"],
    isDocumentTemplate: true,
    isFormOnly: false,
    order: 12,
    hasSheets: false,
  },
  REQUEST_CHANGE: {
    label: "Request (changes)",
    dataSources: ["day", "general", "organisation", "user", "form", "formElement", "attachment", "BYRNE"],
    isDocumentTemplate: true,
    isFormOnly: false,
    order: 13,
    hasSheets: false,
  },
  APP_PAGE: {
    label: "Dashboard",
    dataSources: [
      "form",
      "formElement",
      "day",
      "general",
      "organisation",
      "user",
      "client",
      "clientContact",
      "clientAddress",
      "project",
      "task",
      "taskRevision",
      "file",
      "sheet",
      "timesheetBlock",
      "timelineBlock",
      "attachment",
      "invoice",
      "invoiceLineItem",
      "quote",
      "quoteLineItem",
      "purchaseOrder",
      "purchaseOrderLineItem",
      "BYRNE",
    ],
    isDocumentTemplate: true,
    order: 14,
    hasSheets: false,
    outputTypes: ["APP_PAGE"],
  },
};

export const KEY_TYPES = {
  ORGANISATION_FOLDER: "ORGANISATION_FOLDER",
  CLIENT_FOLDER: "CLIENT_FOLDER",
  REQUEST_FOLDER: "REQUEST_FOLDER",
  PROJECT_FOLDER: "PROJECT_FOLDER",
  TASK_FOLDER: "TASK_FOLDER",
  FILE_MAIN: "FILE_MAIN",
  FILE_MAIN_EXPORT_RAW: "FILE_MAIN_EXPORT_RAW",
  FILE_MAIN_EXPORT: "FILE_MAIN_EXPORT",
  FILE_SHEET_EXPORT_RAW: "FILE_SHEET_EXPORT_RAW",
  FILE_SHEET_EXPORT: "FILE_SHEET_EXPORT",
  SHEET_REFERENCE: "SHEET_REFERENCE",
  TASK_ATTACHMENT: "TASK_ATTACHMENT",
  TASK_ATTACHMENT_CUSTOM_FIELD: "TASK_ATTACHMENT_CUSTOM_FIELD",
  PROJECT_ATTACHMENT: "PROJECT_ATTACHMENT",
  CLIENT_ATTACHMENT: "CLIENT_ATTACHMENT",
  REQUEST_ATTACHMENT: "REQUEST_ATTACHMENT",
  QUOTE: "QUOTE",
  INVOICE: "INVOICE",
};

export const ATTACHMENT_FILE_TYPES = [
  {
    name: "IMAGE",
    extensions: ["png", "jpg", "jpeg"],
  },
  {
    name: "PDF",
    extensions: ["pdf"],
  },
  {
    name: "VIDEO",
    extensions: ["mp4", "mov"],
  },
  {
    name: "EMAIL",
    extensions: ["msg", "eml"],
  },
];

const TEMPLATE_EDITOR_BUILTIN_FIELDS_WITH_FORMULAS = ["width", "height", "fontSize", "strokeWidth", "fill", "stroke"];

export const TEMPLATE_EDITOR_CUSTOM_FIELDS_WITH_FORMULAS = [
  "custom_sectionFill",
  "custom_textFill",
  "custom_sectionStroke",
  "custom_defaultPagePaddingTop",
  "custom_defaultPagePaddingBottom",
  "custom_defaultPagePaddingLeft",
  "custom_defaultPagePaddingRight",
  "custom_marginTop",
  "custom_marginBottom",
  "custom_marginLeft",
  "custom_marginRight",
  "custom_paddingTop",
  "custom_paddingBottom",
  "custom_paddingLeft",
  "custom_paddingRight",
  "custom_strokeWidthTop",
  "custom_strokeWidthBottom",
  "custom_strokeWidthLeft",
  "custom_strokeWidthRight",

  "custom_width",
  "custom_height",
  "custom_absoluteTop",
  "custom_absoluteBottom",
  "custom_absoluteLeft",
  "custom_absoluteRight",

  "custom_sectionMinWidth",
  "custom_sectionMinHeight",
  "custom_sectionStrokeWidth",
  "custom_gap",
];

export const TEMPLATE_EDITOR_FORMULA_FIELDS = [
  ...TEMPLATE_EDITOR_BUILTIN_FIELDS_WITH_FORMULAS.map((fieldName) => `formula_${fieldName}`),
  ...TEMPLATE_EDITOR_CUSTOM_FIELDS_WITH_FORMULAS.map((fieldName) => `custom_formula_${fieldName.split("custom_")[1]}`),
];

export const TEMPLATE_EDITOR_CUSTOM_FIELDS = [
  ...TEMPLATE_EDITOR_CUSTOM_FIELDS_WITH_FORMULAS,
  ...TEMPLATE_EDITOR_FORMULA_FIELDS,

  ////////////////// built-ins ////////////////////
  "visible",
  "selectable",
  "evented",
  "lockMovementX",
  "lockMovementY",
  "lockScalingX",
  "lockScalingY",
  "lockUniScaling",
  "lockRotation",

  ////////////////// custom ///////////////////////

  // flags
  "custom_hasCustomPadding",
  "custom_isPageBorder",
  "custom_isPageNumber",
  "custom_allowBreak",
  "custom_calculateWidthFormulaAtDisplayTime",
  "custom_calculateHeightFormulaAtDisplayTime",
  "custom_hasPageBorders",
  "custom_isSpreadsheetRow",
  "custom_isSpreadsheetName",

  // conditional display
  "custom_usesConditionalDisplay",
  "custom_usesMultipleConditions",
  "custom_conditionalDisplayDataSource",
  "custom_conditionalDisplayDataSourceOverride",
  "custom_conditionalDisplayDataSourceField",
  "custom_conditionalDisplayCondition",
  "custom_conditionalDisplayTarget",

  // dynamic content - old version
  "custom_dynamicInformation",
  "custom_dynamicInformationDataSource",
  "custom_dynamicInformationDataSourceOverride",
  "custom_dynamicInformationParameters",

  // text transforms - old version
  "custom_staticPrefix",
  "custom_staticSuffix",
  "custom_staticSeparator",
  "custom_splitBySeparator",
  "custom_splitUseValueAtIndex",
  "custom_skipFirstXCharacters",
  "custom_textTransform",

  // repeat
  "custom_repeatForDataSource",
  "custom_repeatForDataSourceOverride",
  "custom_repeatFor",
  "custom_repeatForParameters",
  "custom_repeatAxis",
  "custom_repeatSpacing",
  "custom_repeatOffsetX",
  "custom_repeatOffsetY",

  // other
  "custom_type",
  "custom_cssPosition",
  "custom_imageSource",
  "custom_dateFormat",
  "custom_dateAddDays",
  "custom_numberPrefix",
  "custom_numberPrefixDigitsToRemove",
  "custom_targetSpreadsheetCell",
  "custom_name",
  "custom_textCount",
  "custom_rectCount",
  "custom_lineCount",
  "custom_ellipseCount",
  "custom_signatureContainerCount",
  "custom_dynamicFileContainerCount",
  "custom_imageContainerCount",
  "custom_imageCount",
  "custom_groupCount",
  "custom_sectionCount",
  "custom_pageCount",
  "custom_index",
  "custom_id",
  "custom_verticalAlign",
  "custom_imageKey",
  "custom_pageSize",
  "custom_pageHeight",
  "custom_pageWidth",
  "custom_pageContentWidth",
  "custom_pageContentHeight",
  "custom_originalImageWidth",
  "custom_originalImageHeight",
  "custom_hideOnPages",
  "custom_opacity",
  "custom_hideBackground",
  "custom_pageFontFamily",
  "custom_sectionStrokeWidth",
  "custom_sectionBackgroundOpacity",
  "custom_flexDirection",
  "custom_variables",
  "custom_conditions",
  "custom_conditionalExpression",
  "custom_isUsingOldSystem", // for migrating non-document templates to V3 of the template editor
  "custom_customFieldName", // used to pass the name of the custom field to the custom field function for dynamic values

  // component
  "custom_componentType",
  "custom_componentParameters",
];

export const TEMPLATE_APP_PAGE_COMPONENTS = [
  {
    label: "Bar chart",
    id: "bar-chart",
    parameters: [
      {
        label: "Data",
        id: "data",
      },
      {
        label: "X-axis (field name to use)",
        id: "xAxis",
      },
      {
        label: "Y-axis (field name to use)",
        id: "yAxis",
      },
      {
        label: "X-axis: date format",
        id: "xAxisDateFormat",
      },
      {
        label: "Y-axis: format as currency?",
        id: "yAxisFormatAsCurrency",
      },
      {
        label: "Color",
        id: "color",
      },
    ],
  },
  {
    label: "Line chart",
    id: "line-chart",
    parameters: [
      {
        label: "Data",
        id: "data",
      },
      {
        label: "X-axis",
        id: "xAxis",
      },
      {
        label: "Y-axis",
        id: "yAxis",
      },
      {
        label: "X-axis label",
        id: "xAxisLabel",
      },
      {
        label: "Y-axis label",
        id: "yAxisLabel",
      },
    ],
  },
  {
    label: "Tree map",
    id: "treemap",
    parameters: [
      {
        label: "Data",
        id: "data",
      },
      {
        label: "Data key (field name to use)",
        id: "dataKey",
      },
      {
        label: "Name key (field name to use)",
        id: "nameKey",
      },
      {
        label: "Only show top X items",
        id: "onlyShowTopXItems",
      },
      {
        label: "Group the rest?",
        id: "groupTheRest",
      },
      {
        label: "Value: format as currency?",
        id: "yAxisFormatAsCurrency",
      },
    ],
  },
  {
    label: "Simple stat",
    id: "simple-stat",
    parameters: [
      {
        label: "Value",
        id: "value",
      },
      {
        label: "Date format",
        id: "dateFormat",
      },
      {
        label: "Format as currency?",
        id: "formatAsCurrency",
      },
    ],
  },
  {
    label: "Progress linear",
    id: "progress",
    parameters: [
      {
        label: "Current value",
        id: "currentValue",
      },
      {
        label: "Max value",
        id: "maxValue",
      },
    ],
  },
  {
    label: "Ring chart",
    id: "ring-chart",
    parameters: [
      {
        label: "Data",
        id: "data",
      },
      {
        label: "Data key",
        id: "dataKey",
      },
      {
        label: "Name key",
        id: "nameKey",
      },
    ],
  },
];

export const TEMPLATE_EDITOR_CONDITIONS = [
  { hasTarget: false, label: "Exists and is not empty", value: "EXISTS" },
  { hasTarget: false, label: "Does not exist or is empty", value: "NOT EXISTS" },
  { hasTarget: true, label: "Equals", value: "EQUALS" },
  { hasTarget: true, label: "Equals (lowercase)", value: "EQUALS LOWERCASE" },
  { hasTarget: true, label: "Does not equal", value: "NOT EQUALS" },
  { hasTarget: true, label: "Does not equal (lowercase)", value: "NOT EQUALS LOWERCASE" },
  { hasTarget: true, label: "Contains", value: "CONTAINS" },
  { hasTarget: true, label: "Contains (lowercase)", value: "CONTAINS LOWERCASE" },
  { hasTarget: true, label: "Does not contain", value: "NOT CONTAINS" },
  { hasTarget: true, label: "Does not contain (lowercase)", value: "NOT CONTAINS LOWERCASE" },
  { hasTarget: true, label: "Starts with", value: "STARTS WITH" },
  { hasTarget: true, label: "Starts with (lowercase)", value: "STARTS WITH LOWERCASE" },
  { hasTarget: true, label: "Does not start with", value: "NOT STARTS WITH" },
  { hasTarget: true, label: "Does not start with (lowercase)", value: "NOT STARTS WITH LOWERCASE" },
  { hasTarget: true, label: "Ends with", value: "ENDS WITH" },
  { hasTarget: true, label: "Ends with (lowercase)", value: "ENDS WITH LOWERCASE" },
  { hasTarget: true, label: "Does not end with", value: "NOT ENDS WITH" },
  { hasTarget: true, label: "Does not end with (lowercase)", value: "NOT ENDS WITH LOWERCASE" },

  { hasTarget: true, label: "Is greater than", value: "GREATER THAN" },
  { hasTarget: true, label: "Is less than", value: "LESS THAN" },
  {
    hasTarget: true,
    label: "Is greater than or equal to",
    value: "GREATER OR EQUAL",
  },
  {
    hasTarget: true,
    label: "Is less than or equal to",
    value: "LESS OR EQUAL",
  },
  { hasTarget: true, label: "Is between (separate 2 values by comma)", value: "BETWEEN" },
  { hasTarget: true, label: "Is not between (separate 2 values by comma)", value: "NOT BETWEEN" },
  { hasTarget: true, label: "Is one of (separate multiple values by comma)", value: "IN" },
  { hasTarget: true, label: "Is not one of (separate multiple values by comma)", value: "NOT IN" },
  { hasTarget: false, label: "Is checked", value: "CHECKED" },
  { hasTarget: false, label: "Is not checked", value: "NOT CHECKED" },
  { hasTarget: false, label: "Is odd number", value: "ODD" },
  { hasTarget: false, label: "Is even number", value: "EVEN" },
];

export function getLatestRevision(parent) {
  let maxCreatedAt = null;
  let latestRevision = null;
  if (!parent.revisions || !parent.revisions.items) {
    return;
  }
  for (let i = 0; i < parent.revisions.items.length; i++) {
    const revision = parent.revisions.items[i];
    if (!maxCreatedAt || revision.createdAt > maxCreatedAt) {
      maxCreatedAt = revision.createdAt;
      latestRevision = revision;
    }
  }
  return latestRevision;
}

export function getLatestFileVersion(parent) {
  let maxCreatedAt = null;
  let latestVersion = null;
  for (let i = 0; i < parent.versions.items.length; i++) {
    const version = parent.versions.items[i];
    if (!maxCreatedAt || version.createdAt > maxCreatedAt) {
      maxCreatedAt = version.createdAt;
      latestVersion = version;
    }
  }
  return latestVersion;
}

export function getFilenameFromKey(key, withExtension) {
  const keyParts = key.split("/");
  const fileNameWithExtension = keyParts[keyParts.length - 1];
  if (withExtension) {
    return fileNameWithExtension;
  }
  const fileNameWithExtensionParts = fileNameWithExtension.split(".");
  return fileNameWithExtensionParts[0];
}

export function getExtensionFromKey(key) {
  const keyParts = key.split("/");
  const fileNameWithExtension = keyParts[keyParts.length - 1];
  const fileNameWithExtensionParts = fileNameWithExtension.split(".");
  return fileNameWithExtensionParts[fileNameWithExtensionParts.length - 1];
}

export function makePascalCase(payload) {
  if (Array.isArray(payload)) {
    return payload.map(makePascalCase);
  } else if (typeof payload === "object" && payload !== null) {
    let payloadPascalCase = {};
    for (let keyNameCamelCase in payload) {
      let keyNamePascalCase = `${keyNameCamelCase[0].toUpperCase()}${keyNameCamelCase.substring(1)}`;
      payloadPascalCase[keyNamePascalCase] = makePascalCase(payload[keyNameCamelCase]);
    }
    return payloadPascalCase;
  } else {
    return payload;
  }
}

export function getReadableStatus(status) {
  if (status.length === 0) {
    return status;
  }
  if (status !== status.toUpperCase()) {
    return status;
  }

  return status
    .split("_")
    .map((word) => {
      return word[0].toUpperCase() + word.substring(1).toLowerCase();
    })
    .join(" ");
}

export function getUppercaseStatus(status) {
  if (!status) {
    return;
  }
  if (status.length === 0) {
    return status;
  }
  return status.toUpperCase().split(" ").join("_");
}

export const FILE_TYPES_WITHOUT_ANNOTATION = ["REPORT"];

export const HAS_SHEETS = {
  AUTOCAD: true,
  BRICSCAD: true,
  REVIT: true,
  MATHCAD: false,
  EXCEL: false,
  WORD: false,
  BLUEBEAM: false,
  POWERPOINT: false,
  REPORT: false,
};

export function removeSpecialCharactersFromString(value, { allowForwardSlash = false, allowOneFullStop = true } = {}) {
  value = value.replace(/[^0-9.\- /a-zA-Z_]/g, "");

  if (!allowForwardSlash) {
    value = value.split("/").join("");
  }

  while (value.includes("//")) {
    value = value.split("//").join("/");
  }

  if (allowOneFullStop) {
    while (value.includes("..")) {
      value = value.split("..").join(".");
    }
  } else {
    value = value.split(".").join("");
  }

  while (value.includes("..")) {
    value = value.split("..").join(".");
  }

  while (value.includes("--")) {
    value = value.split("--").join("-");
  }

  return value;
}

export async function encodeKey(params) {
  try {
    // log({
    //   level: "DEBUG",
    //   message: "START",
    //   method: "encodeKey",
    //   details: {
    //     params,
    //   },
    // });
    let result;
    if (!Array.isArray(params)) {
      result = await encodeSingleKey(params);
    } else {
      result = [];

      for (let i = 1; i < params.length; i++) {
        for (let key in params[0].data) {
          if (!params[i].data.hasOwnProperty(key)) {
            params[i].data[key] = params[0].data[key];
          }
        }
      }

      for (let i = 0; i < params.length; i++) {
        result.push(await encodeSingleKey(params[i]));
      }
    }

    // await log({
    //   level: "DEBUG",
    //   message: "END",
    //   method: "encodeKey",
    //   details: {
    //     params,
    //     result,
    //   },
    // });

    return result;
  } catch (e) {
    await log({
      level: "ERROR",
      message: "Failed to build key",
      method: "encodeKey",
      details: {
        params,
        e,
        errorMessage: e.message,
        errorStack: e.stack,
      },
    });
    throw e;
  }
}

async function encodeSingleKey({ type, data }) {
  process.env.LOG_EVENT_ID =
    data.eventId || process.env.LOG_EVENT_ID || `${Date.now()}-${Math.floor(Math.random() * 10000000000)}`;
  const organisationPrefix = `public/${data.organisation}`;
  const projectPrefix = `${organisationPrefix}/projects/${data.projectId}`;
  const taskPrefix = `${projectPrefix}/tasks/${data.taskId}`;
  let fileNameBase = null;
  if (data.fileType) {
    data.fileType = data.fileType.toLowerCase();
    try {
      fileNameBase = await buildFileName(data, type);
    } catch (e) {
      await log({
        level: "ERROR",
        message: "Failed to build file name for organisation",
        method: "encodeSingleKey",
        details: {
          organisation: data.organisation,
          type,
          data,
          e,
        },
      });
      throw e;
    }
  }

  let completeKey = "";

  switch (type) {
    case KEY_TYPES.ORGANISATION_FOLDER:
      checkPropertiesForEncodeKey(data, ["organisation"]);
      completeKey = `${organisationPrefix}`;
      break;

    case KEY_TYPES.CLIENT_FOLDER:
      checkPropertiesForEncodeKey(data, ["organisation", "clientId"]);
      completeKey = `${organisationPrefix}/clients/${data.clientId}`;
      break;

    case KEY_TYPES.REQUEST_FOLDER:
      checkPropertiesForEncodeKey(data, ["organisation", "requestId"]);
      completeKey = `${organisationPrefix}/requests/${data.requestId}`;
      break;

    case KEY_TYPES.PROJECT_FOLDER:
      checkPropertiesForEncodeKey(data, ["organisation", "projectId"]);
      completeKey = `${projectPrefix}`;
      break;

    case KEY_TYPES.TASK_FOLDER:
      checkPropertiesForEncodeKey(data, ["organisation", "projectId", "taskId"]);
      completeKey = `${taskPrefix}`;
      break;

    case KEY_TYPES.FILE_MAIN:
      checkPropertiesForEncodeKey(data, [
        "organisation",
        "projectId",
        "taskId",
        "versionNumber",
        "fileId",
        "clientInitials",
        "projectInitials",
        "taskInitials",
        "taskRevisionName",
        "fileType",
        "extension",
      ]);
      completeKey = `${taskPrefix}/${data.taskRevisionName}/${data.fileType}/${data.fileId}/${data.versionNumber}/${fileNameBase}.${data.extension}`;
      break;

    case KEY_TYPES.FILE_MAIN_EXPORT_RAW:
      checkPropertiesForEncodeKey(data, [
        "organisation",
        "projectId",
        "taskId",
        "versionNumber",
        "fileId",
        "clientInitials",
        "projectInitials",
        "taskInitials",
        "taskRevisionName",
        "fileType",
        "extension",
      ]);
      completeKey = `${taskPrefix}/${data.taskRevisionName}/${data.fileType}/${data.fileId}/${data.versionNumber}/${fileNameBase}_raw.${data.extension}`;
      break;

    case KEY_TYPES.FILE_MAIN_EXPORT:
      checkPropertiesForEncodeKey(data, [
        "organisation",
        "projectId",
        "taskId",
        "versionNumber",
        "fileId",
        "clientInitials",
        "projectInitials",
        "taskInitials",
        "taskRevisionName",
        "fileType",
        "extension",
      ]);
      completeKey = `${taskPrefix}/${data.taskRevisionName}/${data.fileType}/${data.fileId}/${data.versionNumber}/${fileNameBase}_annotated.${data.extension}`;
      break;

    case KEY_TYPES.FILE_SHEET_EXPORT_RAW:
      checkPropertiesForEncodeKey(data, [
        "organisation",
        "projectId",
        "taskId",
        "versionNumber",
        "fileId",
        "clientInitials",
        "projectInitials",
        "taskInitials",
        "taskRevisionName",
        "sheetName",
        "fileType",
        "extension",
      ]);
      if (data.projectId === `${data.organisation}-TEMPLATES`) {
        completeKey = `${organisationPrefix}/templates/${data.fileType.toUpperCase()}/${fileNameBase}_raw.pdf`;
      } else {
        completeKey = `${taskPrefix}/${data.taskRevisionName}/${data.fileType}/${data.fileId}/${data.versionNumber}/${fileNameBase}_raw.${data.extension}`;
      }
      break;

    case KEY_TYPES.FILE_SHEET_EXPORT:
      checkPropertiesForEncodeKey(data, [
        "organisation",
        "projectId",
        "taskId",
        "versionNumber",
        "fileId",
        "clientInitials",
        "projectInitials",
        "taskInitials",
        "taskRevisionName",
        "sheetName",
        "fileType",
        "extension",
      ]);
      completeKey = `${taskPrefix}/${data.taskRevisionName}/${data.fileType}/${data.fileId}/${data.versionNumber}/${fileNameBase}.${data.extension}`;
      break;

    case KEY_TYPES.TASK_ATTACHMENT:
      checkPropertiesForEncodeKey(data, ["organisation", "projectId", "taskId", "attachmentFileName"]);
      completeKey = `${projectPrefix}/attachments/${data.taskId}/${data.attachmentFileName}`;
      break;

    case KEY_TYPES.TASK_ATTACHMENT_CUSTOM_FIELD:
      checkPropertiesForEncodeKey(data, ["organisation", "projectId", "taskId", "attachmentFileName", "fieldName"]);
      completeKey = `${projectPrefix}/attachments/${data.taskId}/${data.fieldName}/${data.attachmentFileName}`;
      break;

    case KEY_TYPES.CLIENT_ATTACHMENT:
      checkPropertiesForEncodeKey(data, ["organisation", "clientId", "attachmentFileName"]);
      completeKey = `${organisationPrefix}/clients/${data.clientId}/attachments/${data.attachmentFileName}`;
      break;

    case KEY_TYPES.REQUEST_ATTACHMENT:
      checkPropertiesForEncodeKey(data, ["organisation", "requestId", "attachmentFileName"]);
      completeKey = `${organisationPrefix}/requests/${data.requestId}/attachments/${data.attachmentFileName}`;
      break;

    case KEY_TYPES.PROJECT_ATTACHMENT:
      checkPropertiesForEncodeKey(data, ["organisation", "projectId", "attachmentFileName"]);
      completeKey = `${projectPrefix}/attachments/${data.attachmentFileName}`;
      break;

    case KEY_TYPES.QUOTE:
      checkPropertiesForEncodeKey(data, ["organisation", "projectId", "quoteId", "extension"]);
      completeKey = `${projectPrefix}/quotes/${data.quoteId}/${fileNameBase}.${data.extension}`;
      break;

    case KEY_TYPES.INVOICE:
      checkPropertiesForEncodeKey(data, ["organisation", "projectId", "invoiceId", "extension"]);
      completeKey = `${projectPrefix}/invoices/${data.invoiceId}/${fileNameBase}.${data.extension}`;
      break;

    case KEY_TYPES.PURCHASE_ORDER:
      checkPropertiesForEncodeKey(data, ["organisation", "projectId", "purchaseOrderId", "extension"]);
      completeKey = `${projectPrefix}/purchase-orders/${data.purchaseOrderId}/${fileNameBase}.${data.extension}`;
      break;

    default:
      return null;
  }

  return removeSpecialCharactersFromString(completeKey, {
    allowForwardSlash: true,
  });
}

function checkPropertiesForEncodeKey(data, propertyList) {
  propertyList.forEach((propertyName) => {
    if (!data.hasOwnProperty(propertyName)) {
      throw new Error(
        `For encodeKey, data must have property '${propertyName}'. data = ${JSON.stringify(data, null, 2)}`
      );
    }
  });
}

// the following functions are specific to each client's organisation
export async function buildFileName(data, type) {
  // log({
  //   level: "DEBUG",
  //   message: "START",
  //   method: "buildFileName",
  //   details: {
  //     data,
  //     type,
  //   },
  // });

  let fileName;
  try {
    fileName = await getCustomFunction("getFileName", {
      organisation: data.organisation,
      data,
      type,
    });
    // await log({
    //   level: "DEBUG",
    //   message: "Built file name for organisation",
    //   method: "buildFileName",
    //   details: {
    //     fileName,
    //   },
    // });
  } catch (e) {
    await log({
      level: "ERROR",
      message: "Failed to build file name for organisation",
      method: "buildFileName",
      details: {
        organisation: data.organisation,
        type,
        data,
      },
      error: e,
    });
    throw e;
  }

  // log({
  //   level: "DEBUG",
  //   message: "END",
  //   method: "buildFileName",
  //   details: {
  //     fileName,
  //   },
  // });
  return fileName;
}

export function getTemplateFromOrganisation({
  organisationDetails,
  templateId,
  fileType,
  includeOldStyleTemplates = true,
}) {
  if (!templateId) {
    templateId = "default";
  }

  let templateDetails = organisationDetails.templates.items.find((template) => template.id === templateId);

  // for templates which were created on the old system, they have non-namespaced names, such as "default"
  // these need to be mapped, e.g. "default" -> "AEC_default"
  if (!templateDetails) {
    if (!includeOldStyleTemplates) {
      return;
    }
    templateId = `${organisationDetails.id}_${fileType}_${templateId}`;
    templateDetails = organisationDetails.templates.items.find((template) => template.id === templateId);
  }
  let namespacePrefix = `${organisationDetails.id}_${fileType}_`;

  let nonNamespacedTemplateId = templateId;

  if (nonNamespacedTemplateId.startsWith(namespacePrefix)) {
    nonNamespacedTemplateId = nonNamespacedTemplateId.replace(namespacePrefix, "");
  }
  return {
    ...templateDetails,
    nonNamespacedTemplateId,
    id: templateId,
  };
}

export function processIdForDisplay(id = "") {
  let hideOrganisationId;
  let organisationId;
  if (global.isBrowser) {
    hideOrganisationId = window.organisationDetails?.settings?.general?.hideOrganisationIdInTags;
    organisationId = window.organisationDetails?.id;
  } else {
    hideOrganisationId = global.organisationDetails?.settings?.general?.hideOrganisationIdInTags;
    organisationId = global.organisationDetails?.id;
  }

  if (hideOrganisationId) {
    let targetStringToRemove = `${organisationId}-`;
    if (id.startsWith(targetStringToRemove)) {
      id = id.substring(targetStringToRemove.length);
    }
  }

  return id;
}

export function formatCurrency(currency, value) {
  let roundedValue = roundToDecimals(value, 2);
  return new Intl.NumberFormat("en-EN", {
    style: "currency",
    currency,
  }).format(roundedValue);
}

export function getAttachmentTypeFromKey(key) {
  const extension = getExtensionFromKey(key);
  let attachmentType = ATTACHMENT_FILE_TYPES.find((type) => type.extensions.includes(extension.toLowerCase()));
  let attachmentTypeName = attachmentType ? attachmentType.name : "OTHER";
  return attachmentTypeName;
}

export function truncateText(text, maxLength = 60, ellipsis = "...") {
  if (!text) {
    return "";
  }
  if (text.length <= maxLength) {
    return text;
  }
  return `${text.substring(0, maxLength)}${ellipsis}`;
}

export async function fetchActivityItemsForRequest(requestId) {
  let activityItems;
  if (global.isBrowser) {
    activityItems = (
      await window.callGraphQLSimple({
        query: "listActivityItemsByParent",
        message: "Failed to list activity items",
        variables: {
          parentId: requestId,
          limit: 1000,
        },
      })
    ).data.listActivityItemsByParent.items;
  } else {
    activityItems = (
      await global.nodeCallAppSync({
        query: global.queriesAutoGenerated.listActivityItemsByParent,
        variables: {
          parentId: requestId,
          limit: 1000,
        },
        logOutput: true,
      })
    ).data.listActivityItemsByParent.items;
  }

  let activityItemsWithParsedContent = activityItems.map((activityItem) => {
    let parsedContent = activityItem.content;
    if (parsedContent) {
      try {
        parsedContent = JSON.parse(activityItem.content);
      } catch (e) {
        // nothing we can do, it means it's not JSON
      }
    }
    return {
      ...activityItem,
      content: parsedContent,
    };
  });

  return {
    requestId,
    activityItems: activityItemsWithParsedContent,
  };
}

export function trimStringToLength(string, length) {
  if (string.length <= length) {
    return string;
  }
  return `${string.substring(0, length)}...`;
}

export function isUserAvailableInInterval({ userId, startDate, endDate, holidays }) {
  let isUnavailable = false;
  let holidaysForUser = holidays.filter((holiday) => holiday.userId === userId);
  let reasons = [];
  if (!startDate || !endDate) {
    return {
      isUnavailable: true,
      reason: "No start or end date",
    };
  }

  let conflictingHolidaysWithConflictingDays = holidaysForUser
    .filter((holiday) => {
      return holiday.days.some(({ day }) => {
        let dayIsConflicting = moment(day).isBetween(startDate, endDate, "day", "[]");
        return dayIsConflicting;
      });
    })
    .map((holiday) => {
      return {
        ...holiday,
        days: holiday.days.filter(({ day }) => {
          let dayIsConflicting = moment(day).isBetween(startDate, endDate, "day", "[]");
          return dayIsConflicting;
        }),
      };
    });

  if (conflictingHolidaysWithConflictingDays.length > 0) {
    isUnavailable = true;
    let sickConflictingHolidays = conflictingHolidaysWithConflictingDays.filter((holiday) => holiday.isSick);
    let nonSickConflictingHolidays = conflictingHolidaysWithConflictingDays.filter((holiday) => !holiday.isSick);
    if (sickConflictingHolidays?.length > 0) {
      let numberOfSickDays = sickConflictingHolidays.reduce((acc, current) => {
        return acc + current.days.length;
      }, 0);
      reasons.push(`${numberOfSickDays} day${numberOfSickDays === 1 ? "" : "s"} sick`);
    }

    if (nonSickConflictingHolidays?.length > 0) {
      let numberOfHolidayDays = nonSickConflictingHolidays.reduce((acc, current) => {
        return acc + current.days.length;
      }, 0);
      reasons.push(`${numberOfHolidayDays} day${numberOfHolidayDays === 1 ? "" : "s"} on holiday`);
    }
  }

  // let userIsAlreadyAssignedToTaskInInterval = tasksWithOverlappingDates?.some((task) => {
  //   return task.assignedTo === userId || task.assignedToUsers?.includes(userId);
  // });

  // if (userIsAlreadyAssignedToTaskInInterval) {
  //   isUnavailable = true;
  //   reasons.push(`Assigned to another ${getSimpleLabel("task")}`);
  // }

  return {
    isUnavailable,
    reason: reasons.join(", "),
  };
}
