import axios from "axios";

import { callGraphQLSimple } from "common/apiHelpers";
import { removeSpecialCharactersFromString } from "common/shared";
import { shouldFieldBeDisplayed } from "ReportPage/Report/reportHelpers";
import getS3File from "common/getS3File";

export function removeSpecialCharactersFromForm({ form, values = {}, targetFields = [] }) {
  if (Object.keys(values).length === 0 || targetFields.length === 0) {
    return;
  }

  let fieldsToChange = {};
  targetFields.forEach((fieldName) => {
    if (values.hasOwnProperty(fieldName) && values[fieldName] !== undefined) {
      fieldsToChange[fieldName] = removeSpecialCharactersFromString(values[fieldName], { allowOneFullStop: false });
    }
  });

  if (Object.keys(fieldsToChange).length === 0) {
    return;
  }

  form.setFieldsValue(fieldsToChange);
}

export async function isFormValid({ fileId }) {
  try {
    const requestFormFile = (
      await callGraphQLSimple({
        queryCustom: "getFile",
        variables: {
          id: fileId,
        },
      })
    ).data.getFile;
    const latestFormFileVersionKey = requestFormFile.versions.items.slice(-1)[0].key;
    const publicFormFileUrl = await getS3File(latestFormFileVersionKey);
    const formFileContents = (await axios.get(publicFormFileUrl)).data;
    let validationErrors = [];
    for (let fieldKey in formFileContents.fields) {
      let fieldIsVisible = shouldFieldBeDisplayed({ form: formFileContents, fieldName: fieldKey });

      // we must not validate fields which are currently hidden by conditional logic
      if (!fieldIsVisible) {
        continue;
      }

      const fieldDetails = formFileContents.fields[fieldKey];
      if (fieldDetails.isMandatory) {
        let errorMessage;
        switch (fieldDetails.type) {
          case "checkbox":
            if (!fieldDetails.value) {
              errorMessage = "You must check this box in order to continue";
            }
            break;
          case "textfield":
            if (!fieldDetails.value) {
              errorMessage = "This field cannot be empty";
            }
            break;
          case "radio-list":
            if (!fieldDetails.value) {
              errorMessage = "You must select an option";
            }
            break;
          case "checkbox-list":
            if (!fieldDetails.value || fieldDetails.value.length === 0) {
              errorMessage = "You must select at least one option";
            }
            break;
          case "dropdown":
            if (!fieldDetails.value || (Array.isArray(fieldDetails.value) && fieldDetails.value.length === 0)) {
              errorMessage = "You must select an option";
            }
            break;
          case "date-picker":
            if (!fieldDetails.value) {
              errorMessage = "You must select a date";
            }
            break;
          case "number":
            if (fieldDetails.value === undefined || fieldDetails.value === null || fieldDetails.value === "") {
              errorMessage = "This field cannot be empty";
            }
            break;
          case "textarea":
            const textAreaIsEmpty = isMultiLineTextFieldEmpty(fieldDetails.value);
            if (textAreaIsEmpty) {
              errorMessage = "This field cannot be empty";
            }
            break;
          case "attachmentPicker":
            if (!fieldDetails.value || fieldDetails.value.length === 0) {
              errorMessage = "You must select at least one file";
            }
            break;
          case "attachmentUploader":
            if (!fieldDetails.value || fieldDetails.value.length === 0) {
              errorMessage = "You must upload at least one file";
            }
            break;
          default:
            break;
        }
        if (errorMessage) {
          validationErrors.push({
            fieldKey,
            fieldLabel: fieldDetails.label,
            errorMessage,
          });
        }
      }
    }

    return {
      isValid: validationErrors.length === 0,
      validationErrors,
    };
  } catch (e) {
    console.error("Error encountered when trying to validate form:", e);
    throw e;
  }
}

export function isMultiLineTextFieldEmpty(value) {
  if (value === undefined || value === null || value === "") {
    return true;
  }

  if (value.trim() === "") {
    return true;
  }

  let textEditorElements;
  try {
    textEditorElements = JSON.parse(value);
  } catch (e) {
    console.error("Error parsing multiline textfield value:", value, e);

    // it means it's not a JSON string, so if we got to this point, it's not empty
    return false;
  }

  let allElementsAreParagraphs = textEditorElements.every((element) => element.type === "paragraph");

  if (!allElementsAreParagraphs) {
    return false;
  }

  let allParagraphsAreEmpty = textEditorElements.every(
    (element) =>
      !element.children ||
      element.children.length === 0 ||
      !element.children[0].text ||
      element.children[0].text.trim().length === 0
  );

  return allParagraphsAreEmpty;
}
