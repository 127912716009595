import React from "react";

import { Breadcrumb, Button, Tabs, Alert, Typography, Space, message, Empty } from "antd";
import { CopyOutlined, FileTextOutlined, MailOutlined } from "@ant-design/icons";
import query from "query-string";
import { withRouter, Link } from "react-router-dom";

import withSubscriptions from "common/withSubscriptions";
import { updateProject } from "graphql/queries_custom";
import { callGraphQL, getLabel } from "common/helpers";
import { isAuthorised } from "common/permissions";

import ProjectItem from "ProjectsPage/ProjectItem/ProjectItem";
import ProjectActions from "./ProjectActions/ProjectActions";
import ProjectQuotes from "./ProjectQuotes/ProjectQuotes";
import ProjectInvoices from "./ProjectInvoices/ProjectInvoices";
import ProjectBudget from "./ProjectBudget/ProjectBudget";
import ProjectTimesheets from "./ProjectTimesheets/ProjectTimesheets";
import ProjectSummary from "./ProjectSummary/ProjectSummary";
import NonTaskReports from "../NonTaskReports/NonTaskReports";
import ProjectSidebar from "./ProjectSidebar/ProjectSidebar";
import Attachments from "Attachments/Attachments";
import ProjectAddress from "./ProjectAddress/ProjectAddress";
import PurchaseOrderListItem from "PurchaseOrderListItem/PurchaseOrderListItem";

import Card from "Card/Card";
import TaskListItem from "TaskListItem/TaskListItem";
import ProjectIdTag from "ProjectIdTag/ProjectIdTag";
import ContactList from "../ContactList/ContactList";
import SendPublicUploadEmail from "Modals/SendPublicUploadEmail/SendPublicUploadEmail";
import CopyLinkButton from "CopyLinkButton/CopyLinkButton";
import Explanation from "Explanation/Explanation";
import Activity from "Activity/Activity";
import UnauthorisedPage from "UnauthorisedPage/UnauthorisedPage";

import "./ProjectDetailsPage.scss";
import { getSimpleLabel } from "common/labels";
import { callGraphQLSimple } from "common/apiHelpers";

export class ProjectDetailsPage extends React.Component {
  state = {
    activeTab: "tasks",
    isCreateContactModalVisible: false,
    isSendPublicUploadEmailVisible: false,
    attachmentsPrefix: "",
  };

  async componentDidMount() {
    this.props.setBoxedLayout(false);
    const queryString = query.parse(this.props.location.search);
    const activeTab = queryString.projectTab;
    const attachmentPath = queryString.attachmentPath;

    if (activeTab) {
      this.setState({ activeTab });
    }

    if (attachmentPath) {
      this.setState({ attachmentPath });
    }

    window.callGraphQLSimple({
      displayError: false,
      mutation: "createAuditItem",
      variables: {
        input: {
          taskId: "nothing",
          projectId: this.props.project.id,
          fileId: "nothing",
          userId: window.apiUser.id,
          clientId: this.props.project.clientId,
          page: "PROJECT_DETAILS",
          type: "PAGE_VIEW",
          organisation: window.apiUser.organisation,
        },
      },
    });
  }

  componentWillUnmount() {
    this.props.setBoxedLayout(true);
  }

  displayExternalReferenceTasks = () => {
    const { tasks, project, organisationDetails, windowWidth } = this.props;

    const externalRefTasks = tasks.filter((x) => x.isExternalReference && x.projectId === project.id);

    if (externalRefTasks.length === 0) {
      return null;
    }

    return (
      <Card className="project-external-reference-task-list" title="External References">
        {externalRefTasks.map((task) => (
          <TaskListItem key={task.id} organisationDetails={organisationDetails} {...task} windowWidth={windowWidth} />
        ))}
      </Card>
    );
  };

  generatePublicUploadUrl = () => {
    const { project, organisationDetails, apiUser } = this.props;
    const { attachmentsPrefix } = this.state;

    const details = {
      project: project?.id,
      organisation: organisationDetails?.id,
      email: apiUser?.id,
      firstName: apiUser?.firstName,
      lastName: apiUser?.lastName,
      projectTitle: project?.title,
      attachmentsPrefix,
    };

    let detailsBase64;
    let url;

    try {
      detailsBase64 = btoa(JSON.stringify(details));
      url = `${window.location.origin}/public-upload?details=${detailsBase64}`;
      return url;
    } catch (e) {
      message.error(`Failed to generate URL for public upload due to a non-standard character in the project details`);
      throw e;
    }
  };

  render() {
    const {
      project,
      users,
      user,
      apiUser,
      organisationDetails,
      clients,
      windowWidth,
      quotes,
      purchaseOrders,
      invoices,
    } = this.props;
    const { activeTab, isSendPublicUploadEmailVisible } = this.state;
    const client = clients.find((x) => x?.id === project?.clientId);

    const basePath = `/projects/${project.id}`;

    let layout = {};

    if (windowWidth > 1430) {
      layout.mainContentWidth = "100%";
      layout.sidebarWidth = "300px";
    } else {
      layout.mainContentWidth = "100%";
      layout.sidebarWidth = 0;
    }

    if (project.team && !(apiUser.teams || []).includes(project.team)) {
      return <UnauthorisedPage />;
    }

    return (
      <div className="project-details-page">
        <div className="breadcrumb-bar">
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to={`/clients/${project.clientId}`}>{project.client?.name}</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <ProjectIdTag project={{ ...project, client }} includeTitle={true} />
              <CopyLinkButton />
            </Breadcrumb.Item>
          </Breadcrumb>
        </div>

        <div className="page-content">
          <div style={{ width: layout.mainContentWidth }}>
            <Space direction="vertical" size="large" style={{ width: "100%" }}>
              <ProjectSummary project={project} client={client} user={user} users={users} apiUser={apiUser} />
              {layout.sidebarWidth === 0 && (
                <div style={{ marginTop: "-1rem" }}>
                  <ProjectSidebar project={project} user={user} splitLayout={windowWidth > 500} />
                </div>
              )}
              {project.isFinished ? (
                <Alert
                  showIcon
                  message={`This ${getSimpleLabel(
                    "project"
                  )} is finished. While in this state, you cannot assign any ${getSimpleLabel(
                    "tasks"
                  )}, ${getSimpleLabel("quotes")}, invoices or purchase orders to it.`}
                  type="info"
                />
              ) : null}
              {project.isArchived ? (
                <Alert
                  showIcon
                  message="This project is archived. While in this state, you cannot make any changes to it."
                  type="info"
                />
              ) : null}

              <Typography.Text className="get-share-link">
                Share link with {getSimpleLabel("clients")} for direct upload to this{" "}
                {getLabel({
                  organisationDetails,
                  id: "project",
                  defaultValue: "project",
                })}
                :
                <Button
                  type="primary"
                  data-cy="copy-share-link-button"
                  onClick={async () => {
                    const { attachmentsPrefix } = this.state;
                    const { project } = this.props;
                    let publicUploadUrl;
                    try {
                      publicUploadUrl = this.generatePublicUploadUrl();
                    } catch (e) {
                      return;
                    }

                    let attachmentsTargetLabel = attachmentsPrefix
                      .substring(`${project.id}/`.length)
                      .split("//")
                      .join("/");

                    if (attachmentsTargetLabel.endsWith("/")) {
                      attachmentsTargetLabel = attachmentsTargetLabel.substring(0, attachmentsTargetLabel.length - 1);
                    }

                    if (!attachmentsTargetLabel) {
                      attachmentsTargetLabel = `the root folder of this ${getSimpleLabel("project")}.`;
                    }

                    await navigator.clipboard.writeText(publicUploadUrl);

                    message.success({
                      content: (
                        <Typography.Text>
                          Link copied. Uploads will be made to <b>{attachmentsTargetLabel}</b>
                        </Typography.Text>
                      ),
                    });
                    await callGraphQLSimple({
                      mutation: "createActivityItem",
                      variables: {
                        input: {
                          parentId: project.id,
                          content: JSON.stringify({
                            type: "PUBLIC_UPLOAD_LINK_COPIED",
                            publicUploadUrl,
                            attachmentsTargetLabel,
                          }),
                          type: "LIFECYCLE_EVENT",
                          organisation: project.organisation,
                          author: apiUser.id,
                        },
                      },
                    });
                  }}
                >
                  <CopyOutlined />
                  Copy link
                </Button>
                <Button
                  type="primary"
                  onClick={() => {
                    try {
                      this.generatePublicUploadUrl();
                    } catch (e) {
                      return;
                    }
                    this.setState({ isSendPublicUploadEmailVisible: true });
                  }}
                >
                  <MailOutlined />
                  Email link
                </Button>
              </Typography.Text>

              <Tabs
                className="project-main-tabs"
                activeKey={activeTab}
                onTabClick={(tabKey) => {
                  this.props.history.push(`${basePath}?projectTab=${tabKey}`);
                  this.setState({ activeTab: tabKey });
                }}
              >
                <Tabs.TabPane
                  tab={getLabel({
                    organisationDetails,
                    id: "Tasks",
                    defaultValue: "Tasks",
                  })}
                  key="tasks"
                >
                  <ProjectItem
                    project={project}
                    users={users}
                    organisationDetails={organisationDetails}
                    windowWidth={windowWidth}
                    displayProjectInfo={true}
                  />
                </Tabs.TabPane>
                {(organisationDetails.settings?.quote?.usesQuotes ||
                  organisationDetails.settings?.timesheet?.usesTimesheets ||
                  organisationDetails.settings?.invoice?.usesInvoices) &&
                  isAuthorised(["PROJECT.VIEW_FINANCIALS"]) && (
                    <Tabs.TabPane
                      tab={getLabel({
                        id: "financials-tab",
                        defaultValue: "Financials",
                      })}
                      key="financials"
                    >
                      <div className="financials-content">
                        {(organisationDetails.settings?.quote?.usesQuotes ||
                          organisationDetails.settings?.timesheet?.usesTimesheets) &&
                          isAuthorised(["PROJECT.VIEW_BUDGET_BAR"]) && (
                            <ProjectBudget project={project} quotes={quotes} invoices={invoices} />
                          )}
                        {organisationDetails.settings?.quote?.usesQuotes && (
                          <ProjectQuotes
                            project={project}
                            quotes={quotes}
                            users={users}
                            apiUser={apiUser}
                            organisationDetails={organisationDetails}
                          />
                        )}
                        {organisationDetails.settings?.invoice?.usesInvoices && (
                          <ProjectInvoices project={project} users={users} apiUser={apiUser} invoices={invoices} />
                        )}
                      </div>
                    </Tabs.TabPane>
                  )}
                <Tabs.TabPane tab="Reports" key="reports" data-cy="reports">
                  <NonTaskReports project={project} parentType="PROJECT" />
                </Tabs.TabPane>
                {organisationDetails.settings?.purchaseOrder?.usesPurchaseOrders && (
                  <Tabs.TabPane
                    tab={<Typography.Text data-cy="purchase-orders-tab">Purchase orders</Typography.Text>}
                    key="purchase-orders"
                    data-cy="purchase-orders"
                  >
                    {purchaseOrders.length > 0 ? (
                      purchaseOrders
                        .filter((x) => x.projectId === project.id)
                        .map((purchaseOrder) => (
                          <PurchaseOrderListItem
                            purchaseOrder={purchaseOrder}
                            key={`purchase-order-${purchaseOrder.id}`}
                            organisationDetails={organisationDetails}
                          />
                        ))
                    ) : (
                      <Empty description="No purchase orders found" />
                    )}
                  </Tabs.TabPane>
                )}

                {organisationDetails.settings?.timesheet?.usesTimesheets && (
                  <Tabs.TabPane tab="Timesheets" key="timesheets">
                    <ProjectTimesheets project={project} users={users} apiUser={apiUser} />
                  </Tabs.TabPane>
                )}

                <Tabs.TabPane
                  tab={
                    <Typography.Text data-cy="attachments-tab">
                      <>
                        Attachments{" "}
                        <Explanation
                          title={
                            <>
                              The "Attachments" tab contains files which are uploaded by users, such as client
                              documentation, site photos, etc. If a file with the same name gets uploaded multiple
                              times, all previous versions are still available, therefore nothing gets overwritten.
                              <br />
                              <br />
                              If you are looking for files produced via DraughtHub, such as drawings, calculations, or
                              reports, these can be found inside each {getLabel({ id: "task" })} under the "
                              {getLabel({ id: "Tasks" })}" tab.
                              <br />
                              <br />
                              Alternatively, you can find the underlying cloud file system for the entire{" "}
                              {getLabel({ id: "project" })} under the "Raw cloud storage" tab.
                            </>
                          }
                        />
                      </>
                    </Typography.Text>
                  }
                  key="attachments"
                >
                  <Attachments
                    project={project}
                    apiUser={apiUser}
                    onPrefixChange={(prefix) => this.setState({ attachmentsPrefix: prefix })}
                  />
                </Tabs.TabPane>
                <Tabs.TabPane
                  tab={
                    <>
                      <Typography.Text>
                        Raw cloud storage{" "}
                        <Explanation
                          title={
                            <>
                              The "Raw cloud storage" tab contains the underlying cloud file system for the entire{" "}
                              {getLabel({ id: "project" })}. It contains all the files produced via DraughtHub,
                              including every saved version of each file. <br />
                              <br />
                              Please note that the main way of accessing files produced via DraughtHub, such as
                              drawings, calculations, or reports, is to go inside the corresponding{" "}
                              {getLabel({ id: "task" })} found under the "{getLabel({ id: "Tasks" })}" tab.
                              <br />
                              <br />
                              If you are looking for files which are uploaded by users, such as client documentation,
                              site photos, etc., these can be found under the "Attachments" tab.
                            </>
                          }
                        />
                      </Typography.Text>
                    </>
                  }
                  key="raw-cloud-files"
                >
                  <Attachments
                    project={project}
                    apiUser={apiUser}
                    defaultPath={this.state.attachmentPath ?? `projects/${project.id}`}
                    readOnly
                  />
                </Tabs.TabPane>
                <Tabs.TabPane tab={<Typography.Text data-cy="address-tab">Address</Typography.Text>} key="Address">
                  <ProjectAddress project={project} apiUser={apiUser} />
                </Tabs.TabPane>
                <Tabs.TabPane tab="Contacts" key="contacts">
                  <ContactList
                    parent={project}
                    isDisplayedOnProjectDetailsPage={true}
                    mutation={updateProject}
                    client={client}
                  />
                </Tabs.TabPane>
                <Tabs.TabPane tab={<Typography.Text data-cy="activity-tab">Activity</Typography.Text>} key="activity">
                  <Activity parentLabel="Project" parentId={project.id} parentRecord={project} apiUser={apiUser} />
                </Tabs.TabPane>
                <Tabs.TabPane tab="Actions" key="actions">
                  <ProjectActions apiUser={apiUser} project={project} />
                </Tabs.TabPane>
              </Tabs>
            </Space>
          </div>

          {layout.sidebarWidth !== 0 && (
            <div style={{ width: layout.sidebarWidth, flexShrink: 0 }}>
              <ProjectSidebar project={project} user={user} quotes={quotes} />
            </div>
          )}

          {isSendPublicUploadEmailVisible && (
            <SendPublicUploadEmail
              apiUser={apiUser}
              visible={isSendPublicUploadEmailVisible}
              project={project}
              client={client}
              generatePublicUploadUrl={this.generatePublicUploadUrl}
              onClose={() => this.setState({ isSendPublicUploadEmailVisible: false })}
            />
          )}
        </div>
      </div>
    );
  }
}

export default withRouter(
  withSubscriptions({
    Component: ProjectDetailsPage,
    subscriptions: [
      "tasks",
      "project",
      "users",
      "organisationDetails",
      "clients",
      "purchaseOrders",
      "quotes",
      "invoices",
    ],
  })
);
