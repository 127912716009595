import React from "react";
import { Typography, Button, Modal, message } from "antd";
import { PlusCircleOutlined, CaretRightOutlined } from "@ant-design/icons";
import cx from "classnames";

import {
  displayModalForNestedField,
  // displayReportUserListModal,
  displayModalContainingFields,
  displayModalContainingSectionFields,
  displayFields,
} from "ReportPage/Report/reportHelpers";

import Card from "Card/Card";
import OverallSpinner from "OverallSpinner/OverallSpinner";
import FormFieldModal from "../FormFieldModal/FormFieldModal";

import "./FormEditor.scss";

const FIELDS_TO_SAVE = [
  "label",
  "type",
  "placeholder",

  "renderedInReport",
  "useDynamicOptions",
  "takeFileNameFromSelectedOption",
  "autoSelectFirstOption",
  "dataSource",
  "dataSourceField",
  "buttonText",
  "isHidden",
  "isMandatory",
  "usesConditionalDisplay",
  "conditionalDisplayDataSource",
  "conditionalDisplayDataSourceOverride",
  "conditionalDisplayDataSourceField",
  "conditionalDisplayCondition",
  "conditionalDisplayTarget",
  "isForLineItems",
  "textOnly",
  "basicFormattingOnly",
  "presentationOnly",
  "editable",
  "readOnly",
  "allowPreview",
  "defaultPath",
  "defaultRelativePath",
  "allowDownload",
  "allowClear", // for dropdowns, date pickers, etc.
  "doNotAllowList",
  "compactFields",
  "sectionItemsAreAlwaysExpanded",
  "hasInlineDescription",
  "inlineDescription",
  "hasTooltipDescription",
  "tooltipDescription",
  "isAlwaysExpanded",
  "optionsRepresentFormFiles",
  "useValueInTaskCreation",
  "targetTaskField",
  "triggersFormUpdate",
  "resultingFileNameSuffix",
];

export default class FormEditor extends React.Component {
  state = {
    fields: undefined,
    form: undefined,
    fieldsArray: undefined,
    isFormEditor: true,
    selectedFormField: undefined,
    selectedModalContainerField: undefined,
    selectedNestedContainerField: undefined,
    isAddingFormField: false,
  };

  componentDidMount() {
    this.downloadForm();
  }

  downloadForm = async () => {
    const { form } = this.props;

    this.setState({
      form: form,
      fields: form.fields,
      fieldsArray: this.formToFieldsArray(form),
    });
  };

  formToFieldsArray = (form) => {
    const orderedFieldKeys = Object.keys(form.fields)
      .map((key) => form.fields[key].adminOrder)
      .map((adminOrder) => {
        return Object.keys(form.fields).find((key) => form.fields[key].adminOrder === adminOrder);
      });

    return orderedFieldKeys.map((key) => {
      return {
        id: key,
        adminOrder: undefined,
        ...form.fields[key],
      };
    });
  };

  fieldsArrayToform = (fieldsArray) => {
    let fieldsDictionary = {};
    fieldsArray.forEach((field, i) => {
      fieldsDictionary[field.id] = {
        ...field,
        adminOrder: i,
      };
    });
    return {
      fields: fieldsDictionary,
    };
  };

  onFieldEdit = ({ fieldData, duplicate = false }) => {
    let newState = {
      selectedFormField: fieldData,
    };

    if (duplicate) {
      newState.isAddingFormField = true;
    }

    this.setState(newState);
  };

  changeFieldOrder = ({ fieldData, delta, skipFieldsWithParent }) => {
    const { fieldsArray } = this.state;
    let newFieldsArray = JSON.parse(JSON.stringify(fieldsArray));
    const newFieldData = JSON.parse(JSON.stringify(fieldData));
    let fieldDataIndex = newFieldsArray.findIndex((field) => field.id === fieldData.id);
    newFieldsArray.splice(fieldDataIndex, 1);
    if (!skipFieldsWithParent) {
      if (delta < 0) {
        newFieldsArray.splice(fieldDataIndex + delta, 0, newFieldData);
      } else {
        newFieldsArray.splice(fieldDataIndex + delta, 0, newFieldData);
      }
    } else {
      let newFieldDataIndex;
      if (delta === -1) {
        newFieldDataIndex =
          newFieldsArray.findLastIndex(
            (field, i) => !field.hiddenInModalBy && !field.parentSection && i < fieldDataIndex
          ) - 1;
        if (newFieldDataIndex < 0) {
          newFieldDataIndex = 0;
        }
        newFieldsArray.splice(newFieldDataIndex, 0, newFieldData);
      } else {
        newFieldDataIndex = newFieldsArray.findIndex(
          (field, i) => !field.hiddenInModalBy && !field.parentSection && i > fieldDataIndex
        );
        newFieldsArray.splice(newFieldDataIndex, 0, newFieldData);
      }
    }
    this.saveNewFields(newFieldsArray);
  };

  onFieldDelete = ({ fieldData }) => {
    Modal.confirm({
      title: "Confirm field delete",
      content: (
        <>
          Are you sure you want to delete <b>{fieldData.label}</b>?
        </>
      ),
      onOk: async () => {
        const { fieldsArray, selectedNestedContainerField } = this.state;
        let newFieldsArray;
        if (selectedNestedContainerField) {
          newFieldsArray = fieldsArray.map((field) => {
            if (field.id === selectedNestedContainerField.id) {
              let nestedFields = JSON.parse(JSON.stringify(field.fields));
              delete nestedFields[fieldData.id];
              return {
                ...field,
                fields: nestedFields,
              };
            } else {
              return field;
            }
          });
        } else {
          newFieldsArray = fieldsArray.filter((field) => field.id !== fieldData.id);
          if (fieldData.type === "dynamicSectionList") {
            newFieldsArray = newFieldsArray.filter(
              (field) => field.isPlaceholderForDynamicSectionList !== fieldData.id
            );
          }
        }
        this.saveNewFields(newFieldsArray);
      },
    });
  };

  saveNewFields = async (fieldsArray) => {
    const { template, isReadOnly, onFormChange } = this.props;
    const { selectedNestedContainerField } = this.state;
    let newForm = this.fieldsArrayToform(fieldsArray);

    let newState = {};

    if (selectedNestedContainerField) {
      let updatedSelectedNestedContainerField = fieldsArray.find((x) => x.id === selectedNestedContainerField.id);
      newState = {
        ...newState,
        selectedNestedContainerField: updatedSelectedNestedContainerField,
      };
    }

    newState = {
      ...newState,
      selectedFormField: undefined,
      isAddingFormField: false,
      form: newForm,
      fieldsArray,
      fields: newForm.fields,
    };

    this.setState(newState);
    if (isReadOnly) {
      message.error("Cannot save while in read-only mode");
    } else {
      onFormChange(newForm);
      await Storage.put(template.key, JSON.stringify(newForm, null, 2));
    }
  };

  buildFieldId = ({ label }) => {
    let charactersToRemove = [",", ".", "&", "/", "%", "#", "@", "!", "^", "*", "(", ")", '"', "?", " "];
    charactersToRemove.forEach((character) => (label = label.split(character).join("")));
    return `${label.toLowerCase()}-${Date.now()}`;
  };

  onFormModalSubmit = async (params) => {
    const {
      isAddingFormField,
      fieldsArray,
      selectedModalContainerField,
      selectedNestedContainerField,
      selectedSectionContainerField,
    } = this.state;

    let newField = {
      id: isAddingFormField ? this.buildFieldId(params) : params.id,
      hiddenInModalBy: selectedModalContainerField?.id,
      parentSection: selectedSectionContainerField?.id,
    };

    for (let fieldName of FIELDS_TO_SAVE) {
      newField[fieldName] = params[fieldName];
    }

    switch (params.type) {
      case "nestedFieldListWithModal":
      case "nestedFieldListNoModal":
        newField.fields = isAddingFormField ? {} : fieldsArray.find((x) => x.id === params.id)?.fields;
        newField.value = isAddingFormField ? [] : fieldsArray.find((x) => x.id === params.id)?.value;

        break;
      case "textfield":
      case "textarea":
      case "checkbox":
      case "number":
      case "attachmentPicker":
        newField.value = params.value;
        break;
      case "checkbox-list":
      case "radio-list":
      case "dropdown":
        newField.value = [];
        newField.options = [];
        for (let i = 0; i < (params.optionCount || 1); i++) {
          if (params[`option-${i}-selected`]) {
            if (params.type === "checkbox-list") {
              newField.value.push(params[`option-${i}`]);
            } else {
              newField.value = params[`option-${i}`];
            }
          }
          newField.options.push({
            label: params[`option-${i}`],
            value: params[`option-${i}`],
          });
        }
        break;
      default:
        break;
    }

    // debugger;
    let newFieldsArray = JSON.parse(JSON.stringify(fieldsArray));
    if (selectedNestedContainerField) {
      let containerField = newFieldsArray.find((x) => x.id === selectedNestedContainerField.id);
      if (isAddingFormField) {
        let maxExistingAdminOrder = Object.values(containerField).reduce((max, field) => {
          return Math.max(max, field.adminOrder || 0);
        }, 0);
        newField.adminOrder = maxExistingAdminOrder + 1;
        containerField.fields[newField.id] = newField;
      } else {
        containerField.fields[newField.id] = newField;
      }

      // this is used when adding a new field to a nested field list and the user opts to select it from the template list,
      // rather than starting from scratch
      if (params.useAsDefaultKeyName) {
        console.log("using this as default key name");
        containerField.defaultKeyName = newField.id;
      }
    } else {
      if (isAddingFormField) {
        newFieldsArray.push(newField);
      } else {
        let targetIndex = newFieldsArray.findIndex((field) => field.id === params.id);
        newFieldsArray[targetIndex] = newField;
      }
    }

    if (isAddingFormField && params.type === "dynamicSectionList") {
      newFieldsArray.push({
        id: `placeholderForDynamicSectionList-${newField.id}`,
        isPlaceholderForDynamicSectionList: newField.id,
        type: "section",
        label: "Placeholder section",
      });
    }
    await this.saveNewFields(newFieldsArray);
  };

  render() {
    const {
      onClose,
      visible,
      fileTypeDetails /*template, templateTask, organisationDetails, fileType, windowWidth, windowHeight*/,
      organisationDetails,
    } = this.props;
    const { form, selectedFormField, isAddingFormField } = this.state;
    if (!form) {
      return <OverallSpinner />;
    }

    return (
      <div className={cx("form-editor", { invisible: !visible })}>
        {displayModalContainingFields.call(this, {
          onFieldEdit: this.onFieldEdit,
          onFieldDelete: this.onFieldDelete,
          changeFieldOrder: this.changeFieldOrder,
        })}
        {displayModalContainingSectionFields.call(this, {
          onFieldEdit: this.onFieldEdit,
          onFieldDelete: this.onFieldDelete,
          changeFieldOrder: this.changeFieldOrder,
        })}
        {displayModalForNestedField.call(this, {
          onFieldEdit: this.onFieldEdit,
          onFieldDelete: this.onFieldDelete,
          changeFieldOrder: this.changeFieldOrder,
        })}
        <div className="user-input-container">
          <Card
            title={
              <>
                <Typography.Text>Form fields</Typography.Text>
                <Button
                  style={{ marginLeft: "1rem" }}
                  type="primary"
                  icon={<PlusCircleOutlined />}
                  onClick={() => {
                    this.setState({
                      selectedFormField: undefined,
                      isAddingFormField: true,
                    });
                  }}
                >
                  Add field
                </Button>
              </>
            }
            className="form-fields-card"
            actions={
              <>
                {!fileTypeDetails.isFormOnly && (
                  <Button onClick={onClose} className="back-button">
                    Close form <CaretRightOutlined style={{ marginLeft: "0.5rem" }} />
                  </Button>
                )}
              </>
            }
          >
            <div className="fields">
              {displayFields.call(this, {
                showHiddenByModal: false,
                onFieldEdit: this.onFieldEdit,
                onFieldDelete: this.onFieldDelete,
                changeFieldOrder: this.changeFieldOrder,
              })}
            </div>
          </Card>
        </div>
        {(selectedFormField || isAddingFormField) && (
          <FormFieldModal
            apiUser={this.props.apiUser}
            field={selectedFormField}
            modalContainerField={this.state.selectedModalContainerField}
            nestedContainerField={this.state.selectedNestedContainerField}
            sectionContainerField={this.state.selectedSectionContainerField}
            fileTypeDetails={fileTypeDetails}
            form={this.props.form}
            onClose={() => {
              this.setState({
                selectedFormField: undefined,
                isAddingFormField: false,
              });
            }}
            onSubmit={this.onFormModalSubmit}
            organisationDetails={organisationDetails}
          />
        )}
      </div>
    );
  }
}
